import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {
  constructor() {}

  getMenu(): any[] {
    return [
      { name: '', path: './' },
      {
        name: 'OS PARTS ORDERING',
        path: './ordering',
        children: [
          { name: 'JOB/PROCESS STATUS', path: './jobstatus' },
          { name: 'ORDER/FORECAST MAINTENANCE', path: './orderforecast' },
          {
            name: 'ORDER/FORECASTTEST MAINTENANCE',
            path: './orderforecasttest',
          },
          { name: 'ADJUSTMENT INQUIRY', path: './adjustmentinquiry' },
          {
            name: 'FLUCTUATION ALLOWANCE MAINTENANCE',
            path: './famaintenance',
          },
          { name: 'RUNDOWN MAINTENANCE', path: './rundownmaintenance' },
          { name: 'BULK ADJUSTMENT', path: './bulkadjustment' },
          { name: 'REPORTS', path: './reports' },
          {
            name: 'FLUCTUATION ALLOWANCE MAINTENANCE',
            path: './famaintenance',
          },
          { name: 'GETSUDO STATUS', path: './getsudostatus' },
          { name: 'AIR FREIGHT ORDER', path: './airfreightorder' },
          {
            name: 'PARTS AND INVENTORY POLICIES',
            path: './partsandinventorpolicies',
          },
        ],
      },
      {
        name: 'OSPIPELINE',
        path: './pipe',
        children: [
          {
            name: 'JOB/PROCESS STATUS',
            path: './jobstatus',
          },
          {
            name: 'PARTS INQUIRY',
            path: './partsinquire',
          },
          {
            name: 'REPORTS',
            path: './reports',
          },
          {
            name: 'PARTS MAINTAINANCE',
            path: './partsmaintenance',
          },

          {
            name: 'CONTAINER MAINTENANCE',
            path: './containermaintenance',
          },
          {
            name: 'SEAL VERIFICATION',
            path: './sealverification',
          },
          {
            name: 'PRODUCTION PROGRESS',
            path: './productionprogress',
          },
          {
            name: 'PROCUREMENT',
            path: './Procurement',
          },

        ],
      },
      {
        name: 'OS INVENTORY MANAGEMENT',
        path: './inventory',
        children: [
          {
            name: 'MODULE STOCKING',
            path: './modulestocking',
          },
          {
            name: 'STOCKING PACE INQUIRY',
            path: './stocking-pace-inquiry',
          },
          {
            name: 'STOCKING PACE MAINTENANCE',
            path: './stocking-pace-maintenance',
          },
          {
            name: 'MODULE TYPE',
            path: './module-type',
          },
        ]
      },
      {
        name: 'SETUP',
        path: './setup',
        children: [
          {
            name: 'MASTER SETUP',
            path: './master',
          },
          {
            name: 'CALENDAR SETUP',
            path: './calendar',
          },
          {
            name: 'CONFIGURATION LINES',
            path: './configurationlines',
          },
          {
            name: 'SHIFTS',
            path: './shifts',
          }
        ],
      },
      {
        name: 'HELP',
        path: './help',
        children: [
          {
            name: 'TRAINING VIDEOS',
            path: './trainingvideos',
          },
          {
            name: 'OSPARTS RELEASE NOTES',
            path: './releasenotes',
          },

          {
            name: 'OSPIPE RELEASE NOTES',
            path: './pipereleasenotes',
          },
        ],
      },
    ];
  }
}
