import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { log } from 'util';
import { AirfreightArrivedCheckboxComponent } from '../airfreight-arrived-checkbox/airfreight-arrived-checkbox.component';
import { AirorderNewrowComponent } from './airorder-newrow/airorder-newrow.component';
import { CustomTooltipComponent } from 'src/app/core/custom-tooltip/custom-tooltip.component';
import * as moment from 'moment';
import { GriddataService } from 'src/app/services/griddata.service';
import { GridVanningVolEditorComponent } from 'src/app/core/grid/grid-vanvolum-edit.component';
import { ActualArrivalEditableComponent } from './actual-arrival-editable/actual-arrival-editable.component';
import { roundUp } from 'src/app/constants';
import { EtaDateEditableComponent } from './actual-arrival-editable/eta-date-editable.component';
import { NoOfPiecesEditableComponent } from './actual-arrival-editable/no-of-pieces-editable.component';
import { ConfEditableComponent } from './actual-arrival-editable/conf-editable.component';
import { DeadlineEditableComponent } from './actual-arrival-editable/deadline-editable.component';
import { EtaTimeEditableComponent } from './actual-arrival-editable/eta-time-editable.component';
import { CaseNoEditableComponent } from './actual-arrival-editable/case-no-editable.component';
import { Location } from '@angular/common';
import { Part } from 'src/app/shared/part-model';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { Subscription, forkJoin } from 'rxjs';
import { logErrors } from 'src/app/shared/logger';
import { NgxSpinnerService } from 'ngx-spinner';
import * as mm from 'moment-timezone';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/services/air-freight-validation/validations.service';
import { CustomHeaderComponent } from '../custom-header/custom-header.component';
import { DataServiceService } from 'src/app/services/air-freight-validation/data-service.service';
import { ScheduleDeleteComponent } from '../schedule-delete/schedule-delete.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AirorderDeleteComponent } from '../delete-airorder/airorder-delete.component';
import { ConfigParameterComponent } from './config-parameter/config-parameter.component';
import { element } from 'protractor';
import { ConfLotsEditableComponent } from './actual-arrival-editable/conf-lots-editable.component';
import { NoOfLotsEditableComponent } from './actual-arrival-editable/no-of-lots.editable.component';
import { Dropdown } from 'primeng/dropdown';
import { OSPStateService } from 'src/app/services/osp-state.service';

@Component({
  selector: 'app-add-edit-air-record',
  templateUrl: './add-edit-air-record.component.html',
  styleUrls: ['./add-edit-air-record.component.scss'],
})
export class AddEditAirRecordComponent implements OnInit {
  editEventEmitter = new EventEmitter();
  addMasterEventEmitter = new EventEmitter();
  editMasterEventEmitter = new EventEmitter();
  @ViewChild('formTop', { static: false }) formTop!: ElementRef;
  @ViewChild('dockNoDropdown') dockNoDropdown!: Dropdown;
  @ViewChild('kanbanNoInput') kanbanNoInput!: ElementRef;
  @ViewChild('piecesToOrderInput') piecesToOrderInput!: ElementRef;
  @ViewChild('aircaseInput') aircaseInput!: ElementRef;
  @Output() viewCartClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() orderAdded = new EventEmitter<any>();
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  gridOptions = {} as any;
  isLotsSelected: boolean = true;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  isExpandedIndex: number | null = null; // Track the index of the currently expanded panel
  columnDefs;
  rowData;
  context: any;
  popupname: any;
  masterId: any;
  masterName: any;
  part_type: any;
  dataItem: any;
  kanbanList = [];
  partList = [];
  kanbanNo: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;
  warningMessage = '';
  successMessage = '';
  minDate: String;
  estimatedCost: any;
  boxWeight: any;
  piecesToOrder: any;
  lotsToOrder: any;
  easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
  addingParts = [];
  addOrEditform2: FormArray;
  adjustSubscription$: Subscription;
  parts: Part[] = []; // Declare and initialize here
  isExpanded: boolean[] = []; // Declare and initialize here
  todaysDate = String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10);
  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    kanbanNo: [{ value: '', disabled: false }, Validators.required],
    partNo: [{ value: '', disabled: false }, Validators.required],
    boxWeight: [''],
    estimatedCost: [''],
    destCode: [null, Validators.required],
    orderType: [null, Validators.required],
    orderLot: [''],
    qpcBox: [''],
    totalOrder: [''],
    orderDate: [null, Validators.required],
    vanConfirmation: ['No'],
    costCalculation: ['Weight'],
    responsibilityregion: ['Plant'],
    volumesize: ['Pipeline'],
    partType: ['Production'],
    dockNo: [null, Validators.required],
    raisedBy: [null],
    reason: [null, Validators.required],
    piecesToOrder: [null],
    lotsToOrder: [null],
    airCode: [null, Validators.required],
    managerName: [],
    part_description: [''],
    budgetCodes: [''],
    // budgetCodes: [null, Validators.required],

    firstComment: [''],
    secondComment: [''],

    KvcName: [],
  });
  enableview: boolean = false;
  frameworkComponents = {
    customTooltip: CustomTooltipComponent,
    buttonRenderer: AirorderNewrowComponent,
  };
  @ViewChild('osPartsGrid') osPartsGrid;
  toOrder: any;
  addAirOrderPart: any = [];
  addAirOrderKanban: any = [];
  addAirOrderDestcode: any = [];
  addAirOrderDock: any = [];
  getAircodeForSelectedDock: any = [];
  namcValue: string;
  user: string;
  userRole: string;
  params: any;
  userName: string;
  filteredData = [];
  selectedDestCode: any;
  defaultOrderType: any;
  addAirReason: any = [];
  fb: any;
  totalParts: number;
  addBudgetCodes: any = [];
  managerDetails: any = [];
  kvcDetails: any = [];
  addAirCaseCode: any = [];
  // addOrEditform: FormArray;
  qpcforParts: any = [];
  schedules: any;
  manager_filter_id: any;
  kvc_filter_id: any;
  costperKilo: any = [];

  invalidFields = [
    'dock',
    'part number',
    'kanban',
    'air case code',
    'number of pieces to order or number of lots to order',
  ];
  globalQpc: any;
  calendarList: any = [];
  constructor(
    private readonly airFreightService: AirfreightApicallsService,
    private readonly lookupservice: LookupService,
    private readonly spinner: NgxSpinnerService,
    private router: Router,
    private orderService: ValidationService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private location: Location,
    private dataService: DataServiceService
  ) {
    this.context = {
      componentParent: this,
    };

    // Add the first part only on initialization

    if (this.parts.length === 0) {
      this.parts.push({ part_num: '', description: '' }); // Only if needed
      this.isExpanded.push(true); // Expand the first part
    }
    this.columnDefs = [
      {
        headerName: '', // New column for actions (Delete button)
        field: 'actions',
        cellRendererFramework: ScheduleDeleteComponent, // Renders the delete button
        width: 70, // Adjust the width as needed
        sortable: false,
        // filter: false,
        // Optionally add other grid settings like floatingFilter or suppressMovable
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'Arrival DT',
        field: 'arrivalDate',
        sortable: false,
        flex: 1,
        cellRendererFramework: ActualArrivalEditableComponent,
        cellRendererParams: { column: 'arriving_date' },
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'Deadline DT',
        field: 'deadlineDate',
        sortable: false,
        flex: 1,

        cellRendererFramework: DeadlineEditableComponent,
        cellRendererParams: { column: 'deadline_dates' },
      },
      // {
      //   headerComponentFramework: CustomHeaderComponent,
      //   headerName: 'No.of Lots',
      //   field: 'noOfLots',
      //   sortable: true,
      //   flex: 1,
      //   cellRendererFramework: NoOfPiecesEditableComponent,
      //   cellRendererParams: { column: 'pieces' },
      // },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'No.of Pieces',
        field: 'noOfPieces',
        sortable: false,
        flex: 1,
        cellRendererFramework: NoOfPiecesEditableComponent,
        cellRendererParams: { column: 'number_of_pieces' },
        hide: this.isLotsSelected,
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'No of lots',
        field: 'noOfLots',
        sortable: false,
        flex: 1,
        cellRendererFramework: NoOfLotsEditableComponent,
        cellRendererParams: { column: 'number_of_lots' },
        hide: !this.isLotsSelected,
      },
      {
        headerName: 'Conf Lots',
        field: 'configureLots',
        sortable: false,
        flex: 1,
        cellRendererFramework: ConfLotsEditableComponent,
        cellRendererParams: { column: 'conf_lots' },
        hide: !this.isLotsSelected,
      },
      {
        headerName: 'Conf Pieces',
        field: 'ConfigurePieces',
        flex: 1,
        // tooltipField: 'vessel_name',
        sortable: false,
        cellRendererFramework: ConfEditableComponent,
        cellRendererParams: { column: 'conf_pieces' },
        hide: this.isLotsSelected,
      },

      {
        // headerComponentFramework: CustomHeaderComponent,
        headerName: 'Van Center ETA DT',
        field: 'etaDate',
        sortable: false,
        flex: 1,

        tooltipField: 'container_number',
        cellRendererFramework: EtaDateEditableComponent,
        cellRendererParams: { column: 'eta_date' },
      },

      {
        headerName: 'Renban',
        field: 'casenoEditable',
        sortable: false,
        flex: 1,
        cellRendererFramework: CaseNoEditableComponent,
        cellRendererParams: { column: 'case_no' },
      },
      {
        headerName: 'Arrived?',
        field: 'ArrivedChecked',
        flex: 1,
        tooltipField: 'Arrived?',
        cellRendererFramework: AirfreightArrivedCheckboxComponent,
        cellRendererParams: { column: 'isArrived' },
        sortable: false,
      },
      //AirfreightArrivedCheckboxComponent
    ];
    this.rowData = [
      {
        arrivalDate: '',
        deadlineDate: '',
        noOfPieces: '',
        noOfLots: '',
        configureLots: '',
        ConfigurePieces: '',
        etaDate: '',
        casenoEditable: '',
      },
    ];

    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.getGridOptions();
  }
  get addOrEdit() {
    return this.addOrEditform.controls;
  }
  access;
  isFirstLoad = true;
  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    // this.gridOptions.floatingFilter = false;
    localStorage.setItem('isClearMsg', 'false');
    


    this.todaysDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
    this.minDate = new Date(this.todaysDate).toISOString().split('T')[0];

    console.log('I am caled', this.todaysDate);
    this.business_entity = localStorage.getItem('namcvalue');

    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    } else {
      this.userName = 'Test User';
    }

    this.addOrEditform.controls.orderDate.setValue(this.todaysDate);
    this.addOrEditform.controls.raisedBy.setValue(this.userName);

    // this.dataService.filteredData$.subscribe((orderData) => {
    //   console.log('going inside', orderData);
    //   if (orderData) {
    //     this.filteredData = orderData;
    //   }
    // });

    this.toOrder = [
      {
        order_type: 'Pieces',
      },
      {
        order_type: 'Lots',
      },
    ];

    // Set the default value to "Lots"
    this.defaultOrderType = this.toOrder.find((item) => item.order_type === 'Lots');
    if (this.defaultOrderType) {
      this.addOrEditform.controls.orderType.setValue(this.defaultOrderType);
    }

    this.loadDrop();

    this.adjustSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      if (!this.isFirstLoad) {
        this.router.navigate(['ordering/airfreightorder']);
      }
      this.isFirstLoad = false; // Set to false after the first load
    });
  }
  loadDrop() {
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    this.callApiForAdd();
  }

  // calling all apis needed to create air order record

  callApiForAdd() {
    const addApiSubcriptions = [
      this.lookupservice.getPartNumber(),
      this.lookupservice.getKanban(),
      this.lookupservice.getDestCode(),
      this.lookupservice.getDock(),
      this.airFreightService.getReason(),
      this.airFreightService.getBudgetCodesDefault(),
      this.airFreightService.getManagerDetails(),
      this.airFreightService.getKvcDetails(),
      this.airFreightService.getAirCaseCodes(),
      this.lookupservice.getqpcforpart(),
      this.airFreightService.getAirCostData(),
      this.airFreightService.getCalendar(),
    ];

    forkJoin(addApiSubcriptions).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.addAirOrderPart.push(...res[0].body.data);
        this.addAirOrderKanban.push(...res[1].body.data);
        this.addAirOrderDestcode.push(...res[2].body.data);
        this.addAirOrderDock.push(...res[3].body.data);
        this.getAircodeForSelectedDock = this.addAirCaseCode;
        this.addAirOrderDock = this.addAirOrderDock.filter(
          (value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
        );

        this.addAirReason.push(...res[4].body.data);
        this.addBudgetCodes.push(...res[5].body.data);
        this.managerDetails.push(...res[6].body.data);
        this.kvcDetails.push(...res[7].body.data);
        this.addAirCaseCode.push(...res[8].body.data);
        this.addAirCaseCode = this.addAirCaseCode.filter(
          (value, index, self) => index === self.findIndex((t) => t.air_case_code === value.air_case_code)
        );

        this.qpcforParts.push(...res[9].body.data);
        this.costperKilo.push(...res[10].body.data);
        this.calendarList.push(...res[11].body.data);

        this.addOrEditform.controls.budgetCodes.setValue(this.addBudgetCodes[0]);

        if (this.addAirOrderDestcode.length === 1) {
          // Set the entire object to the form control, not just the dest_code
          this.selectedDestCode = this.addAirOrderDestcode[0]; // The object to be selected
          console.log('port..', this.selectedDestCode);

          this.addOrEditform.controls.destCode.setValue(this.selectedDestCode);
        }

        if (this.addAirReason.length > 0) {
          // Set the entire object to the form control, not just the dest_code
          const selectedReason = this.addAirReason[0]; // The object to be selected

          this.addOrEditform.controls.reason.setValue(selectedReason);
        }

        this.spinner.hide();
      },
    });

    this.toOrder = [
      {
        order_type: 'Lots',
      },
      {
        order_type: 'Pieces',
      },
    ];
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    // this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  saveUser() {}

  submitted: boolean = false;

  rowdata = [];
  addMasterDetails() {
    console.log('addMasterDetails', this.addOrEdit);

    if (this.addOrEditform.invalid) {
      console.log('I am invalid');
      this.submitted = true;
      this.scrollToTop();
      return;
    }

    const addEmittedData1 = {
      kanban_no: this.addOrEdit.kanbanNo.value,
      part_no: this.addOrEdit.part_num.value,

      // dataitem: this.addOrEdit.dataItem.value,
    };
    console.log(addEmittedData1, 'addemit');
    console.log(addEmittedData1.part_no.item_id);

    let resultArray = {
      kanban: addEmittedData1.kanban_no.kanban,
      item_id: addEmittedData1.part_no.item_id,
    };

    console.log(addEmittedData1, 'addEmittedData');

    this.rowdata.push(resultArray);

    //clear form

    this.successMessage = 'Added successfully, can add more or close popup to view cart';

    this.addMasterEventEmitter.emit(this.rowdata);
    this.addOrEditform.reset();
    // this.dialogeRef.close();
  }

  // scrool to top on form errors
  scrollToTop() {
    if (this.formTop) {
      this.formTop.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;

    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  // addRow() {
  //   const newRow = {
  //     arrivalDate: '',
  //   };
  //   this.rowData.push(newRow);
  //   this.gridOptions.api.setRowData(this.rowData);

  // }

  addRow() {
    // Create a new row object
    const newRow = {
      arrivalDate: '', // Add other fields as needed with default/empty values
      deadlineDate: '',
      noOfPieces: '',
      noOfLots: '',
      configureLots: '',
      ConfigurePieces: '',
      etaDate: '',
      casenoEditable: '',
    };

    // Push the new row into the existing rowData array
    this.rowData = [...this.rowData, newRow];

    // Update the grid with the updated rowData
    this.gridOptions.api.setRowData(this.rowData);
  }

  // Method to handle the dropdown's change event
  onLotsPiecesChange(event: any): void {
    this.isLotsSelected = event.value?.order_type === 'Lots';
    console.log(this.isLotsSelected, 'hi');
    // this.osPartsGrid.gridOptions.api.refreshHeader();

    this.updateColumnVisibility();
  }

  updateColumnVisibility() {
    this.columnDefs = this.columnDefs.map((colDef) => {
      if (colDef.field === 'noOfLots') {
        return {
          ...colDef,
          hide: !this.isLotsSelected, // Show this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'configureLots') {
        return {
          ...colDef,
          hide: !this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'noOfPieces') {
        return {
          ...colDef,
          hide: this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'ConfigurePieces') {
        return {
          ...colDef,
          hide: this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      return colDef; // Keep other columns unchanged
    });

    // Apply the updated column definitions
    // this.gridApi.setColumnDefs(this.columnDefs);
  }

  handleKeydown(inputType: string, event) {
    //this.allowOnlyNumbers(event);

    if (inputType === 'input1') {
      this.calculateTotalOrder();
      this.calculateEstimationCost();
    } else {
      this.calculateEstimationCost();
      this.calculateTotalOrder();
    }
  }

  calculateTotalOrder() {
    console.log('calculateTotalOrder');

    // pieces== no of pieces%qpc
    // lots= no of lots%qpc

    const qpc_orderlot = this.addOrEdit.qpcBox?.value;
    const piecesToOrder = this.addOrEdit.piecesToOrder?.value;
    const lotsToOrder = this.addOrEdit.lotsToOrder?.value;
    let pieces = lotsToOrder * qpc_orderlot;
    console.log('peces', pieces);
    let lots = piecesToOrder / qpc_orderlot;
    console.log('lots', lots);

    // If either qpc_orderlot or piecesToOrder is missing, clear totalOrder
    if (!this.isLotsSelected) {
      console.log('pieces............');
      if (!piecesToOrder) {
        this.addOrEdit.totalOrder.setValue(''); // Clear totalOrder
      } else {
        // If both fields have values, calculate totalOrder
        console.log(qpc_orderlot, piecesToOrder, 'nalini');
        const calculateValue = piecesToOrder;
        this.addOrEdit.totalOrder.setValue(calculateValue);
      }
    } else {
      console.log('lots.................');
      if (!lotsToOrder && !qpc_orderlot) {
        this.addOrEdit.totalOrder.setValue(''); // Clear totalOrder
      } else {
        // If both fields have values, calculate totalOrder

        const calculateValue = lotsToOrder * qpc_orderlot;
        this.addOrEdit.totalOrder.setValue(calculateValue);
      }
    }
  }

  // allow only numbers

  calculateEstimationCost() {
    console.log('calculateEstimationCost', this.costperKilo[0]);

    //   total cost = box weight (in kilo) * no. of boxes * cost per kilo
    //est cost (when weight) = no. of pieces / qpc * weight * cost per kilo
    const cost_per_kilo = Number(this.costperKilo[0].cost_per_kilo);

    const boxWeight = Number(this.addOrEdit.boxWeight.value);
    const piecesToOrder = Number(this.addOrEdit.piecesToOrder.value);
    const qpc_orderlot = Number(this.addOrEdit.qpcBox.value);

    const lotsToOrder = this.addOrEdit.lotsToOrder?.value;

    console.log('piecesToOrder:', piecesToOrder);
    console.log('qpc_orderlot:', qpc_orderlot);
    console.log('boxWeight:', boxWeight);
    console.log('cost_per_kilo:', cost_per_kilo);

    // console.log(this.boxWeight,this.piecesToOrder,'nalini')
    if (!this.isLotsSelected) {
      if (!boxWeight && !piecesToOrder && !qpc_orderlot && !cost_per_kilo) {
        this.addOrEdit.estimatedCost.setValue(''); // Clear totalOrder
      } else {
        const calculateCost = (piecesToOrder / qpc_orderlot) * boxWeight * cost_per_kilo;
        this.addOrEdit.estimatedCost.setValue(roundUp(calculateCost,2));
      }
    } else {
      //est cost (when weight) = no of lots * weight * cost per kilo
      if (!boxWeight && !this.lotsToOrder && !cost_per_kilo) {
        this.addOrEdit.estimatedCost.setValue(''); // Clear totalOrder
      } else {
        const calculateCost = lotsToOrder * boxWeight * cost_per_kilo;
        this.addOrEdit.estimatedCost.setValue(roundUp(calculateCost,2));
      }
    }
  }

  goBack() {
    localStorage.setItem('isClearMsg', 'true');
    this.location.back();
  }

  toggleExpansion(index: number) {
    if (this.isExpandedIndex === index) {
      // If the current index is already expanded, collapse it
      this.isExpandedIndex = null;
    } else {
      // Otherwise, set the new expanded index
      this.isExpandedIndex = index;
    }
  }

  // Define a global array to hold parts
  partsArray = [];
  resetLatestPart() {
    this.addOrEdit.kanbanNo.setValue('');
    this.addOrEdit.partNo.setValue('');
    this.addOrEdit.boxWeight.setValue('');
    this.addOrEdit.estimatedCost.setValue('');
    this.addOrEdit.destCode.setValue(null);
    this.addOrEdit.orderType.setValue(null);
    this.addOrEdit.orderLot.setValue('');
    this.addOrEdit.qpcBox.setValue('');
    this.addOrEdit.totalOrder.setValue('');
    this.addOrEdit.orderDate.setValue(null);
    this.addOrEdit.vanConfirmation.setValue('No');
    this.addOrEdit.costCalculation.setValue('Weight');
    this.addOrEdit.responsibilityregion.setValue('Plant');
    this.addOrEdit.volumesize.setValue('Pipeline');
    this.addOrEdit.partType.setValue('Production');
    this.addOrEdit.dockNo.setValue(null);
    this.addOrEdit.raisedBy.setValue(null);
    this.addOrEdit.reason.setValue(null);
    this.addOrEdit.piecesToOrder.setValue(null);
    this.addOrEdit.lotsToOrder.setValue(null);
    this.addOrEdit.airCode.setValue(null);
    this.addOrEdit.managerName.setValue('');
    this.addOrEdit.part_description.setValue('');
    this.addOrEdit.budgetCodes.setValue('');
    this.addOrEdit.firstComment.setValue('');
    this.addOrEdit.secondComment.setValue('');
    this.addOrEdit.KvcName.setValue([]);
  }
  addPart() {
    console.log('addPart calling....');
    this.warningMessage = ''; // Clear any previous warnings
    this.updateInvalidFields();
    if (this.addOrEditform.invalid) {
      //this.warningMessage = 'Please fill all the mandatory fields before adding a part.';
      this.warningMessage = `Please fill all the mandatory fields before adding a part - (${this.invalidFields.join(
        ', '
      )}).`;
      this.scrollToTop();
      this.focusFirstInvalidControl();
      return;
    }
    // Get the updated schedules
    let getSchedules = this.rowData.filter((row) => row.isUpdated);
    console.log(getSchedules, 'getSchedules...');

    // Validate schedules
    if (!this.requiredSchedules(getSchedules)) {
      return; // Stop execution if validation failed in requiredSchedules
    }

    // Validate piecesToOrder or lotsToOrder
    if (this.addOrEdit.orderType.value.order_type == 'Lots') {
      if (!this.validate_peaceOfOrder(parseInt(this.addOrEdit.lotsToOrder.value), this.rowData, 1)) return false;
    } else if (this.addOrEdit.orderType.value.order_type == 'Pieces') {
      if (!this.validate_peaceOfOrder(parseInt(this.addOrEdit.piecesToOrder.value), this.rowData, 2)) return false;
    }

    // Check if mandatory fields are filled out (partNo, kanbanNo, destCode, dockNo)
    const part_num = this.addOrEditform.controls.partNo.value?.item_id || '';
    const kanban = this.addOrEditform.controls.kanbanNo.value?.kanban || '';
    const dest_code = this.addOrEditform.controls.destCode.value?.dest_code || '';
    const dock = this.addOrEditform.controls.dockNo.value?.dock || '';
    const reason = this.addOrEditform.controls.reason.value?.dock || '';
    const pieces_to_order =
      this.addOrEdit.piecesToOrder.value === '' || this.addOrEdit.piecesToOrder.value === undefined
        ? null
        : this.addOrEdit.piecesToOrder.value;

    const lots_to_order =
      this.addOrEdit.lotsToOrder.value === '' || this.addOrEdit.lotsToOrder.value === undefined
        ? null
        : this.addOrEdit.lotsToOrder.value;
    const part_type = this.addOrEdit.partType.value;
    const order_type = this.addOrEditform.controls.orderType.value?.order_type;
    const resp = this.addOrEdit.responsibilityregion.value;
    const order_lot = this.addOrEdit.qpcBox.value || '';
    const volume = this.addOrEdit.volumesize.value;
    const kvc_conf = this.addOrEditform.get('vanConfirmation').value || '';
    const air_case_code = this.addOrEditform.controls.airCode.value?.air_case_code || '';
    const buco_code = this.addOrEdit.budgetCodes.value?.buco_code || '';
    const box_weight =
      this.addOrEdit.boxWeight.value === '' || this.addOrEdit.boxWeight.value === undefined
        ? null
        : this.addOrEdit.boxWeight.value;
    const part_desc = this.addOrEdit.part_description.value || '';
    const comment1 = this.addOrEdit.firstComment.value || '';
    const comment2 = this.addOrEdit.secondComment.value || '';
    const cost_calc = this.addOrEdit.costCalculation.value || null;

    const manager_id =
      this.manager_filter_id === '' || this.manager_filter_id === undefined ? null : this.manager_filter_id;
    const kvc_id = this.kvc_filter_id === '' || this.kvc_filter_id === undefined ? null : this.kvc_filter_id;
    const total_order =
      this.addOrEdit.totalOrder.value === '' || this.addOrEdit.totalOrder.value === undefined
        ? null
        : this.addOrEdit.totalOrder.value;
    // const est_cost = this.addOrEdit.estimatedCost.value || null ;
    const est_cost =
      this.addOrEdit.estimatedCost.value === '' || this.addOrEdit.estimatedCost.value === undefined
        ? null
        : this.addOrEdit.estimatedCost.value;

    // // If mandatory fields are missing, show the warning message and stop
    // if (!part_num || !kanban || !dest_code || !dock || !reason) {
    //   this.warningMessage = 'Please fill all the mandatory fields before adding a part.';
    //   this.scrollToTop();
    //   return; // Stop further execution if fields are incomplete
    // }

    // If all required fields are filled, expand to the next part
    // Create the new part and expand the design for Part 2
    const newpart = {
      part_num: part_num,
      kanban: kanban,
      dest_code: dest_code,
      dock: dock,
      reason: reason,
      pieces_to_order: pieces_to_order,
      part_type: part_type,
      order_type: order_type,
      resp: resp,
      order_lot: order_lot,
      volume: volume,
      kvc_conf: kvc_conf,
      air_case_code: air_case_code,
      buco_code,
      box_weight,
      part_desc,
      comment1,
      comment2,
      cost_calc,
      manager_id: manager_id,
      kvc_id: kvc_id,
      total_order: total_order,
      lots_to_order,
      est_cost: est_cost,

      schedules: [], // Initialize schedules if needed
    };

    // Collect schedules only for the current part
    getSchedules = this.rowData.filter((row) => row.isUpdated);
    if (getSchedules.length > 0) {
      newpart.schedules = getSchedules.map((schedule) => ({
        arriving_date: schedule.arriving_date,
        eta_date: schedule.eta_date,
        case_no: schedule.case_no,
        deadline_date: schedule.deadline_dates,
        number_of_pieces: schedule.number_of_pieces,
        number_of_lots: schedule.number_of_lots,
        conf_lots: schedule.conf_lots,
        conf_pieces: schedule.conf_pieces,
        air_order_status: schedule.isArrived ? 'ARRIVED' : kvc_conf === 'Yes' ? 'CONFIRMED' : 'CREATED',
      }));

      // Check if the part number already exists (with the `checkIfPartExist` method)
      if (this.checkIfPartExist(newpart)) {
        // If duplicate part exists, return early without adding the new part
        return;
      }

      // Add the new part to the parts array
      this.parts.push(newpart);
      this.isExpanded.push(true); // Expand the design for the newly added part

      // Collapse the previous part if needed
      const currentIndex = this.isExpanded.findIndex((expanded) => expanded);
      if (currentIndex >= 0) {
        this.isExpanded[currentIndex] = false; // Collapse the previous part
      }
      this.rowData.forEach((row) => {
        row.arriving_date = null;
        row.eta_date = null;
        row.conf_lots = null;
        (row.deadline_dates = null), (row.number_of_lots = null), (row.number_of_pieces = null);
        row.isUpdated = false;
      });
    } else {
      this.warningMessage = 'Please add schedules for the part.';
      this.scrollToTop();
      return; // Stop execution if no schedules are added
    }

    // Only reset the values for fields you intend to clear, like the destination and order date
    // Do not reset the entire form here unless it's necessary
    this.addOrEditform.controls.destCode.setValue(this.selectedDestCode);
    this.addOrEditform.controls.orderType.setValue(this.defaultOrderType);
    this.addOrEditform.controls.orderDate.setValue(this.todaysDate);
  }

  // addPart() {
  //   // if (this.addOrEditform.invalid) {
  //   //   this.submitted = true;
  //   //   this.scrollToTop();
  //   //   return;
  //   // }
  //   console.log('addPart calling....');
  //   this.warningMessage = ''; // Clear any previous warnings

  //   // Check if mandatory fields are filled out (partNo, kanbanNo, destCode, dockNo)
  //   const part_num = this.addOrEditform.controls.partNo.value?.item_id || '';
  //   const kanban = this.addOrEditform.controls.kanbanNo.value?.kanban || '';
  //   const dest_code = this.addOrEditform.controls.destCode.value?.dest_code || '';
  //   const dock = this.addOrEditform.controls.dockNo.value?.dock || '';
  //   const reason = this.addOrEditform.controls.reason.value?.dock || '';
  //   const pieces_to_order = this.addOrEdit.piecesToOrder.value || '';
  //   const part_type = this.addOrEdit.partType.value;
  //   const order_type = this.addOrEditform.controls.orderType.value?.order_type;
  //   const resp = this.addOrEdit.responsibilityregion.value;
  //   const order_lot = parseInt(this.addOrEdit.qpcBox.value || '', 10);
  //   const volume = this.addOrEdit.volumesize.value;
  //   const kvc_conf = this.addOrEditform.get('vanConfirmation').value || '';
  //   const air_case_code = this.addOrEditform.controls.airCode.value?.air_case_code || '';
  //   const manager_id = this.addOrEditform.controls.managerName.value?.air_case_code || '';
  //   const box_weight=parseInt(this.addOrEdit.boxWeight.value || '', 10);
  //   //const total_order=

  //   const part_description = this.addOrEdit.part_description.value || '';
  //   console.log(manager_id, 'managername');

  //   // // If mandatory fields are missing, show the warning message and stop
  //   // if (!part_num || !kanban || !dest_code || !dock || !reason) {
  //   //   this.warningMessage = 'Please fill all the mandatory fields before adding a part.';
  //   //   this.scrollToTop();
  //   //   return; // Stop further execution if fields are incomplete
  //   // }

  //   // If all required fields are filled, expand to the next part
  //   // Create the new part and expand the design for Part 2
  //   const newPart = {
  //     part_num: part_num,
  //     kanban: kanban,
  //     dest_code: dest_code,
  //     dock: dock,
  //     reason: reason,
  //     pieces_to_order: pieces_to_order,
  //     part_type: part_type,
  //     order_type: order_type,
  //     resp: resp,
  //     order_lot: order_lot,
  //     volume: volume,
  //     kvc_conf: kvc_conf,
  //     air_case_code: air_case_code,
  //     manager_id,
  //     box_weight:box_weight,

  //     part_description:part_description,
  //     schedules: [], // Initialize schedules if needed
  //   };

  //   // Collect schedules only for the current part
  //   const getSchedules = this.rowData.filter((row) => row.isUpdated);
  //   if (getSchedules.length > 0) {
  //     newPart.schedules = getSchedules.map((schedule) => ({
  //       arriving_date: schedule.arriving_date,
  //       eta_date: schedule.eta_date,
  //       case_no: schedule.case_no,
  //     }));

  //     // Add the new part to the parts array
  //     this.parts.push(newPart);
  //     this.isExpanded.push(true); // Expand the design for the newly added part

  //     // Collapse the previous part if needed
  //     // const currentIndex = this.isExpanded.findIndex((expanded) => expanded);
  //     // if (currentIndex >= 0) {
  //     //   this.isExpanded[currentIndex] = false; // Collapse the previous part
  //     // }

  //     // Reset the rowData after adding part
  //     this.rowData.forEach((row) => {
  //       row.arriving_date = null;
  //       row.eta_date = null;
  //       row.isUpdated = false;
  //     });
  //   }
  //   // else {
  //   //   this.warningMessage = 'Please add schedules for the part.';
  //   //   this.scrollToTop();
  //   //   return; // Stop execution if no schedules are added
  //   // }

  //   // Only reset the values for fields you intend to clear, like the destination and order date
  //   // Do not reset the entire form here unless it's necessary
  //   this.addOrEditform.controls.destCode.setValue(this.selectedDestCode);
  //   this.addOrEditform.controls.orderType.setValue(this.defaultOrderType);
  //   this.addOrEditform.controls.orderDate.setValue(this.todaysDate);
  // }

  // saveOrder(i) {
  //   let sendToApi = [];

  //   // Get the updated schedules
  //   let getSchedules = this.rowData.filter((row) => row.isUpdated);
  //   console.log(getSchedules, 'getSchedules...');

  //   // Remove parts with empty 'part_num'
  //   console.log(this.parts, 'before parts');
  //   this.parts = this.parts.filter((part) => part.part_num !== '');
  //   console.log('parts after removing empty parts: ', this.parts);

  //   // Check if there are valid parts after the removal
  //   const hasAddedParts = this.parts.length > 0;
  //   console.log('hasAddedParts', hasAddedParts);
  //   if (hasAddedParts) {
  //     //Extract the values from the form
  //     const part_num = this.addOrEdit.partNo.value.item_id;
  //     const kanban = this.addOrEdit.kanbanNo.value.kanban;
  //     const dest_code = this.addOrEdit.destCode.value.dest_code;
  //     const dock = this.addOrEdit.dockNo.value.dock;
  //     const newPart = {
  //       part_num: part_num,
  //       kanban: kanban,
  //       dest_code: dest_code,
  //       dock: dock,
  //       schedules:[]
  //     };

  //     //Add the new part to the parts array
  //     this.parts.push(newPart);
  //     console.log('New part added:', newPart);
  //     console.log('Updated parts:', this.parts);
  //   }

  //   // // If no duplicate, proceed to create a new part

  //   // Expand the design for the newly added part
  //   this.isExpanded.push(true); // Expand the design for the newly added part

  //   // Collapse the previous part if needed
  //   const currentIndex = this.isExpanded.findIndex((expanded) => expanded);
  //   if (currentIndex >= 0) {
  //     this.isExpanded[currentIndex] = false; // Collapse the previous part
  //   }

  //   this.parts.forEach((part) => {
  //     let schedule_details = getSchedules.map((schedule) => ({
  //       arriving_date: schedule.arriving_date,
  //     }));

  //     const payload = {
  //       part_num: part.part_num,
  //       kanban: part.kanban,
  //       dest_code: part.dest_code,
  //       air_order_no: this.easternCurrentDate,
  //       business_entity: this.namcValue,
  //       workid: this.user,
  //       userid: this.userName,
  //       user_role: this.userRole,
  //       schedules: schedule_details, // Include schedules for the current part
  //     };

  //     sendToApi.push(payload);
  //   });

  //   console.log(sendToApi, 'sendToApi.....');

  //   // Send the constructed payload to the API
  //   this.airFreightService.addAirOrderDetails(sendToApi).subscribe(
  //     (responseData) => {
  //       console.log(responseData, 'Response Data...');
  //       // this.orderService.setOrderData(responseData);
  //       // this.router.navigate(['ordering/airfreightorder']);
  //     },
  //     (error) => {
  //       console.error('Error occurred while saving the order:', error);
  //     }
  //   );
  // }

  //   saveOrder(i) {
  //     console.log('i am calling......');
  //     let sendToApi = [];

  //     // if (this.addOrEditform.invalid) {
  //     //     this.submitted = true;
  //     //     this.scrollToTop();
  //     //     return;
  //     // }

  //     // Get the updated schedules
  //     let getSchedules = this.rowData.filter((row) => row.isUpdated);
  //     console.log(getSchedules, 'getSchedules...');

  //     // Filter out empty parts
  //     this.parts = this.parts.filter((part) => part.part_num); // Check for non-empty part_num

  //     console.log(this.parts, 'validParts');

  //     // Check if the parts array has elements and if there are valid parts
  //     const hasAddedParts = this.parts.length > 0;

  //     const part_num = this.addOrEdit.partNo.value.item_id;
  //     const kanban = this.addOrEdit.kanbanNo.value.kanban;
  //     const date_raised = this.addOrEdit.orderDate.value;
  //     const kvc_conf = this.addOrEditform.get('vanConfirmation').value;
  //     const dest_code = this.addOrEdit.destCode.value.dest_code;
  //     const dock = this.addOrEdit.dockNo.value.dock;

  //     const newpart = {
  //       part_num: part_num,
  //       kanban: kanban,
  //       dest_code: dest_code,
  //       air_order_no: this.easternCurrentDate,
  //       business_entity: this.namcValue,
  //       userid: this.userName,
  //       workid: this.user,
  //       date_raised: date_raised,
  //       kvc_conf: kvc_conf,
  //       dock: dock,
  //       user_role: this.userRole,
  //       schedules: getSchedules.map((schedule) => ({
  //         arriving_date: schedule.arriving_date,
  //       })),
  //     };

  //     // Check for duplicate part numbers
  //     // const partNos = this.parts.map((part) => part.part_num);
  //     // const hasDuplicates = new Set(partNos).size !== partNos.length;

  //     // Check if part_num already exists in parts
  //  // Push the new part first, before checking for duplicates
  //  this.parts.push(newpart);
  //  console.log(this.parts, 'new parts');

  //  // Now check for duplicate part numbers using Set
  //  const partNos = this.parts.map((part) => part.part_num);
  //  const uniquePartNos = new Set(partNos); // Create a Set to filter duplicates

  //  if (uniquePartNos.size !== partNos.length) {
  //    // If there are duplicates
  //    this.warningMessage = `There are duplicate part numbers. Please review your entries.`;
  //    this.scrollToTop();

  //    // Optionally highlight the part (part2 in this case) in the UI
  //    // You can apply a CSS class to mark it for the user to fix
  //    return; // Don't stop the user from modifying part2, just show the warning
  //  }

  //     // If parts have been added, process them
  //     if (hasAddedParts) {
  //       this.parts.forEach((part) => {
  //         if (part.part_num) {
  //           // Check for part_num
  //           console.log(part, 'nannna');

  //           let schedule_details = getSchedules.map((schedule) => ({
  //             arriving_date: schedule.arriving_date,
  //           }));

  //           const payload = {
  //             part_num: part.part_num, // Use part_num
  //             kanban: part.kanban,
  //             dest_code: part.dest_code,
  //             air_order_no: this.easternCurrentDate,
  //             business_entity: this.namcValue,
  //             workid: this.user,
  //             userid: this.userName,
  //             user_role: this.userRole,
  //             schedules: schedule_details, // Use schedules for the current part
  //           };

  //           sendToApi.push(payload);
  //         }
  //       });
  //     } else {
  //       console.log('else called');
  //       // Fallback logic if no parts were added
  //       const part_num = this.addOrEdit.partNo.value.item_id;
  //       const kanban = this.addOrEdit.kanbanNo.value.kanban;
  //       const date_raised = this.addOrEdit.orderDate.value;
  //       const kvc_conf = this.addOrEditform.get('vanConfirmation').value;
  //       const dest_code = this.addOrEdit.destCode.value.dest_code;
  //       const dock = this.addOrEdit.dockNo.value.dock;

  //       const payload = {
  //         part_num: part_num,
  //         kanban: kanban,
  //         dest_code: dest_code,
  //         air_order_no: this.easternCurrentDate,
  //         business_entity: this.namcValue,
  //         userid: this.userName,
  //         workid: this.user,
  //         date_raised: date_raised,
  //         kvc_conf: kvc_conf,
  //         dock: dock,
  //         user_role: this.userRole,
  //         schedules: getSchedules.map((schedule) => ({
  //           arriving_date: schedule.arriving_date,
  //         })),
  //       };

  //       sendToApi.push(payload);
  //     }

  //     console.log(sendToApi, 'sendToApi.....');

  //     // Send the constructed payload to the API
  //     this.airFreightService.addAirOrderDetails(sendToApi).subscribe(
  //       (responseData) => {
  //         console.log(responseData, 'Response Data...');
  //         // this.orderService.setOrderData(responseData);
  //         // this.router.navigate(['ordering/airfreightorder']);
  //       },
  //       (error) => {
  //         console.error('Error occurred while saving the order:', error);
  //       }
  //     );
  //   }

  // saveOrder(i) {
  //   if (this.addOrEditform.invalid) {
  //     this.warningMessage = 'Please fill all the mandatory fields before adding a part.'; // Custom message
  //     this.submitted = true; // Set flag to show error messages in UI
  //     this.scrollToTop();
  //     return; // Do not proceed further, just return
  //   }
  //   console.log('i am calling......');
  //   let sendToApi = [];

  //   // Get the updated schedules
  //   let getSchedules = this.rowData.filter((row) => row.isUpdated);
  //   console.log(getSchedules, 'getSchedules...');

  //   // Filter out empty parts
  //   this.parts = this.parts.filter((part) => part.part_num); // Check for non-empty part_num
  //   console.log(this.parts, 'validParts');

  //   // Check if the parts array has elements and if there are valid parts
  //   const hasAddedParts = this.parts.length > 0;
  //   if (hasAddedParts) {
  //     const part_num = this.addOrEdit.partNo.value.item_id || '';
  //     const kanban = this.addOrEdit.kanbanNo.value.kanban || '';
  //     const date_raised = this.addOrEdit.orderDate.value || '';
  //     const kvc_conf = this.addOrEditform.get('vanConfirmation').value || '';
  //     const dest_code = this.addOrEdit.destCode.value.dest_code || '';
  //     const dock = this.addOrEdit.dockNo.value.dock || '';
  //     const reason_code = this.addOrEdit.reason.value.reason_code || '';

  //     const newpart = {
  //       part_num: part_num,
  //       kanban: kanban,
  //       dest_code: dest_code,
  //       air_order_no: this.easternCurrentDate,
  //       business_entity: this.namcValue,
  //       userid: this.userName,
  //       workid: this.user,
  //       date_raised: date_raised,
  //       kvc_conf: kvc_conf,
  //       dock: dock,
  //       reason_code: reason_code,
  //       user_role: this.userRole,

  //       schedules: getSchedules.map((schedule) => ({
  //         arriving_date: schedule.arriving_date,
  //         eta_date: schedule.eta_date,
  //       })),
  //     };

  //     // Check if the part already exists in the list
  //     const partExists = this.parts.some((part) => part.part_num === newpart.part_num);

  //     if (partExists) {
  //       // If the part exists, show a warning but do not push a new part
  //       this.warningMessage = `This part number already exists. Please check the part numbers.`;
  //       this.scrollToTop();

  //       // Instead of adding a new part, find and update the existing part
  //       const existingPartIndex = this.parts.findIndex((part) => part.part_num === newpart.part_num);
  //       if (existingPartIndex !== -1) {
  //         // Update the part in the list instead of adding a new one
  //         this.parts[existingPartIndex] = { ...this.parts[existingPartIndex], ...newpart };
  //       }

  //       // Do not add a new part (no part3), just return
  //       return;
  //     }

  //     // If part doesn't exist, push the new part to the array
  //     this.parts.push(newpart);

  //     console.log(this.parts, 'new parts');

  //     // Continue with the rest of the process (sending to API, etc.)

  //     this.parts.forEach((part) => {
  //       if (part.part_num) {
  //         console.log(part.schedules[0], 'Teacher');

  //         //Ensure you get the specific schedules for the current part
  //         let schedule_details = part.schedules.map((schedule) => ({
  //           arriving_date: schedule.arriving_date,
  //           //eta_date: schedule.eta_date,  // Ensure eta_date is being passed correctly
  //         }));

  //         const payload = {
  //           part_num: part.part_num, // Use part_num
  //           kanban: part.kanban,
  //           dest_code: part.dest_code,
  //           air_order_no: this.easternCurrentDate,
  //           business_entity: this.namcValue,
  //           workid: this.user,
  //           userid: this.userName,
  //           user_role: this.userRole,
  //           date_raised: date_raised,
  //           kvc_conf: kvc_conf,
  //           dock: dock,
  //           reason_code: reason_code,
  //           schedules: schedule_details, // Use schedules for the current part
  //         };

  //         sendToApi.push(payload);
  //       }
  //     });
  //   } else {
  //     // If parts are not added and form is invalid, show the warning message but don't reset the form

  //     // Handle fallback logic if no parts were added
  //     const part_num = this.addOrEdit.partNo.value.item_id || '';
  //     const kanban = this.addOrEdit.kanbanNo.value?.kanban || '';
  //     const date_raised = this.addOrEdit.orderDate.value;
  //     const kvc_conf = this.addOrEditform.get('vanConfirmation').value || '';
  //     const dest_code = this.addOrEdit.destCode.value?.dest_code || '';
  //     const dock = this.addOrEdit.dockNo.value?.dock || '';
  //     const reason_code = this.addOrEdit.reason.value?.reason_code || '';

  //     const payload = {
  //       part_num: part_num,
  //       kanban: kanban,
  //       dest_code: dest_code,
  //       air_order_no: this.easternCurrentDate,
  //       business_entity: this.namcValue,
  //       userid: this.userName,
  //       workid: this.user,
  //       date_raised: date_raised,
  //       kvc_conf: kvc_conf,
  //       dock: dock,
  //       user_role: this.userRole,
  //       schedules: getSchedules.map((schedule) => ({
  //         arriving_date: schedule.arriving_date,
  //         eta_date: schedule.eta_date,
  //       })),
  //     };

  //     sendToApi.push(payload);
  //   }

  //   console.log(sendToApi, 'sendToApi.....');

  //   // Send the constructed payload to the API
  //   // this.airFreightService.addAirOrderDetails(sendToApi).subscribe(
  //   //   (responseData) => {
  //   //     console.log(responseData, 'Response Data...');
  //   //     // this.orderService.setOrderData(responseData);
  //   //     // this.router.navigate(['ordering/airfreightorder']);
  //   //   },
  //   //   (error) => {
  //   //     console.error('Error occurred while saving the order:', error);
  //   //   }
  //   // );
  // }

  // Helper method to extract form values
  getFormValues() {
    const part_num = this.addOrEdit.partNo.value.item_id || '';
    const kanban = this.addOrEdit.kanbanNo.value?.kanban || '';
    const date_raised = this.addOrEdit.orderDate.value || '';
    const raised_by = this.addOrEdit.raisedBy.value || '';
    const kvc_conf = this.addOrEditform.get('vanConfirmation').value || '';
    const dest_code = this.addOrEdit.destCode.value?.dest_code || '';
    const dock = this.addOrEdit.dockNo.value?.dock || '';
    const reason_code = this.addOrEdit.reason.value?.reason_code || '';
    const part_type = this.addOrEdit.partType.value;
    const order_type = this.addOrEditform.controls.orderType.value?.order_type;
    const resp = this.addOrEdit.responsibilityregion.value;
    const air_case_code = this.addOrEditform.controls.airCode.value?.air_case_code || '';

    const order_lot = this.addOrEdit.qpcBox.value || '';
    const volume = this.addOrEdit.volumesize.value;
    const box_weight =
      this.addOrEdit.boxWeight.value === '' || this.addOrEdit.boxWeight.value === undefined
        ? null
        : this.addOrEdit.boxWeight.value;

    const part_desc = this.addOrEdit.part_description.value || '';
    const buco_code = this.addOrEdit.budgetCodes.value?.budget_code || '';
    const comment1 = this.addOrEdit.firstComment.value || '';
    const comment2 = this.addOrEdit.secondComment.value || '';
    const cost_calc = this.addOrEdit.costCalculation.value;
    const manager_id =
      this.manager_filter_id === '' || this.manager_filter_id === undefined ? null : this.manager_filter_id;
    const kvc_id = this.kvc_filter_id === '' || this.kvc_filter_id === undefined ? null : this.kvc_filter_id;
    const total_order =
      this.addOrEdit.totalOrder.value === '' || this.addOrEdit.totalOrder.value === undefined
        ? null
        : this.addOrEdit.totalOrder.value;
    const est_cost =
      this.addOrEdit.estimatedCost.value === '' || this.addOrEdit.estimatedCost.value === undefined
        ? null
        : this.addOrEdit.estimatedCost.value;
    const pieces_to_order =
      this.addOrEdit.piecesToOrder.value === '' || this.addOrEdit.piecesToOrder.value === undefined
        ? null
        : this.addOrEdit.piecesToOrder.value;

    const lots_to_order =
      this.addOrEdit.lotsToOrder.value === '' || this.addOrEdit.lotsToOrder.value === undefined
        ? null
        : this.addOrEdit.lotsToOrder.value;

    console.log(buco_code, 'buco_code.....');

    return {
      part_num,
      kanban,
      date_raised,
      kvc_conf,
      dest_code,
      dock,
      reason_code,
      pieces_to_order,
      lots_to_order,
      part_type,
      order_type,
      resp,
      order_lot,
      volume,
      air_case_code,
      manager_id,
      box_weight,
      part_desc,
      buco_code,
      comment1,
      comment2,
      cost_calc,
      kvc_id,
      total_order,
      est_cost,
      raised_by,
    };
  }

  saveOrder() {
    localStorage.setItem('isAdd', 'true');
    //const reason_code = this.addOrEdit.reason.value?.reason_code || '';

    this.updateInvalidFields();
    if (this.addOrEditform.invalid) {
      //this.warningMessage = 'Please fill all the mandatory fields before adding a part.';
      this.warningMessage = `Please fill all the mandatory fields before adding a part - (${this.invalidFields.join(
        ', '
      )}`;
        console.log(this.warningMessage);
        if(this.warningMessage.includes('number of pieces to order or number of lots to order')){
          this.warningMessage = this.warningMessage.replace('number of pieces to order or number of lots to order','');
          this.warningMessage = this.warningMessage + (this.isLotsSelected ? ' number of lots to order)': ' number of pieces to order)');
        }
           
      this.scrollToTop();
      this.focusFirstInvalidControl();
      return;
    }

    console.log('i am calling......');
    let sendToApi = [];

    // Get the updated schedules
    let getSchedules = this.rowData.filter((row) => row.isUpdated);
    console.log(getSchedules, 'getSchedules...');

    // Validate schedules
    if (!this.requiredSchedules(getSchedules)) {
      return; // Stop execution if validation failed in requiredSchedules
    }

    if (this.addOrEdit.orderType.value.order_type == 'Lots') {
      if (!this.validate_peaceOfOrder(parseInt(this.addOrEdit.lotsToOrder.value), this.rowData, 1)) return false;
    } else if (this.addOrEdit.orderType.value.order_type == 'Pieces') {
      if (!this.validate_peaceOfOrder(parseInt(this.addOrEdit.piecesToOrder.value), this.rowData, 2)) return false;
    }

    // Filter out empty parts

    console.log(this.parts, 'parts........');
    // this.parts = this.parts.filter((part) => part.part_num); // Check for non-empty part_num
    const part_details_final = this.parts.filter((part) => part.part_num); // Check for non-empty part_num

    // Get form values using the helper method
    const {
      part_num,
      kanban,
      date_raised,
      kvc_conf,
      dest_code,
      dock,
      reason_code,
      pieces_to_order,
      part_type,
      order_type,
      resp,
      order_lot,
      volume,
      air_case_code,
      box_weight,
      part_desc,
      buco_code,
      comment1,
      comment2,
      cost_calc,
      manager_id,
      kvc_id,
      total_order,
      est_cost,
      lots_to_order,
      raised_by,
    } = this.getFormValues();
    this.globalQpc = order_lot;
    // hide change
    // const hasAddedParts = this.parts.length > 0;

    const hasAddedParts = part_details_final.length > 0;

    if (hasAddedParts) {
      const newpart = {
        part_num: part_num,
        kanban: kanban,
        dest_code: dest_code,
        air_order_no: this.easternCurrentDate,
        business_entity: this.namcValue,
        userid: this.userName,
        workid: this.user,
        date_raised: date_raised,
        kvc_conf: kvc_conf,
        dock: dock,
        reason: reason_code,
        user_role: this.userRole,
        pieces_to_order: pieces_to_order,
        order_type: order_type,
        part_type: part_type,
        resp: resp,
        order_lot: order_lot,
        volume: volume,
        air_case_code: air_case_code,
        box_weight: box_weight,
        part_desc: part_desc,
        buco_code: buco_code,
        comment1: comment1,
        comment2: comment2,
        cost_calc: cost_calc,
        manager_id: manager_id,
        kvc_id: kvc_id,
        total_order: total_order,
        est_cost: est_cost,
        lots_to_order: lots_to_order,
        schedules: getSchedules.map((schedule) => ({
          arriving_date: schedule.arriving_date,
          eta_date: this.formatDate(schedule.eta_date),
          case_no: schedule.case_no,
          deadline_date: schedule.deadline_dates,
          number_of_pieces: schedule.number_of_pieces,
          number_of_lots: schedule.number_of_lots,
          conf_lots: schedule.conf_lots,
          conf_pieces: schedule.conf_pieces,
          air_order_status: schedule.isArrived ? 'ARRIVED' : kvc_conf === 'Yes' ? 'CONFIRMED' : 'CREATED',
        })),
      };

      // Check if the part already exists in the list
      const partExists = part_details_final.some((part) => part.part_num === newpart.part_num);

      if (partExists) {
        // If the part exists, show a warning but do not push a new part
        this.warningMessage = `This part number already exists. Please check the part numbers.`;
        this.scrollToTop();

        // Instead of adding a new part, find and update the existing part
        const existingPartIndex = part_details_final.findIndex((part) => part.part_num === newpart.part_num);
        if (existingPartIndex !== -1) {
          // Update the part in the list instead of adding a new one
          part_details_final[existingPartIndex] = { ...part_details_final[existingPartIndex], ...newpart };
        }

        // Do not add a new part (no part3), just return
        return;
      }

      // If part doesn't exist, push the new part to the array
      part_details_final.push(newpart);
      console.log(this.parts, 'new parts');

      // Continue with the rest of the process (sending to API, etc.)
      part_details_final.forEach((part) => {
        if (part.part_num) {
          console.log(part, 'partdetails');
          console.log(part.pieces_to_order);
          console.log(part.schedules[0], 'Teacher');

          // Ensure you get the specific schedules for the current part
          let schedule_details = part.schedules.map((schedule: any) => ({
            arriving_date: schedule.arriving_date,
            eta_date: schedule.eta_date,
            case_no: schedule.case_no,
            deadline_date: schedule.deadline_date,
            number_of_pieces: schedule.number_of_pieces,
            number_of_lots: schedule.number_of_lots,
            conf_lots: schedule.conf_lots,
            conf_pieces: schedule.conf_pieces,
            air_order_status: schedule.isArrived ? 'ARRIVED' : kvc_conf === 'Yes' ? 'CONFIRMED' : 'CREATED',
          }));

          const payload = {
            part_num: part.part_num, // Use part_num
            kanban: part.kanban,
            dest_code: part.dest_code,
            air_order_no: this.easternCurrentDate,
            business_entity: this.namcValue,
            workid: this.user,
            userid: this.userName,
            user_role: this.userRole,
            date_raised: date_raised,
            kvc_conf: kvc_conf,
            dock: dock,
            reason: reason_code,
            pieces_to_order: part.pieces_to_order,
            part_type: part_type,
            order_type: order_type,
            order_lot: order_lot,
            volume: volume,
            air_case_code: air_case_code,
            box_weight: box_weight,
            part_desc: part_desc,
            buco_code: buco_code,
            comment1: comment1,
            comment2: comment2,
            cost_calc: cost_calc,
            manager_id: manager_id,
            kvc_id: kvc_id,
            total_order: total_order,
            est_cost: est_cost,
            lots_to_order: lots_to_order,
            raised_by,
            schedules: schedule_details, // Use schedules for the current part
          };

          sendToApi.push(payload);
        }
      });
    } else {
      console.log('i am callingelse...');
      // If parts are not added and form is invalid, show the warning message but don't reset the form
      const payload = {
        part_num: part_num,
        kanban: kanban,
        dest_code: dest_code,
        air_order_no: this.easternCurrentDate,
        business_entity: this.namcValue,
        userid: this.userName,
        workid: this.user,
        date_raised: date_raised,
        kvc_conf: kvc_conf,
        dock: dock,
        user_role: this.userRole,
        reason: reason_code,
        pieces_to_order: pieces_to_order,
        part_type: part_type,
        order_type: order_type,
        resp: resp,
        order_lot: order_lot,
        volume: volume,
        air_case_code: air_case_code,
        box_weight: box_weight,
        part_desc: part_desc,
        buco_code: buco_code,
        comment1: comment1,
        comment2: comment2,
        cost_calc: cost_calc,
        manager_id: manager_id,
        kvc_id: kvc_id,
        total_order: total_order,
        est_cost: est_cost,
        lots_to_order: lots_to_order,
        raised_by: raised_by,
        schedules: getSchedules.map((schedule) => ({
          arriving_date: schedule.arriving_date,
          eta_date: this.formatDate(schedule.eta_date),
          case_no: schedule.case_no,
          deadline_date: schedule.deadline_dates,
          number_of_pieces:
            schedule.number_of_pieces === null ||
            schedule.number_of_pieces === undefined ||
            schedule.number_of_pieces === ''
              ? schedule.number_of_lots * this.globalQpc
              : schedule.number_of_pieces,
          number_of_lots:
            schedule.number_of_lots === null || schedule.number_of_lots === undefined || schedule.number_of_lots === ''
              ? schedule.number_of_pieces / this.globalQpc
              : schedule.number_of_lots,
          conf_lots: schedule.conf_lots,
          conf_pieces: schedule.conf_pieces,
          air_order_status: schedule.isArrived ? 'ARRIVED' : kvc_conf === 'Yes' ? 'CONFIRMED' : 'CREATED',
        })),
      };
      sendToApi.push(payload);
    }

    console.log(sendToApi, 'sendToApi.....');
    this.removeMessage();
    //Send the constructed payload to the API
    this.airFreightService.addAirOrderDetails(sendToApi).subscribe({
      error: this.errorCallback,
      next: (responseData) => {
        if (responseData) {
          this.orderService.setOrderData(responseData?.body?.addResponse?.updatedRows?.data);
          localStorage.setItem('isAdd', 'true');
          this.router.navigate(['ordering/airfreightorder']);
        }
      },
    });
  }
  onCancel() {
    localStorage.setItem('isClearMsg', 'true');
    this.router.navigate(['ordering/airfreightorder']);
  }
  // Function to delete a part form from the FormArray
  updateFormAfterDeletion() {
    // Example: If you are tracking the number of parts
    this.totalParts = this.parts.length;

    // If you need to update the UI to reflect that a part was deleted
    if (this.totalParts === 0) {
      // this.showNoPartsMessage = true;
      console.log('no parts');
    } else {
      // this.showNoPartsMessage = false;
      console.log('parts');
    }
  }

  // deletePart(index: number): void {
  //   // Check if deleting from filteredData or parts
  //   if (this.filteredData && this.filteredData.length > 0) {
  //     // Remove from filteredData if applicable
  //     this.filteredData.splice(index, 1);
  //   } else {
  //     // Remove from parts array
  //     this.parts.splice(index, 1);
  //   }

  //   // Optionally, if there's any logic related to updating form fields or selections, handle it here
  //   // For example, reset the form or update other related properties
  //   this.updateFormAfterDeletion();
  // }

  //checking for if only 1 part should not delete
  deletePart(index: number): void {
    // Check if there is only one part left
    const isOnlyOnePart =
      (this.filteredData && this.filteredData.length === 1) || (this.parts && this.parts.length === 1);
    // Prevent deletion if there is only one part left
    if (isOnlyOnePart) {
      this.warningMessage = 'Air order having only one part, cannot be deleted.';
      return; // Exit the function without deleting
    }
    console.log(this.filteredData[index]);
    const exportData = [];
    // exportData.push(data);
    exportData.push({ ...this.filteredData[index], isParts: true });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        console.log(this.rowData);
        // Check if deleting from filteredData or parts
        if (this.filteredData && this.filteredData.length > 0) {
          // Remove from filteredData if applicable
          this.filteredData.splice(index, 1);
        } else {
          // Remove from parts array
          this.parts.splice(index, 1);
        }

        this.updateFormAfterDeletion();
      }
    });

    //   // Optionally, if there's any logic related to updating form fields or selections, handle it here
    //   // For example, reset the form or update other related properties
    //   this.updateFormAfterDeletion();
    // }
  }

  deleteSchedule(data) {
    if(this.rowData.length == 1){
      this.warningMessage = 'Part having only one Schedule, cannot be deleted.';
      this.scrollToTop();
      return; // Exit the function without deleting
    }
    const exportData = [];
    // exportData.push(data);
    exportData.push({ ...data, isSchedule: true });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        //  let rowData2 = this.rowData.filter(value => value !== data);
        if (data >= 0 && data < this.rowData.length) {
          // Remove the row at rowNodeIndex
          this.rowData.splice(data, 1);
          this.gridOptions.api.setRowData(this.rowData);
        }
      }
    });    
  }

  ConfigureParameter() {
    console.log('add-edit config');
    const modalComponent = 'modal-component';
    localStorage.setItem('isBudgetCodeSetToDefault', 'false');
    localStorage.setItem('isAirCaseCodeSetToDefault', 'false');
    localStorage.setItem('isContactadded', 'false');

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = modalComponent;
    dialogConfig.maxHeight = '520px';
    dialogConfig.width = '900px';
    const dialogRef = this.dialog.open(ConfigParameterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      console.log('res');
      if (res === 'cancel') {
        let isBudgetCodeSetToDefault = localStorage.getItem('isBudgetCodeSetToDefault');
        let isAirCaseCodeSetToDefault = localStorage.getItem('isAirCaseCodeSetToDefault');
        let isContactadded = localStorage.getItem('isContactadded');

        if (isBudgetCodeSetToDefault === 'true') {
          console.log('inside if');
          this.spinner.show();
          this.airFreightService.getBudgetCodesDefault().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                this.addBudgetCodes = [];
                this.addBudgetCodes.push(...data.body.data);
                let a = data.body.data.find((item) => item.is_default === true)?.budget_code;
                this.addOrEditform.controls.budgetCodes.setValue(data.body.data[0]);
                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
        }
        if (isAirCaseCodeSetToDefault === 'true') {
          console.log('inside if');
          this.spinner.show();
          this.airFreightService.getAirCaseCodes().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                //dockNo
                this.addOrEditform.controls.dockNo.reset();
                this.addOrEditform.controls.airCode.reset()

                this.addAirCaseCode = []
                this.addAirCaseCode.push(...data.body.data);
                this.getAircodeForSelectedDock = this.addAirCaseCode;
                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
        }
        if (isContactadded === 'true') {
          console.log('inside if');
          this.spinner.show();
          this.airFreightService.getKvcDetails().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                this.kvcDetails = [];
                this.kvcDetails.push(...data.body.data);
                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
          this.airFreightService.getManagerDetails().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                this.managerDetails = [];
                this.managerDetails.push(...data.body.data);
                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
        }

        // this.callApiForAdd();
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            console.log('insidesave');
            return { backgroundColor: 'white' };
          }
        };
      }
    });
  }

  onKanbanChange(): void {
    const kanban = this.addOrEditform.controls.kanbanNo?.value?.kanban;
    const dock = this.addOrEditform.controls.dockNo?.value?.dock;

    // Disable part number dropdown
    this.addOrEditform.controls.partNo.disable();

    // Your logic to update the form values based on kanban and dock selection
    const item = this.qpcforParts.find((entry) => entry.kanban === kanban);
    if (item) {
      this.addOrEditform.controls.partNo.setValue({ item_id: item.item_id });
      this.addOrEditform.controls.part_description.setValue(item.part_description);
    }

    if (dock && kanban) {
      const itemWithDock = this.qpcforParts.find((entry) => entry.dock === dock && entry.kanban === kanban);
      if (itemWithDock) {
        this.addOrEditform.controls.qpcBox.setValue(itemWithDock.order_lot);
      }
    } else {
      const partNoList = this.qpcforParts.filter((element) => element.kanban === kanban);
      this.addAirOrderDock = partNoList;
      this.addAirOrderDock = this.addAirOrderDock.filter(
        (value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
      );
    }
    this.handleKeydown('input1', 'event');
  }

  onPartNoChange(): void {
    const part_no = this.addOrEditform.controls.partNo?.value?.item_id;
    const dock = this.addOrEditform.controls.dockNo?.value?.dock;

    // Disable kanban dropdown
    this.addOrEditform.controls.kanbanNo.disable();

    if (part_no) {
      const item = this.qpcforParts.find((entry) => entry.item_id === part_no);
      if (item) {
        this.addOrEditform.controls.kanbanNo.setValue({ kanban: item.kanban });
        this.addOrEditform.controls.part_description.setValue(item.part_description);
      }
    }

    if (dock && part_no) {
      const itemWithDock = this.qpcforParts.find((entry) => entry.dock === dock && entry.item_id === part_no);
      if (itemWithDock) {
        this.addOrEditform.controls.qpcBox.setValue(itemWithDock.order_lot);
      }
    } else {
      const partNoList = this.qpcforParts.filter((element) => element.item_id === part_no);
      this.addAirOrderDock = partNoList;
      this.addAirOrderDock = this.addAirOrderDock.filter(
        (value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
      );
    }
    this.handleKeydown('input1', 'event');
  }

  onDockChange(data): void {
    var dock = this.addOrEditform.controls.dockNo?.value?.dock;
    var part_no = this.addOrEditform.controls.partNo?.value?.item_id;
    const kanban = this.addOrEditform.controls.kanbanNo?.value?.kanban;

    if (dock) {
      let defaultAirCode = [];
      console.log('addAirCaseCode', this.getAircodeForSelectedDock);
      this.getAircodeForSelectedDock.forEach((item) => {
        console.log(item);
        console.log(dock);
        if (String(item.dock) === String(dock)) {
          defaultAirCode.push(item);
        }
      });

      console.log('this.kk', defaultAirCode);
      this.addAirCaseCode = defaultAirCode;
      const defaultItem = this.addAirCaseCode.find((item) => item.is_default === true);

      if (defaultItem) {
        this.addAirCaseCode = this.addAirCaseCode.filter((item) => item.is_default !== true);
        this.addAirCaseCode.unshift(defaultItem);
        this.addOrEditform.controls.airCode.setValue(this.addAirCaseCode[0]);
      }

      // Reset kanban and part dropdowns and reload options
      const partNoList = this.qpcforParts.filter((element) => element.dock === dock);

      // Populate new kanban options
      const newkanban = partNoList
        .map((item) => ({ kanban: item.kanban }))
        .sort((a, b) => parseInt(a.kanban) - parseInt(b.kanban));

      // Populate new part options
      const newPartNoList = partNoList
        .map((item) => ({ item_id: item.item_id }))
        .sort((a, b) => parseInt(a.item_id) - parseInt(b.item_id));

      // Update dropdown options
      this.addAirOrderKanban = newkanban;
      this.addAirOrderPart = newPartNoList;

      // Reset the selected value in the kanban and partNo form controls
      this.addOrEditform.controls.kanbanNo.reset();
      this.addOrEditform.controls.partNo.reset();
      this.addOrEditform.controls.partNo.enable();
      this.addOrEditform.controls.kanbanNo.enable();
    }

    if (dock && part_no) {
      const item = this.qpcforParts.find((entry) => entry.dock === dock && entry.item_id === part_no);
      if (item) {
        this.addOrEditform.controls.qpcBox.setValue(item.order_lot);
      }
    } else if (dock && kanban) {
      const item = this.qpcforParts.find((entry) => entry.dock === dock && entry.kanban === kanban);
      if (item) {
        this.addOrEditform.controls.qpcBox.setValue(item.order_lot);
      }
    } else {
      const partNoList = this.qpcforParts.filter((element) => element.dock === dock);

      const newPartNoList = partNoList
        .map((item) => ({ item_id: item.item_id }))
        .sort((a, b) => parseInt(a.item_id) - parseInt(b.item_id));

      const newkanban = partNoList
        .map((item) => ({ kanban: item.kanban }))
        .sort((a, b) => parseInt(a.kanban) - parseInt(b.kanban));

      this.addAirOrderPart = newPartNoList;
      this.addAirOrderKanban = newkanban;
    }

    this.handleKeydown('input1', 'event');
  }

  preventKeyPress(event: KeyboardEvent): void {
    event.preventDefault(); // Prevent any key press
  }

  requiredSchedules(getSchedules) {
    if (getSchedules.length > 0) {
      // Check if any schedule is missing required fields
      const missingField = getSchedules.some((schedule) => !schedule.arriving_date || !schedule.deadline_dates);

      if (missingField) {
        this.warningMessage = 'At least 1 arrival schedule must be filled in.';
        this.scrollToTop();
        return false; // Return false to indicate validation failed
      }

      // Proceed with mapping the schedules if all fields are present
      this.schedules = getSchedules.map((schedule) => ({
        arriving_date: schedule.arriving_date,
        eta_date: schedule.eta_date,
        case_no: schedule.case_no,
        deadline_date: schedule.deadline_dates,
        number_of_pieces: schedule.number_of_pieces,
        number_of_lots: schedule.number_of_lots,
        conf_lots: schedule.conf_lots,
        conf_pieces: schedule.conf_pieces,
        air_order_status: 'CREATED',
      }));
      return true; // Validation passed
    } else {
      this.warningMessage = 'At least 1 arrival schedule must be filled in.';
      this.scrollToTop();
      return false; // No schedules added, validation failed
    }
  }

  checkIfPartExist(newpart) {
    console.log(this.parts, newpart, 'checking parts...');

    // Check if the part already exists by comparing part_num
    const partExists = this.parts.some(
      (part) => part.part_num === newpart.part_num && part !== newpart // Ensure it's not the same part being updated
    );

    if (partExists) {
      // If the part exists, show a warning and do not add a new part
      this.warningMessage = `This part number already exists. Please check the part numbers.`;
      this.scrollToTop();
      return true; // Stop further execution and return true
    }

    return false; // No duplicate, safe to add the new part
  }
  getMangerIdByNameAndRole() {
    const manager_id = this.addOrEditform.controls.managerName.value?.contact_name || '';
    const role = this.managerDetails.filter((role) => role.contact_name === manager_id);
    this.manager_filter_id = role[0]?.contact_id;
    console.log('this.manager_filter_id', this.manager_filter_id);
    return role;
  }
  getKvcIDdByNameAndRole() {
    const kvc_id = this.addOrEditform.controls.KvcName.value?.contact_name || '';
    console.log('manager', kvc_id);

    const role = this.kvcDetails.filter((role) => role.contact_name === kvc_id);
    this.kvc_filter_id = role[0]?.contact_id;
    console.log('this.manager_filter_id', this.kvc_filter_id);
    return role;
  }

  private focusFirstInvalidControl() {
    const controlOrder = [
      { name: 'dockNo', element: this.dockNoDropdown },
      { name: 'kanbanNo', element: this.kanbanNoInput },
      { name: 'airCode', element: this.aircaseInput },
      { name: 'piecesToOrder', element: this.piecesToOrderInput },
      //{ name: 'airCode', element: this.airCodeDropdown },
    ];

    for (const control of controlOrder) {
      const formControl = this.addOrEditform.get(control.name);
      if (formControl && formControl.invalid) {
        const element = control.element;

        if (element) {
          // For PrimeNG Dropdown
          if ('focusInputViewChild' in element && element.focusInputViewChild?.nativeElement) {
            element.focusInputViewChild.nativeElement.focus();
          }
          // For other native inputs
          else if ('nativeElement' in element) {
            element.nativeElement.focus();
          }
          // Log if the element cannot be focused
          else {
            console.warn(`Cannot focus on control: ${control.name}`);
          }
          break;
        }
      }
    }
  }
  empty_valueCheck(value: any) {
    if (Number.isNaN(value) || value == undefined || value == '' || value == null) return true;
    return false;
  }

  validate_peaceOfOrder(peaceofOrderr: number, requiredArrivalSchdulesArray: any, value_: number) {
    var error_msg = false;
    var schedulesNoOfPeace: number = 0;
    console.log(requiredArrivalSchdulesArray);
    requiredArrivalSchdulesArray.forEach((value: any, index: number) => {
      // value = 1 = lots
      // value = 2 = peaces
      if (value_ == 1 && !this.empty_valueCheck(value.conf_lots) && value.conf_lots <= 0) {
        if (!error_msg) {
          this.warningMessage = "Conf lots can't be negative or 0 in row No." + (index + 1);
          error_msg = true;
        }
      }
      if (value_ == 2 && !this.empty_valueCheck(value.conf_pieces) && value.conf_pieces <= 0) {
        if (!error_msg) {
          this.warningMessage = "Conf Pieces can't be negative or 0 in row No." + (index + 1);
          error_msg = true;
        }
      }
      // if ((!this.empty_valueCheck(value.conf_lots) && value.conf_lots > 0) && (value.etaDate.value == "Invalid date" || this.empty_valueCheck(value.etaDate.value))) {
      //   if (!error_msg) {
      //     this.warningMessage = 'Please Enter Van Center ETA in row No.' + (index + 1);
      //     error_msg = true;
      //   }
      // }
      
      if (value_ == 1){
      if ((this.empty_valueCheck(value.conf_lots) || value.conf_lots <= 0) && (value.etaDate.value != "Invalid date" && !this.empty_valueCheck(value.etaDate.value))) {
        if (!error_msg) {
          this.warningMessage = 'Conf lots must be enter when Van Center ETA date is filled in row No.' + (index + 1);
          error_msg = true;
        }
      }
    }else if (value_ == 2){
      if ((this.empty_valueCheck(value.conf_pieces) || value.conf_pieces <= 0) && (value.etaDate.value != "Invalid date" && !this.empty_valueCheck(value.etaDate.value))) {
        if (!error_msg) {
          // this.warningMessage = 'Please Enter conf Pieces in row No.' + (index + 1);
          this.warningMessage = 'Conf Pieces must be enter when Van Center ETA date is filled in row No.' + (index + 1);
          error_msg = true;
        }
      }
    }
    if((value.etaDate.value != "Invalid date" && !this.empty_valueCheck(value.etaDate.value))){
      const filteredData = this.calendarList.filter(item => new Date(item.date.split('T')[0]).getTime() == new Date(value.etaDate.value).getTime());
      if(filteredData[0]?.work_status == '0'){
        if (!error_msg) {
          this.warningMessage = "Please select another Van center date in row No." + (index + 1) + ". It is non working day";
          error_msg = true;
        }
      }
    }
   


      if (value_ == 1) {
        if (
          (this.empty_valueCheck(value.conf_lots) || value.conf_lots <= 0) &&
          value.etaDate.value != 'Invalid date' &&
          !this.empty_valueCheck(value.etaDate.value)
        ) {
          if (!error_msg) {
            this.warningMessage = 'Please Enter conf lots in row No.' + (index + 1);
            error_msg = true;
          }
        }
      } else if (value_ == 2) {
        if (
          (this.empty_valueCheck(value.conf_pieces) || value.conf_pieces <= 0) &&
          value.etaDate.value != 'Invalid date' &&
          !this.empty_valueCheck(value.etaDate.value)
        ) {
          if (!error_msg) {
            this.warningMessage = 'Please Enter conf Pieces in row No.' + (index + 1);
            error_msg = true;
          }
        }
      }
      if (value.etaDate.value != 'Invalid date' && !this.empty_valueCheck(value.etaDate.value)) {
        const filteredData = this.calendarList.filter(
          (item) => new Date(item.date.split('T')[0]).getTime() == new Date(value.etaDate.value).getTime()
        );
        if (filteredData[0]?.work_status == '0') {
          if (!error_msg) {
            this.warningMessage =
              'Please select another Van center date in row No.' + (index + 1) + '. It is non working day';
            error_msg = true;
          }
        }
      }

      // if (value.conf_lots <= 0) {
      //   if (!error_msg) {
      //     this.warningMessage = 'Conf lots cannot be negative or 0 in row No.' + (index + 1);
      //     error_msg = true;
      //   }
      // }
      // if ((!this.empty_valueCheck(value.conf_lots) && value.conf_lots > 0) && this.empty_valueCheck(value.eta_date)) {
      //   if (!error_msg) {
      //     this.warningMessage = 'Please Enter Van Center ETA in row No.' + (index + 1);
      //     error_msg = true;
      //   }
      // }
      // if ((this.empty_valueCheck(value.conf_lots) || value.conf_lots <= 0) && !this.empty_valueCheck(value.eta_date)) {
      //   if (!error_msg) {
      //     this.warningMessage = 'Please Enter conf lots in row No.' + (index + 1);
      //     error_msg = true;
      //   }
      // }

      if (value_ == 2) {
        if (this.empty_valueCheck(value.number_of_pieces) || parseInt(value.number_of_pieces) <= 0) {
          if (!error_msg) {
            this.warningMessage = 'Please enter Valid No of Pieces in row No.' + (index + 1);
            error_msg = true;
          }
        }
        schedulesNoOfPeace = schedulesNoOfPeace + parseInt(value.number_of_pieces);
      } else if (value_ == 1) {
        if (this.empty_valueCheck(value.number_of_lots) || parseInt(value.number_of_lots) <= 0) {
          if (!error_msg) {
            this.warningMessage = 'Please enter Valid No of Lots in row No.' + (index + 1);
            error_msg = true;
          }
        }
        schedulesNoOfPeace = schedulesNoOfPeace + parseInt(value.number_of_lots);
      }
      // validation arrival date and deadline date
      if (this.empty_valueCheck(value.arriving_date)) {
        if (!error_msg) {
          this.warningMessage = "Arrival date can't ne empty in row No." + (index + 1);
          error_msg = true;
        }
      } else {
        const filteredData = this.calendarList.filter(
          (item) => new Date(item.date.split('T')[0]).getTime() == new Date(value.arriving_date).getTime()
        );
        if (filteredData[0]?.work_status == '0') {
          if (!error_msg) {
            this.warningMessage =
              'Please select another arrival date in row No.' + (index + 1) + '. It is non working day';
            error_msg = true;
          }
        }
      }

      if (this.empty_valueCheck(value.deadline_dates)) {
        if (!error_msg) {
          this.warningMessage = "Deadline date can't ne empty in row No." + (index + 1);
          error_msg = true;
        }
      }

      if (new Date(value.arriving_date).getTime() > new Date(value.deadline_dates).getTime()) {
        if (!error_msg) {
          this.warningMessage = 'Arrival date should be less than or equal to deadline data in row No.' + (index + 1);
          error_msg = true;
        }
      }
      if (value_ == 1 && value.isArrived && (this.empty_valueCheck(value.conf_lots) || value.etaDate.value == 'Invalid date' || this.empty_valueCheck(value.etaDate.value))) {
        if (!error_msg) {
          this.warningMessage = 'Arrived Flag can only be checked when ETA DAte and Conf lots filled in row No.' + (index + 1);
          error_msg = true;
        }
      }
  
      if (value_ == 2 && value.isArrived && (this.empty_valueCheck(value.conf_pieces) || value.etaDate.value == 'Invalid date' || this.empty_valueCheck(value.etaDate.value))) {
        if (!error_msg) {
          // Lakshay = 7404355390 - 615341C
          this.warningMessage = 'Arrived Flag can only be checked when ETA DAte and Conf pieces filled in row No.' + (index + 1);
          error_msg = true;
        }
      }
    });
    if (error_msg) {
      this.scrollToTop();
      return false;
    }
    if (peaceofOrderr == schedulesNoOfPeace) return true;
    else {
      if (value_ == 1)
        //this.warningMessage = 'No. of Lots to order and sum of schdules\'s No. of Lots should be same.';
        this.warningMessage =
          ' Total no of lots from the required arrival schedule(s) must be equal to number of lots to order';
      else if (value_ == 2)
        //this.warningMessage = 'No. of Pieces to order and sum of schdules\'s No. of peaces should be same.';
        this.warningMessage =
          ' Total no of pieces from the required arrival schedule(s) must be equal to number of pieces to order';
      this.scrollToTop();
      return false;
    }
  }

  validatePiecesOrLots(): boolean {
    const piecesToOrder = this.addOrEditform.controls.piecesToOrder.value;
    const lotsToOrder = this.addOrEditform.controls.lotsToOrder.value;

    // Ensure at least one of the fields is filled
    if (!piecesToOrder && !lotsToOrder) {
      return false; // Validation failed
    }

    return true; // Validation passed
  }

  updateInvalidFields() {
    this.invalidFields = []; // Reset invalidFields

    // Map form control names to their user-facing field names
    const fieldMapping: { [key: string]: string } = {
      dockNo: 'dock',
      partNo: 'part number',
      kanbanNo: 'kanban',
      airCode: 'air case code',
    };

    // Desired order of fields
    const fieldOrder = [
      'dock',
      'kanban',
      'part number',
      'air case code',
      'number of pieces to order or number of lots to order',
    ];

    // Loop through form controls to find invalid ones
    Object.keys(this.addOrEditform.controls).forEach((controlName) => {
      const control = this.addOrEditform.get(controlName);
      if (control && control.invalid && fieldMapping[controlName]) {
        const fieldName = fieldMapping[controlName];
        if (!this.invalidFields.includes(fieldName)) {
          this.invalidFields.push(fieldName);
        }
      }
    });

    // Special check for 'piecesToOrder' and 'lotsToOrder'
    const piecesToOrder = this.addOrEditform.get('piecesToOrder')?.value;
    const lotsToOrder = this.addOrEditform.get('lotsToOrder')?.value;

    // Add combined label only if both are null or empty
    if ((piecesToOrder === null || piecesToOrder === '') && (lotsToOrder === null || lotsToOrder === '')) {
      if (!this.invalidFields.includes('number of pieces to order or number of lots to order')) {
        this.invalidFields.push('number of pieces to order or number of lots to order');
      }
    }

    // Sort invalidFields based on the desired order
    this.invalidFields.sort((a, b) => fieldOrder.indexOf(a) - fieldOrder.indexOf(b));
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  onKeyPressDecimalAllow(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

    // Access the value of boxWeight directly from the form control
    const inputValue = this.addOrEdit.boxWeight.value;

    const isNumber = /^[0-9]$/.test(event.key);
    const isDecimalPoint = event.key === '.';

    // Allow number keys and allowed keys
    if (isNumber || allowedKeys.includes(event.key)) {
        return;
    }

    if (isDecimalPoint) {
        // Allow only one decimal point
        if (inputValue.includes('.')) {
            event.preventDefault();
        }
        return;
    }

    // Allow numbers only up to two decimal places
    const [integerPart, decimalPart] = inputValue.split('.');
    
    // Simplified logic: prevent default if there are already two decimal places
    if (decimalPart && decimalPart.length >= 2) {
        event.preventDefault();
    }
}

formatDate(date) {

  return date && date !== "" && moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : null;
}


}
