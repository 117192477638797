import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Moment } from 'moment';
@Component({
  selector: 'app-review-shifts',
  templateUrl: './review-shifts.component.html',
  styleUrls: ['./review-shifts.component.scss'],
})
export class ReviewShiftsComponent implements OnInit {
  columnDefs;
  rowSelection;
  editType;
  rowData;
  gridOptions;
  enableSave: boolean = false;
  context: any;

  suppressClickEdit = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<ReviewShiftsComponent>
  ) {
    this.columnDefs = [
      { headerName: 'Shift No', field: 'shift_no', width: 90 },
      { headerName: 'Day', field: 'day_of_week', width: 110,
        valueGetter: (params) => {
          switch (params.data.day_of_week) {
            case '1': return 'Sunday';
            case '2': return 'Monday';
            case '3': return 'Tuesday';
            case '4': return 'Wednesday';
            case '5': return 'Thursday';
            case '6': return 'Friday';
            case '7': return 'Saturday';
            default: return '';
          }
        }
      },
      {
        headerName: 'Start Time',
        field: 'start_time',
        width: 120,
        valueGetter: (params) => {
          const { start_time } = params.data || {}; 
          if (start_time && !moment(start_time, 'HH:mm:ss', true).isValid()) {
            return start_time;
          }
          return start_time || '';
        },
      },
      {
        headerName: 'End Time',
        field: 'end_time',
        width: 120,
        valueGetter: (params) => {
          const { end_time } = params.data || {}; 
          if (end_time && !moment(end_time, 'HH:mm:ss', true).isValid()) {
            return end_time;
          }
          return end_time || '';
        },
      },
      { headerName: 'Duration', field: 'duration_updated', width: 90 },
      {
        headerName: 'From',
        field: 'from_date',
        width: 120,
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          if (params.data.from_date) {
            params.data.from_date = moment(params.data.from_date).format('MM/DD/YYYY').substring(0, 10);
            return params.data.from_date;
          }
          return params.data.from_date;
        },
        filterParams: {
          comparator: this.dateComparator,
        },
        floatingFilter: true,
      },
      {
        headerName: 'To',
        field: 'to_date',
        width: 120,
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          const toDateValue = params.data.to_date;
          if (toDateValue === '12/31/2999' || moment(toDateValue).isSame('12/31/2999', 'day')) {
            return null; // Return empty string if it's '12/31/2999'
          }
          if (params.data.to_date) {
            params.data.to_date = moment(params.data.to_date).format('MM/DD/YYYY').substring(0, 10);
            return params.data.to_date;
          }
          return params.data.to_date;
        },
        filterParams: {
          comparator: this.dateComparator,
        },
        floatingFilter: true,
      },
      { headerName: 'Force', field: 'force_flag', width: 100 },
      {
        headerName: 'Error',
        field: 'error',
        width: 150,
        headerComponentParams: {
          template:
            '<span class="ag-header-cell-text" style="color:#D20000">Error</span>',
        },
        cellStyle: {
          color: '#D20000', // Display error text in red
        },
        cellRenderer: (params) => {
          // Display the error message from the row data (params.value is the error message)
          if (params.value) {
            return `<span>${params.value}</span>`;  // Show error message in red
          }
          return '';  // If no error, return empty string
        },
      }
      
    ];

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.rowData = data.modifiedRowData;
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {
      headerHeight: 38,
      rowHeight: 28,
      floatingFiltersHeight: 0,
      rowBuffer: 20,
      suppressMovableColumns: true,
      enableCellTextSelection: true,
      suppressContextMenu: false,
      suppressClipboardPaste: true,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
        filter: false,
        floatingFilter: false,
        wrapText: true,
        autoHeight: true,
        suppressPaste: false,
        floatingFilterComponentParams: { suppressFilterButton: true },
        unSortIcon: true,
        icons: {
          sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
        },
        filterParams: {
          textCustomComparator(filter, value, filterText) {
            const filterTextLowerCase = filterText.toLowerCase();
            let valueLowerCase = value?.params ? value.value.toString().toLowerCase() : value.toString().toLowerCase();
            return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
          },
          debounceMs: 1000,
          caseSensitive: true,
          suppressAndOrCondition: true,
          inRangeInclusive: true,
        },
      },
    };
    return this.gridOptions;
  }

  dateComparator(date1, date2) {
    const monthToNum = (date) => {
      if (date === undefined || date === null) {
        return null;
      }
      const newDate: Date = new Date(date);
      return newDate.getFullYear() * 10000 + newDate.getMonth() * 100 + newDate.getDate();
    };
    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }

  ngOnInit() {
    this.enableSave = true;
    let isValid = true; 
   
    // Iterate through rowData to validate dates
    this.rowData?.forEach((row) => {
      if (row.from_date && row.to_date) {
        console.log(row.to_date,'row.to_date')
        console.log(row.from_date,'row.from_date')
        const parsedFromDate = moment(row.from_date, 'MM/DD/YYYY');
        const parsedToDate = moment(row.to_date, 'MM/DD/YYYY');
        console.log(parsedFromDate,'parsedFromDate')
        console.log(parsedToDate,'parsedToDate')
        if (parsedFromDate.isAfter(parsedToDate)) {
          console.log(parsedFromDate,'parsedFromDate')
          console.log(parsedToDate,'parsedToDate')
          // Set error message if from_date is greater than to_date
          row.error = 'Error: from_date must be before to_date';
          isValid = false; // Disable Save button
        } else {
          row.error = ''; // Clear the error if valid
        }
      }
    });
    // Check if there are any errors
    const errorCount = this.rowData?.filter((x) => x.error).length;

    // Enable Save button only if there are no errors
    this.enableSave = errorCount === 0;
    this.enableSave = isValid;
  }

  onSaveChanges() {
    if (this.enableSave) {
      console.log('Changes are valid. Proceed with saving the data locally or update view.');
    } else {
      console.log('Validation failed. Please correct the errors.');
    }
  }
}
