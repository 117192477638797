import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OspService } from 'src/app/core/master-services/osp.service';


@Component({
  selector: 'app-copy-shifts-dialog',
  templateUrl: './copy-shifts-dialog.component.html',
  styleUrls: ['./copy-shifts-dialog.component.scss']
})
export class CopyShiftsDialogComponent implements OnInit{
    addCopyShiftsEventEmitter = new EventEmitter();
    editMasterEventEmitter = new EventEmitter();
    filteredDockList: any[] = [];
    dockList: any[] = [];
    dockListCopy: any[] = [];
    master_id: any;
    master_name: any;
    key_item: any;
    data_item: any;
    business_entity: any;
    new_key_item: any;
    cstatus: any;
    showaddpopup: boolean = false;
    showeditpopup: boolean = false;
    dock: any;
    popupname: any;
    shiftNumber: any;
    dayWeek: any;
    startTime: any;
    endTime: any;
    inMasterCalendar: any;
    inNextDay: any;
    dataItem: any;
    enableError: boolean = false;
    userForm: UntypedFormGroup;
    copyToNumbers: any[] = [];
    copyFromNumbers: any[] = [];
    dayWeeks:any[]=[];
    masterCalendar:any[]=[];
    nextDay:any[]=[];
    errorMessage: any;
    hoursArray = new Array(24);
    mintuesArray = new Array(60);
    addOrEditform: UntypedFormGroup = this.formBuilder.group({
      copyFrom: ['', Validators.required],
      copyToNumber: ['',Validators.required],
    },
    );
    constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private readonly dialogeRef: MatDialogRef<CopyShiftsDialogComponent>,
      private readonly formBuilder: UntypedFormBuilder,
      private readonly ospService: OspService,

    ) {}
    get addOrEdit() {
      return this.addOrEditform.controls;
    }
    ngOnInit(): void {
      this.filteredDockList = this.dockListCopy;
      this.loadDrop();
      this.business_entity = localStorage.getItem('namcvalue');
      this.copyToNumbers = [
        { value: 'M5' },
        { value: 'YY' },
      ];
      this.copyFromNumbers = [
        { value: 'F1' },
        { value: 'AD' },
      ];
      console.log("data---",this.data);
      if (this.data?.mode === 'edit') {
        if (this.data.data?.dock) {
          this.addOrEdit.dock.setValue(
            this.data.data.dock 
          );
          
        }
      }
    }
    loadDrop() {
      const data = {
        business_entity: localStorage.getItem('namcvalue'),
        workid: localStorage.getItem('workdayId'),
        user_role: localStorage.getItem('UserRoles'),
      };
      this.ospService.getDock(data).subscribe({
        next : (res:any) =>{
          this.dockListCopy = res.body;
        },
        
      })
     
    }
    submitted: boolean = false;
    onCopyFromChange(event: any): void {
      const selectedCopyFrom = event.value;
      this.filteredDockList = this.dockListCopy.filter((item) => item.dock !== selectedCopyFrom.dock);
      this.addOrEditform.get('copyToNumber')?.reset();
    }
    copyShiftsDetails() {
      if (!this.addOrEditform.valid) {
        this.submitted = true;
        return;
      }
      console.log(this.addOrEdit,'addOrEdit')
      console.log(this.addOrEditform,'addOrEditform')
      // Prepare the emitted data from the form controls
      const addEmittedData = {
        copyFrom: this.addOrEdit.copyFrom.value.dock,
        // copyFrom: this.addOrEdit.dock.value.dock,
        // copyToNumber: this.addOrEdit.copyToNumber.value,
        copyToNumber: this.addOrEdit.copyToNumber.value.dock,
      };
      // dock: this.addOrEdit.dock.value.dock,
    
      // Emit the data and close the dialog
      this.addCopyShiftsEventEmitter.emit(addEmittedData);
      this.dialogeRef.close();
    }

    editRowMasterDetails() {
      if (this.addOrEditform.invalid) {
        this.submitted = true;
        return;
      }
  
      const editEmittedData = {
        master_id: this.addOrEdit.masterId.value.master_id,
        master_name: this.addOrEdit.masterName.value.name,
        startTime: this.addOrEdit.startTime.value,
        data_item: this.addOrEdit.dataItem.value,
      };
      this.editMasterEventEmitter.emit(editEmittedData);
      this.dialogeRef.close();
      
    }
    
  
    callRespectiveFunction() {
      if (this.data?.mode === 'copy') {
        // this.editRowMasterDetails();
        this.copyShiftsDetails();

      } else {
        console.log("edit function is called");
        
        this.editRowMasterDetails();

      }
    }
    onKeyPress(event: KeyboardEvent) {
      const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
      const isNumber = /^[0-9]$/.test(event.key);
  
      // Allow number keys, allowed keys, and prevent default for others
      if (!isNumber && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    }
  }
  
