import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root'
})
export class ModuleStockingService {
  getToken = () => localStorage.getItem('Token');

  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        //'X-Api-Key': this.environment.partsinventoryKey,
      }),
    };
  }

  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      //'X-Api-Key': this.environment.partsinventoryKey,
    });
  };

  moduleStockingApiUrl = '';

  constructor(private http: HttpClient,
    private environment: EnvironmentConfiguration) {

    this.moduleStockingApiUrl = environment.moduleStockingUrl;
  }

  getmoduleStockingData(data): Observable<any> {
    return this.http.post<any>(
      `${this.moduleStockingApiUrl}/getmoduleStockingData`,
      data,
      this.getHttpCustomOptions()
    );
  }

  editModuleStocking(data): Observable<any> {
    return this.http.put<any>(
      `${this.moduleStockingApiUrl}/editModuleStocking`,
      data,
      this.getHttpCustomOptions()
    )
  }
}