<div *ngIf="data">
  <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3
        class="head"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ data.mode | uppercase }} SHIFTS
      </h3>
    </div>

    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        [mat-dialog-close]="true"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <form [formGroup]="addOrEditform">
    <div class="row">
      <div class="col-md-6">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">COPY FROM </label>
          <!-- <input class="key" type="text" autoWidth="false" maxlength="25" formControlName="copyFrom"
            placeholder="--Type--" ng-required="true" /> -->
            <p-dropdown
            id="selSupplier"
            [options]="dockListCopy" 
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [virtualScrollItemSize]="30"
            [showTransitionOptions]="'0ms'"
            placeholder="Select"
            optionLabel="dock"
            [filterBy]="'dock'" 
            formControlName="copyFrom"
            (onChange)="onCopyFromChange($event)"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '100%' }"
            appendTo="body"
            scrollHeight="180px"
          >
          </p-dropdown>
          
          <ng-container *ngIf="addOrEdit.copyFrom.errors && submitted">
            <small *ngIf="addOrEdit.copyFrom.errors?.required">
              Copy From is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="drpchildctrl">
        </div>
      </div>
      <div class="col-md-6" style="margin-top: 10px">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">COPY TO</label>
          <p-dropdown
            id="selSupplier"
            [options]="filteredDockList" 
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [virtualScrollItemSize]="30"
            [showTransitionOptions]="'0ms'"
            placeholder="Select"
            formControlName="copyToNumber"
            optionLabel="dock" 
            [filterBy]="'dock'" 
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '100%' }"
            appendTo="body"
            scrollHeight="180px"
          >
          </p-dropdown>
          <ng-container *ngIf="addOrEdit.copyToNumber.errors && submitted">
            <small *ngIf="addOrEdit.copyToNumber.errors?.required">
              Copy To is required.
            </small>
          </ng-container>
        </div>
      </div>
      
    </div>
    
  </form>
  <div class="text-right" style="margin-top: 20px">
    <button class="review_btn" mat-button (click)="callRespectiveFunction()">
      PASTE
    </button>

    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      Cancel
    </button>
  </div>
</div>
