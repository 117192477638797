import { ActivatedRoute } from '@angular/router';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, forkJoin } from 'rxjs';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../services/excel.service';
import { OrderExcelServiceService } from '../order-excel-service.service';
import { GriddataService } from '../services/griddata.service';
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { OSPStateService } from '../services/osp-state.service';
import { dateFilterComparator, resetSortingAndFilters, warningMessage, successMessage } from '../constants';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
// import { AirFreightActionsComponent } from './air-freight-actions/air-freight-actions.component';
import { OspService } from '../core/master-services/osp.service';

import { RowNode, GroupCellRendererParams, RowGroupingDisplayType } from 'ag-grid-community';
import { AirfreightApicallsService } from '../services/air-freight-validation/airfreight-apicalls.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ValidationService } from '../services/air-freight-validation/validations.service';
import { DataServiceService } from '../services/air-freight-validation/data-service.service';
// import { AirorderDeleteComponent } from './delete-airorder/airorder-delete.component';
// import { AirOrderCellRendererComponent } from './air-order-cell-renderer/air-order-cell-renderer.component';

@Component({
  selector: 'app-procurement',
  templateUrl: './procurement.component.html',
  styleUrls: ['./procurement.component.scss'],
})
export class ProcurementComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  count = 0;
  rowData = [];
  rowData1 = [];
  originalRowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  private isInitialized = false;
  columnDefsMaster;
  columnDefs;
  columnDefs1;
  columnDefs2;
  // columnDefsTemp;
  public defaultColDef;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;
  successMessage = '';
  canceledRecord: any = [];
  isShowArrived: boolean = false;
  isShowCanceled: boolean = false;
  isNavigatedFromSecond: boolean = false; // Flag to track navigation source
  autoGroupColumnDef;
  groupRowsSticky;
  groupDefaultExpanded = 1;
  showOpenedGroup = false;
  groupSelectsChildren;
  suppressRowClickSelection;
  suppressAggFuncInHeader;
  public groupDisplayType: RowGroupingDisplayType = 'singleColumn';
  dataFromSecond: any;
  private orderDataSubscription: Subscription;
  private orderEditDataSubscription: Subscription;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  onInitVanningFrom: any;
  onInitVanningTo: any;
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';

  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedStatus: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  adjList: any = [];
  selectedAdjlist: any = [];
  addAirOrderPart: any = [];
  addAirOrdereKanban: any = [];
  selectedAircaseCode: any = [];

  vanningFrom: any;
  vanningTo: any;
  adjustSubscription$: Subscription;

  // Dropdown Options
  ordDate: any;
  ordDateTo: any;
  onInitordDate: any;
  onIntordDateTo: any;

  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  statusList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;
  modalComponent = 'modal-component';
  addGetResponse = [];
  minVanningFrom;
  showHideList: any = [
    // {
    //   label: 'Air Order No',
    //   value: 'air_order_no',
    // },
    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'Air Case Code',
      value: 'air_case_code',
    },
    {
      label: 'Part Number',
      value: 'part_no',
    },

    {
      label: 'Kanban',
      value: 'kanban',
    },
    {
      label: 'Arrival DT',
      value: 'arrival_date',
    },
    {
      label: 'No Of Pieces',
      value: 'number_of_pieces',
    },
    {
      label: 'No Of Lots',
      value: 'number_of_lots',
    },

    {
      label: 'Dest Code',
      value: 'dest_code',
    },
    {
      label: 'Status',
      value: 'air_order_status',
    },
    {
      label: 'Van Center Confirmed',
      value: 'kvc_conf',
    },
    {
      label: 'Van Center ETA DT',
      value: 'vanning_date',
    },
    {
      label: 'Renban',
      value: 'case_no',
    },
    {
      label: 'Order DT',
      value: 'date_raised',
    },
    { value: 'userid', label: 'User' },
    { value: 'date_time', label: 'Updated DT' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;

  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  orderAdded: any;
  gridApi: any;
  pendingOrderData: any;
  pendingOrderEditData: any;
  itContact: string;
  airCaseCode: any = [];
  approvedItems: any

  // isEditMode = false;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly airFreightService: AirfreightApicallsService,
    private readonly lookupservice: LookupService,
    private router: Router,
    private validationService: ValidationService,
    private dataService: DataServiceService,
    private orderExcelService: OrderExcelServiceService,
    private route: ActivatedRoute
  ) {
    this.context = {
      componentParent: this,
    };

  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
    if (this.orderDataSubscription) {
      this.orderDataSubscription.unsubscribe();
    }
    if (this.orderEditDataSubscription) {
      this.orderEditDataSubscription.unsubscribe();
    }
  }

  getUrlParams(key: string) {
    const params = {};
    const queryString = window.location.search.substring(1);
    const regex = /([^&=]+)=([^&]*)/g;
    let match;

    while ((match = regex.exec(queryString))) {
      params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
    }

    return params[key];
  }
  removeURLParam() {
    const url = new URL(window.location.href);
    url.search = '';
    history.pushState({}, '', url.toString());
  }
  push_history() {
    var localstorage_key = this.getUrlParams('keyParam');
    if (localstorage_key != undefined && localstorage_key != null && localstorage_key != '') {
      const userData = localStorage.getItem(localstorage_key);
      const userObject = JSON.parse(userData);
      // console.log(userObject, 'temp_ userdetails');
      if (userObject != null && userObject !== undefined) {
        if (userObject.date_raised_from != undefined) this.ordDate = userObject.date_raised_from;
        if (userObject.date_raised_to != undefined) this.ordDateTo = userObject.date_raised_to;
        // select dest_code
        if (userObject.dest_code != undefined)
          userObject.dest_code.forEach((e_) => {
            this.selectedDestCode.push({ dest_code: e_ + '' });
          });
        // select dock
        if (userObject.dock != undefined)
          userObject.dock.forEach((e_) => {
            this.selectedDock.push({ dock: e_ });
          });
        // select kanban
        if (userObject.kanban != undefined)
          userObject.kanban.forEach((e_) => {
            this.selectedKanban.push({ kanban: e_ });
          });
        // select air case code
        if (userObject.air_case_code != undefined)
          // this.set_aircase_value(userObject);
          userObject.air_case_code.forEach((e_) => {
            this.selectedAircaseCode.push({ air_case_code: e_ + '' });
          });
        //select part no
        if (userObject.part_no != undefined)
          userObject.part_no.forEach((e_) => {
            this.selectedPartNo.push({ part_no: e_ });
          });
        //select status
        if (userObject.status != undefined)
          userObject.status.forEach((e_) => {
            this.selectedStatus.push({ air_order_status: e_ });
          });

        this.callSearch(userObject);
        localStorage.removeItem(localstorage_key);
      }
      // userObject.dock.forEach(e_ => {
      //   this.selectedDock.push({'dock':e_});
      // });
    }
    this.removeURLParam();
  }
  ngOnInit(): void {
    // this.push_history();
    // this.orderCreatedDownloadButton();
    console.log('ngoninit called');
    // let isClearMsg = localStorage.getItem('isClearMsg');
    const approvedItems = [
      'Production Part Requirements',
      'Service Part Requirements',
      'Total Part Requirements',
    ]
    this.approvedItems = approvedItems

    // this.columnDef1();
    this.rowData1=[
      {'sun':'5','mon':'5','tue':'5','wed':'5','thu':'5','fri':'5','sat':'5','total':'35'},
      {'sun':'5','mon':'5','tue':'5','wed':'5','thu':'5','fri':'5','sat':'5','total':'35'},
      {'sun':'10','mon':'10','tue':'10','wed':'10','thu':'10','fri':'10','sat':'10','total':'70'}
    ]
    this.pendingOrderData = null;
    this.pendingOrderEditData = null;
    this.orderDataSubscription = this.validationService.orderData$.subscribe((orderData) => {
      if (orderData) {
        this.pendingOrderData = orderData; // Store until init is complete
      }
    });

    //editorderDataSubject

    this.orderEditDataSubscription = this.validationService.editedData$.subscribe((orderEditData) => {
      if (orderEditData) {
        this.pendingOrderEditData = orderEditData; // Store until init is complete
      }
    });
    this.initializeGridColumns();
    this.columnDef1();
    this.adjustSubscription$ = this.stateService.getNamc().subscribe(() => {
      this.initializeData();
    });
  }
  private columnDef1() {
    this.columnDefs2 = [
      {
        headerName: '',
        sortable: false,
        field: 'approvedItems',
        width: 290,
        pinned: 'left',
        cellRenderer: (params: any) => {
          const index = this.approvedItems[params.node?.rowIndex]
          return `<span>${index}</span>`
        }
      },
      {
        headerName: 'Sunday',
        autoHeight: true,
        field: 'sun',
        width: 140,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Monday',
        autoHeight: true,
        field: 'mon',
        width: 130,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Tuesday',
        autoHeight: true,
        field: 'tue',
        width: 130,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Wednesday',
        autoHeight: true,
        field: 'wed',
        width: 130,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Thursday',
        autoHeight: true,
        field: 'thu',
        width: 130,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Friday',
        autoHeight: true,
        field: 'fri',
        width: 130,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Saturday',
        autoHeight: true,
        field: 'sat',
        width: 130,
        unSortIcon: true,
        sortable: true,
      },
      {
        headerName: 'Total',
        autoHeight: true,
        field: 'total',
        width: 140,
        unSortIcon: true,
        sortable: true,
      },
    ];
    this.columnDefs1 = [...this.columnDefs2];
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = (params) => {
      this.gridApi = params.api;
    };

  }
  // createColumnRendererForField(fieldName: string) {
  //   return {
  //     // Optimized valueGetter to avoid unnecessary operations
  //     valueGetter: (params) => {
  //       if (params.node.group) {
  //         // For group rows, return the value of the first child for the given field
  //         const firstChildData = params.node.allLeafChildren?.[0]?.data;
  //         if (firstChildData) {
  //           return firstChildData[fieldName];
  //         }
  //         return ''; // If no data, return empty string
  //       }
  //       // For child rows, return the value directly
  //       return params.data?.[fieldName] || ''; // Return empty string if field is not available
  //     },

  //     // Optimized cellRenderer: hide the value for group rows and show for child rows
  //     cellRenderer: (params) => {
  //       if (params.node.group) {
  //         return ''; // Instead of returning null, return an empty string
  //       }
  //       // For child rows, show the value of the field
  //       return params.value || ''; // Show the value for child rows
  //     },
  //   };
  // }

  private initializeGridColumns() {
    this.columnDefsMaster = [
      // {
      //   headerName: '',
      //   // cellRendererFramework: AirFreightActionsComponent,
      //   floatingFilterComponentFramework: GridRefreshComponent,
      //   field: 'actions',
      //   width: 80,
      //   sortable: false,
      //   floatingFilter: true,
      //   pinned: 'left',
      //   cellRendererParams: {
      //     // Pass a parameter to the component to determine if it should render
      //     showIcon: (params) => params.node.group, // Show icon only if it's not a group
      //   },
      // },
      {
        headerName: 'Air Order No',
        field: 'air_order_no',
        width: 170,
        // floatingFilter: true, // Enable floating filter
        tooltipField: 'air_order_no',
        headerTooltip: 'Air Order No',
        // rowGroup: true, // Grouping by air_order_no
        // hide: true, // Ensure it's not hidden for filtering
        // checkboxSelection: (params) => params.node.group,
        // headerCheckboxSelection: true,
        // // cellRendererFramework: AirOrderCellRendererComponent, // Use the custom cell renderer for all rows
        // filter: 'agTextColumnFilter', // Text filter for air_order_no
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true, // Optionally suppress filter button in the floating filter
        // },
        // valueGetter: (params) => {
        //   // For group rows, show the air_order_no of the first child
        //   if (params.node.group) {
        //     const firstData = params.node.allLeafChildren[0]?.data; // Get the first child data in the group
        //     return firstData ? firstData.air_order_no : ''; // Return air_order_no from the first child or empty string
        //   }
        //   // For child rows, return air_order_no for the floating filter (do not display)
        //   return params.data?.air_order_no || ''; // Still return it for floating filter (non-empty string)
        // },
      },

      {
        headerName: 'Dock',
        field: 'dock',
        width: 80,
        // floatingFilter: true,
        tooltipField: 'dock',
        headerTooltip: 'Dock',
        sortable: true,
        // ...this.createColumnRendererForField('dock'),
      },
      {
        headerName: 'Air Case Code',
        field: 'air_case_code',
        width: 100,
        // floatingFilter: true,
        tooltipField: 'air_case_code',
        headerTooltip: 'Air case Code',
        // ...this.createColumnRendererForField('air_case_code'),
      },

      {
        headerName: 'Part No',
        field: 'part_no',
        width: 130,
        // floatingFilter: true,
        tooltipField: 'part_no',
        headerTooltip: 'Part Number',
        // ...this.createColumnRendererForField('part_no'),
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        width: 90,
        // floatingFilter: true,
        headerTooltip: 'Kanban',
        tooltipField: 'Kanban',
        // ...this.createColumnRendererForField('kanban'),
      },
      {
        headerName: 'Arrival DT',
        field: 'arrival_date',
        headerTooltip: 'Arrival Date',
        width: 120,
        // floatingFilter: true,
        supressMenu: true,
        tooltipField: 'Arrival Date',
        // filter: 'agDateColumnFilter',
        // filterParams: {
        //   comparator: dateFilterComparator,
        // },
        // ...this.createColumnRendererForField('arrival_date'),
      },
      {
        headerName: 'No Of Pieces',
        field: 'number_of_pieces',
        headerTooltip: 'Number Of Pieces',
        sortable: true,
        width: 80,
        // floatingFilter: true,
        tooltipField: 'pieces_to_order',
        // filter: 'agNumberColumnFilter',
        // floatingFilterComponentParams: { suppressFilterButton: false },
        // filterParams: {
        //   numberParser: (text) => {
        //     return text === null ? null : parseFloat(text.replace(',', '.'));
        //   },
        // },
        // ...this.createColumnRendererForField('number_of_pieces'),
      },
      {
        headerName: 'No Of Lots',
        headerTooltip: 'Number Of Lots',
        field: 'number_of_lots',
        sortable: true,
        width: 80,
        // floatingFilter: true,

        // filter: 'agNumberColumnFilter',
        // floatingFilterComponentParams: { suppressFilterButton: false },
        // filterParams: {
        //   numberParser: (text) => {
        //     return text === null ? null : parseFloat(text.replace(',', '.'));
        //   },
        // },
        // ...this.createColumnRendererForField('number_of_lots'),
      },
      {
        headerName: 'Dest Code',
        field: 'dest_code',
        sortable: true,
        width: 80,
        // floatingFilter: true,
        headerTooltip: 'Dest Code',
        // ...this.createColumnRendererForField('dest_code'),
      },
      {
        headerName: 'Status',
        field: 'air_order_status',
        sortable: true,
        width: 130,
        // floatingFilter: true,
        tooltipField: 'air_order_status',
        headerTooltip: 'Status',
        // ...this.createColumnRendererForField('air_order_status'),
      },

      {
        headerName: 'Van Center Confirmed',
        field: 'kvc_conf',
        width: 110,
        // floatingFilter: true,
        headerTooltip: 'Van Center Confirmed',
        // ...this.createColumnRendererForField('kvc_conf'),
      },

      {
        headerName: 'Van Center ETA DT',
        field: 'eta_date',
        width: 120,
        headerTooltip: 'Van Center ETA Date(mm/dd/yyyy)',
        // filter: 'agDateColumnFilter',
        // filterParams: {
        //   comparator: dateFilterComparator,
        // },
        // floatingFilter: true,
        // ...this.createColumnRendererForField('eta_date'),
      },
      {
        headerName: 'Renban',
        field: 'case_no',
        width: 90,
        sorting: true,
        // floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        headerTooltip: 'Renban',
        // filter: 'agTextColumnFilter',
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        // },
        // ...this.createColumnRendererForField('case_no'),
      },
      {
        headerName: 'Order DT',
        field: 'date_raised',
        width: 130,
        // floatingFilter: true,
        headerTooltip: 'Order Date(mm/dd/yyyy)',
        headerClass: 'ag-theme-custom-text-right',
        // filter: 'agDateColumnFilter',
        // filterParams: {
        //   comparator: dateFilterComparator,
        // },
        // ...this.createColumnRendererForField('date_raised'),
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 150,
        // floatingFilter: true,
        // headerClass: 'ag-theme-custom-text-right',
        // ...this.createColumnRendererForField('userid'),
      },
      {
        headerName: 'Updated DT',
        field: 'date_time',
        width: 150,
        // floatingFilter: true,
        // headerClass: 'ag-theme-custom-text-right',
        // headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        // ...this.createColumnRendererForField('date_time'),
      },
    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.autoGroupColumnDef = {
      headerName: 'Air Order No',
      field: 'air_order_no',
      width: 170,
      floatingFilter: true, // Enable floating filter
      tooltipField: 'air_order_no',
      headerTooltip: 'Air Order No',
      rowGroup: true, // Grouping by air_order_no
      hide: true, // Ensure it's not hidden for filtering
      checkboxSelection: (params) => params.node.group,
      headerCheckboxSelection: true,
      // cellRendererFramework: AirOrderCellRendererComponent, // Use the custom cell renderer for all rows
      filter: 'agTextColumnFilter', // Text filter for air_order_no
      floatingFilterComponentParams: {
        suppressFilterButton: true, // Optionally suppress filter button in the floating filter
      },
      valueGetter: (params) => {
        // For group rows, show the air_order_no of the first child
        if (params.node.group) {
          const firstData = params.node.allLeafChildren[0]?.data; // Get the first child data in the group
          return firstData ? firstData.air_order_no : ''; // Return air_order_no from the first child or empty string
        }
        // For child rows, return air_order_no for the floating filter (do not display)
        return params.data?.air_order_no || ''; // Still return it for floating filter (non-empty string)
      },
    };

    this.rowSelection = 'multiple';
    this.groupSelectsChildren = true;
    this.suppressRowClickSelection = true;
    this.suppressAggFuncInHeader = true;
    this.editType = 'fullRow';
    this.showOpenedGroup = true;
    this.groupRowsSticky = true;

    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = (params) => {
      this.gridApi = params.api;
    };
  }

  initializeData() {
    console.log('initialized data ');
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    this.itContact = localStorage.getItem('itContact');

    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }

    this.loadDrop();
    this.onResetDropDown();

    this.originalRowData = [...this.rowData]; // Keep a copy of the original data

    this.setPageSizeToAll();
  }
  checkIfAnySelected() {
    if (this.selectedDestCode.length != 0) {
      return true;
    }

    return false;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    this.removeMessage();
    this.spinner.show();

    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }
    // if (!this.isNavigatedFromSecond) {
    //   console.log('navigation if');
    //   // Clear message if not navigated from second
    //   this.removeMessage();
    // }

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid?.gridOptions?.api.setRowData(this.rowData);
      this.offset = 0;
      this.spinner.show();
    }
    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectdest;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    this.callSearch(this.getSearchData(offset));
  }
  getSearchData(offset) {
    let kanban;
    let selectedPartNo;
    let selectedDestCode;
    let selectedContainer;
    let selectedStatus;
    let selectedDock;
    let selectedSpecialist;
    let selecteAdjlist;
    let selectedAdjlist;
    let selectedAircaseCode;
    console.log(this.kanbanList, this.selectedKanban, 'temp_kanban list');
    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }
    console.log(this.airCaseCode, this.selectedAircaseCode, 'temp_aircase code list');
    if (this.selectedAircaseCode.length > 0) {
      if (!this.selectedAircaseCode.some((a) => a.air_case_code === 'ALL')) {
        selectedAircaseCode = [];
        this.selectedAircaseCode.forEach((element) => {
          selectedAircaseCode.push(element.air_case_code);
        });
      }
    }
    console.log(this.partNoList, this.selectedPartNo, 'temp_part list');
    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.part_no === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.part_no);
        });
      }
    }
    console.log(this.destCodeList, this.selectedDestCode, 'temp_destCodeList list');
    if (this.selectedDestCode.length > 0) {
      if (!this.selectedDestCode.some((a) => a.dest_code === 'ALL')) {
        selectedDestCode = [];
        this.selectedDestCode.forEach((element) => {
          selectedDestCode.push(element.dest_code);
        });
      }
    }
    if (this.selectedContainer.length > 0) {
      if (!this.selectedContainer.some((a) => a.cont_code === 'ALL')) {
        selectedContainer = [];
        this.selectedContainer.forEach((element) => {
          selectedContainer.push(element.cont_code);
        });
      }
    }

    if (this.selectedStatus.length > 0) {
      if (!this.selectedStatus.some((a) => a.air_order_status === 'ALL')) {
        selectedStatus = [];
        this.selectedStatus.forEach((element) => {
          selectedStatus.push(element.air_order_status);
        });
      }
    }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    if (this.selectedSpecialist.length > 0) {
      if (!this.selectedSpecialist.some((a) => a.EMPLOYEE === 'ALL')) {
        selectedSpecialist = [];
        this.selectedSpecialist.forEach((element) => {
          selectedSpecialist.push(element.EMPLOYEE);
        });
      }
    }

    if (this.selectedAdjlist.length > 0) {
      if (!this.selectedAdjlist.some((a) => a.adj_type === 'ALL')) {
        selectedAdjlist = [];
        this.selectedAdjlist.forEach((element) => {
          selectedAdjlist.push(element.adj_type);
        });
      }
    }

    return {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      part_no: selectedPartNo,
      dest_code: selectedDestCode,
      dock: selectedDock,
      date_raised_from: this.ordDate,
      date_raised_to: this.ordDateTo,
      air_case_code: selectedAircaseCode,
      status: selectedStatus,
    };
  }
  callSearch(data) {
    this.isEditMode = true;

    this.spinner.show();
    console.log(data, 'temp_ data console after call');
    this.airFreightService?.getAirOrderData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body.data) {
          this.isEditMode = true;
          this.rowData.push(...res.body.data.data);
          this.osPartsGrid?.gridOptions?.api.setRowData(this.rowData);

          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    // return true;
  }

  loadDrop() {
    const lookupSubscriptions = [
      this.airFreightService.getPartNumber(),
      this.airFreightService.getKanban(),
      this.airFreightService.getDock(),
      this.airFreightService.getDestCode(),
      this.airFreightService.getStatus(),
      this.lookupservice.getUserShowHideColumns(),
      this.airFreightService.getAirfreightAirCaseCodes(),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.destCodeList = [];
        this.containerList = [];
        this.statusList = [];
        this.dockList = [];
        this.adjList = [];
        this.specialistList = [];
        this.airCaseCode = [];
        if (res[1].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[1].body.data);

        if (res[0].body.data) {
          this.partNoList.push({ part_no: 'ALL' });
        }
        this.partNoList.push(...res[0].body.data);

        if (res[2].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        this.dockList.push(...res[2].body.data);

        if (res[3].body.data) {
          this.destCodeList.push({ dest_code: 'ALL' });
        }
        this.destCodeList.push(...res[3].body.data);
        this.selectedDestCode = [{ dest_code: 'ALL' }];

        if (res[4].body.data) {
          this.statusList.push({ air_order_status: 'ALL' });
          this.selectedStatus = res[4].body.data.filter(
            (order) => order.air_order_status === 'CONFIRMED' || order.air_order_status === 'CREATED'
          );
        }
        this.statusList.push(...res[4].body.data);

        const { air_order_columns } = res[5].body.showHideColumnsResponse;

        if (res[6].body.data) {
          this.airCaseCode.push({ air_case_code: 'ALL' });
        }
        this.airCaseCode.push(...res[6].body.data);
        this.airCaseCode = this.airCaseCode.filter(
          (value, index, self) => index === self.findIndex((t) => t.air_case_code === value.air_case_code)
        );

        try {
          const hideItems = JSON.parse(air_order_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });

    //   },
    // });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    // this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    this.isEditMode = false;
    if (!this.isNavigatedFromSecond) {
      console.log('navigation if');
      // Clear message if not navigated from second
      this.removeMessage();
    }
    this.selectedDestCode = [{ dest_code: 'ALL' }];
    this.selectedKanban = [];
    this.selectedPartNo = [];
    // this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedStatus = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedAdjlist = [];
    this.selectedLifecycle = [];
    this.ordDate = this.onInitordDate;
    this.ordDateTo = this.onInitordDate;

    this.kanbanSearchValue = null;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  air_order_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.air_order_columns = hideItems;
    if (!hideItems.length) this.air_order_columns = [{}];
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].part_no === 'ALL') {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex((data) => data.part_no === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  
  adjChange(): void {
    if (this.selectedAdjlist.length >= 1) {
      if (this.selectedAdjlist[this.selectedAdjlist.length - 1].adj_type === 'ALL') {
        this.selectedAdjlist = [];
        this.selectedAdjlist.push(this.adjList[0]);
      } else {
        let indexAll = this.selectedAdjlist.findIndex((data) => data.adj_type === 'ALL');

        if (indexAll > -1) {
          this.selectedAdjlist.splice(indexAll, 1);
        }
      }
    }
  }

  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  getSelectedRowData(): any {
    // Get the selected rows using the grid API
    const selectedNodes = this.gridOptions.api.getSelectedNodes();

    // Map the selected nodes to their data
    const selectedRowData = selectedNodes.map((node) => node.data);

    console.log(selectedRowData, 'selected row data...');

    return selectedRowData;
  }


 
}
