<div class="background">
  <div #formTop></div>

  <div class="p-2 messageBackground reset_btn">
    <label class="resetIcon" (click)="goBack()">
      <img src="./assets/images/back arrow.png" alt="bell icon" class="bell" />
    </label>

    <label class="message calendarFont" (click)="goBack()"> BACK</label>
  </div>

  <div class="d-flex flex-row">
    <div class="p-2 flex-grow-1">
      <h2 class="heading">Enter New Air Freight</h2>
    </div>
    <div class="p-2">
      <button class="cancel_btn configButton" (click)="ConfigureParameter()">
        CONFIGURE PARAMETER
      </button>
    </div>
    <!-- <div class="p-2">
      <button class="cancel_btn1" >Cancel</button>
    </div>
    <div class="p-2">
      <button class="review_btn SaveButton" (click)="saveOrder()">Save</button>
    </div> -->
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>

    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <form [formGroup]="addOrEditform">
    <div class="card mb-3">
      <div class="card-body">
        <!-- 1st row -->
        <div class="row justify-content-start">
          <div class="col-3">
            <span class="v1"></span>
            <label class="searchLable">ORDER TYPE</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              [options]="toOrder"
              optionLabel="order_type"
              placeholder="Select"
              formControlName="orderType"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              (onChange)="onLotsPiecesChange($event)"
              [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="order_type"
            ></p-dropdown>
            <ng-container
              *ngIf="addOrEditform.controls.orderType.errors && submitted"
            >
              <small *ngIf="addOrEditform.controls.orderType.errors?.required">
                Order type is required.
              </small>
            </ng-container>
          </div>

          <div class="col-3">
            <span class="v1"></span>
            <label class="searchLable">DEST CODE</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              [options]="addAirOrderDestcode"
              optionLabel="dest_code"
              placeholder="Select"
              formControlName="destCode"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="dest_code"
            ></p-dropdown>
            <ng-container
              *ngIf="addOrEditform.controls.destCode.errors && submitted"
            >
              <small *ngIf="addOrEditform.controls.destCode.errors?.required">
                Dest code is required.
              </small>
            </ng-container>
          </div>
        </div>
        <!-- 2nd row -->
        <div class="row">
          <div class="col-3">
            <label required class="searchLable">RESPONSIBILITY</label>
            <div class="radio-group">
              <label class="radio">
                <input
                  formControlName="responsibilityregion"
                  value="Plant"
                  type="radio"
                />
                <span class="radio-label"> Plant</span>
              </label>
              <label class="radio">
                <input
                  formControlName="responsibilityregion"
                  value="TMC"
                  type="radio"
                />
                <span class="radio-label">TMC</span>
              </label>
            </div>
          </div>

          <div class="col-3">
            <label class="searchLable">RAISED BY</label>
            <input
              formControlName="raisedBy"
              class="key"
              type="text"
              placeholder="--Type--"
              readonly
            />
          </div>

         

          <div class="col-3">
            <span class="v1"></span>
            <label class="searchLable">ORDER DT</label>
            <input class="key" type="date" [min]="minDate" formControlName="orderDate" />
            <ng-container
              *ngIf="addOrEditform.controls.orderDate.errors && submitted"
            >
              <small *ngIf="addOrEditform.controls.orderDate.errors?.required">
                Order DT is required.
              </small>
            </ng-container>
          </div>
        </div>

        <!-- 3 row -->
        <div class="row">
          <div class="col-3">
            <label required class="searchLable">VOLUME</label>
            <div class="radio-group">
              <label class="radio">
                <input
                  formControlName="volumesize"
                  value="Additional Parts"
                  type="radio"
                />
                <div style="width: 124px" class="radio-label">
                  Additional Parts
                </div>
              </label>
              <label class="radio">
                <input
                  formControlName="volumesize"
                  value="Pipeline"
                  type="radio"
                />
                <span class="radio-label">Pipeline</span>
              </label>
            </div>
          </div>

          <div class="col-md-3">
            <label class="searchLable">MANAGER</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="managerDetails"
              optionLabel="contact_name"
              formControlName="managerName"
              (onChange)="getMangerIdByNameAndRole()"
              [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="contact_name"
            ></p-dropdown>
          </div>

          <!-- <div class="row"> -->
          <div class="col-md-3">
            <label class="searchLable">COMMENT</label>
            <input
              formControlName="firstComment"
              type="text"
              maxlength="250"
              placeholder="--Type--"
              class="key"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label class="searchLable">BUDGET CODE</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="addBudgetCodes"
              optionLabel="budget_code"
              formControlName="budgetCodes"
              [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="budget_code"
              
            ></p-dropdown>
          </div>

          <div class="col-md-3">
            <label class="searchLable">VAN CENTER CONTACT</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="kvcDetails"
              formControlName="KvcName"
              optionLabel="contact_name"
              (onChange)="getKvcIDdByNameAndRole()"
              [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="contact_name"
            ></p-dropdown>
          </div>
          <div class="col-md-3">
            <label class="searchLable">REASON EXPLANATION</label>
            <input
              formControlName="secondComment"
              type="text"
              maxlength="250"
              placeholder="--Type--"
              class="key"
            />
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <mat-accordion>
          <mat-expansion-panel
          disabled="true"
            *ngFor="
              let part of filteredData && filteredData.length
                ? filteredData
                : parts;
              let i = index
            "
            [expanded]="isExpanded[i]"
          >
            <mat-expansion-panel-header
              
            >
              <mat-icon>
                {{ isExpanded[i] ? "expand_more" : "chevron_right" }}
              </mat-icon>
              PART {{ i + 1 }}
              <!-- <img
                class="detailiconhome"
                src="assets/images/icon-delete.svg"
                class="expansion-header-image"
                (click)="deletePart(i)"
                title="delete"
                alt="Delete"
              /> -->
              &nbsp; &nbsp;
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-3">
                <label required class="searchLable">PART TYPE</label>

                <div class="radio-group">
                  <label class="radio">
                    <input
                      formControlName="partType"
                      value="Production"
                      type="radio"
                    />
                    <span class="radio-label">Production</span>
                  </label>
                  <label class="radio">
                    <input
                      formControlName="partType"
                      value="Trial"
                      type="radio"
                    />
                    <span class="radio-label">Trial</span>
                  </label>
                </div>
              </div>

              <div class="col-3">
                <span class="v1"></span>
                <label class="searchLable">DOCK </label>
                <p-dropdown
                  #dockNoDropdown
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="30"
                  [options]="addAirOrderDock"
                  optionLabel="dock"
                  placeholder="Select"
                  formControlName="dockNo"
                  [style]="{ width: '100%' }"
                  scrollHeight="100px"
                  (onChange)="onDockChange('data')"
                  [appendTo]="'body'" 
                  [autoWidth]="false"
                  filterBy="dock"
                ></p-dropdown>
                <ng-container
                  *ngIf="addOrEditform.controls.dockNo.errors && submitted"
                >
                  <small *ngIf="addOrEditform.controls.dockNo.errors?.required">
                    Dock is required.
                  </small>
                </ng-container>
              </div>

              <div class="col-3">
                <span class="v1"></span>
                <label class="searchLable">KANBAN</label>
                <p-dropdown
                  #kanbanNoInput
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="30"
                  [options]="addAirOrderKanban"
                  optionLabel="kanban"
                  placeholder="Select"
                  formControlName="kanbanNo"
                  [style]="{ width: '100%' }"
                  scrollHeight="100px"
                  (onChange)="onKanbanChange()"
                  [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="kanban"
                ></p-dropdown>
                <ng-container
                  *ngIf="addOrEditform.controls.kanbanNo.errors && submitted"
                >
                  <small
                    *ngIf="addOrEditform.controls.kanbanNo.errors?.required"
                  >
                    Kanban is required.
                  </small>
                </ng-container>
              </div>

              <div class="col-3">
                <span class="v1"></span>
                <label class="searchLable">PART NUMBER</label>
                <p-dropdown
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="30"
                  [options]="addAirOrderPart"
                  optionLabel="item_id"
                  placeholder="Select"
                  formControlName="partNo"
                  [style]="{ width: '100%' }"
                  scrollHeight="100px"
                  (onChange)="onPartNoChange()"
                  [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="item_id"
                ></p-dropdown>

                <ng-container
                  *ngIf="addOrEditform.controls.partNo.errors && submitted"
                >
                  <small *ngIf="addOrEditform.controls.partNo.errors?.required">
                    Part number is required.
                  </small>
                </ng-container>
              </div>

              <div class="col-3">
                <label class="searchLable">PART DESCRIPTION</label>
                <input
                  class="key"
                  type="text"
                  placeholder="--Type--"
                  formControlName="part_description"
                  (keydown)="preventKeyPress($event)"
                />
              </div>

              <div class="col-3">
                <span class="v1"></span>
                <label class="searchLable">AIR CASE CODE </label>
                <p-dropdown
                  #aircaseInput
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="30"
                  [options]="addAirCaseCode"
                  placeholder="Select"
                  formControlName="airCode"
                  [style]="{ width: '100%' }"
                  scrollHeight="100px"
                  optionLabel="air_case_code"
                  [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="air_case_code"
                ></p-dropdown>
                <!-- <ng-container
                  *ngIf="addOrEditform.controls.airCode.errors && submitted"
                >
                  <small *ngIf="addOrEditform.controls.airCode.errors?.required">
                    Air case code  is required.
                  </small>
                </ng-container> -->
              </div>

              <div class="col-3">
                <span class="v1"></span>
                <label class="searchLable">REASON</label>
    
                <p-dropdown
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="30"
                  placeholder="Select"
                  [options]="addAirReason"
                  optionLabel="reason_code"
                  formControlName="reason"
                  [style]="{ width: '100%' }"
                  scrollHeight="100px"
                  [appendTo]="'body'" 
              [autoWidth]="false"
              filterBy="reason_code"
                ></p-dropdown>
                <ng-container
                  *ngIf="addOrEditform.controls.reason.errors && submitted"
                >
                  <small *ngIf="addOrEditform.controls.reason.errors?.required">
                    Reason is required.
                  </small>
                </ng-container>
              </div>

              <div class="col-3" *ngIf="!isLotsSelected">
                <span class="v1"></span>
                <label class="searchLable">NO OF PIECES TO ORDER</label>
                <input
                  #piecesToOrderInput
                  class="key"
                  type="text"
                  placeholder="--Type--"
                  maxlength="5"
                  formControlName="piecesToOrder"
                  (input)="handleKeydown('input1', $event)"
                  (keydown)="onKeyPress($event)"
                />
              </div>
              <div class="col-3" *ngIf="isLotsSelected">
                <span class="v1"></span>
                <label class="searchLable">NO OF LOTS TO ORDER</label>
                <input
                  class="key"
                  type="text"
                  placeholder="--Type--"
                  formControlName="lotsToOrder"
                  maxlength="5"
                  (input)="handleKeydown('input1', $event)"
                  (keydown)="onKeyPress($event)"
                />
              </div>
              <div class="col-3">
                <span class="v1"></span>
                <label class="searchLable">QPC</label>
                <input
                  class="key"
                  type="text"
                  placeholder="--Type--"
                  formControlName="qpcBox"
                  (input)="handleKeydown('input2', $event)"
                  readonly
                />
              </div>

              <div class="col-3">
                <label class="searchLable">TOTAL ORDER</label>
                <input
                  class="key"
                  type="text"
                  placeholder="--Type--"
                  formControlName="totalOrder"
                  maxlength="10"
                  readonly
                />
              </div>

              <div class="col-3">
                <!-- <span class="v1"></span> -->
                <label class="searchLable">CUBIC DIMENSION</label>
                <input class="key" type="text" placeholder="--Type--" 
                maxlength="5"
                readonly
                />
              </div>

              <div class="col-3">
                <label class="searchLable">BOX WEIGHT(KG)</label>
                <input
                  class="key"
                  type="text"
                  placeholder="--Type--"
                  formControlName="boxWeight"
                  maxlength="10"
                  (input)="handleKeydown('inpu2', $event)"
                  (keydown)="onKeyPressDecimalAllow($event)"
                />
              </div>

              <div class="col-3">
                <label required class="searchLable">COST CALCULATION</label>

                <div class="radio-group">
                  <label class="radio">
                    <input
                      formControlName="costCalculation"
                      value="Weight"
                      type="radio"
                    />
                    <span class="radio-label">Weight</span>
                  </label>
                  <label class="radio">
                    <input
                      formControlName="costCalculation"
                      value="Volume"
                      type="radio"
                    />
                    <span class="radio-label">Volume</span>
                  </label>
                </div>
              </div>

              <div class="col-3">
                <label class="searchLable">ESTD COST (USD)</label>
                <input
                  class="key"
                  type="text"
                  maxlength="10"
                  placeholder="--Type--"
                  formControlName="estimatedCost"
                  readonly
                />
              </div>

              <div class="col-3">
                <label required class="searchLable"
                  >VAN CENTER CONFIRMATION</label
                >

                <div class="radio-group">
                  <label class="radio">
                    <input
                      formControlName="vanConfirmation"
                      value="Yes"
                      type="radio"
                    />
                    <span class="radio-label">Yes</span>
                  </label>
                  <label class="radio">
                    <input
                      formControlName="vanConfirmation"
                      value="No"
                      type="radio"
                    />
                    <span class="radio-label">No</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-10">
                <p style="font-weight: bold; margin-top: 17px">
                  Required Arrival Schdules
                </p>
              </div>
              <div class="col-2 detailiconhome">
                <!-- <label class="resetlabel1"> -->
                <!-- <p (click)="addRow()">Add Arrival Date</p> -->
                <button class="cancel_btn arrival_btn" (click)="addRow()">
                  ADD ARRIVAL DATE
                </button>
                <!-- </label> -->
              </div>
            </div>

            <div class="row">
              <!-- <app-grid
                class="table-outer"
                #osPartsGrid
                [columnDefs]="columnDefs"
                [rowData]="rowData"
                [gridOptions]="gridOptions"
                [frameworkComponents]="frameworkComponents"
                [floatingFilter]="false"
                [pagination]="false"
                [context]="context"
              ></app-grid> -->
              <ag-grid-angular
                class="ag-theme-balham"
                #ospMorningGrid
                [columnDefs]="columnDefs"
                style="width: 100%; height: 20vh"
                [gridOptions]="gridOptions"
                [rowData]="rowData"
                [tooltipMouseTrack]="true"
                [context]="context"
              >
              </ag-grid-angular>
            </div>

            <div class="mt-3" *ngIf="isExpanded[i]">
              <button class="review_btn" (click)="saveOrder()">Save</button>
              <button class="cancel_btn" (click)="onCancel()">Cancel</button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </form>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>