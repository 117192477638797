import { Component, ElementRef, ViewChild } from '@angular/core';
import * as mm from 'moment-timezone';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <input
      #input
      [(ngModel)]="value"
      type="date"
      [min]="minDate"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class ActualArrivalEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  minDate: String;
  easternCurrentDate: string;

  constructor(private readonly airFreightService: AirfreightApicallsService,) { }

  // agInit(params: any): void {
  //   console.log(params, 'params...');

  //   params.data.arrivalDate = this;
  //   console.log(params.data.arrivalDate, 'hi');
  //   if (params.column === 'arriving_date') {
  //     console.log('column....');
  //     this.value = params.data.arriving_date;
  //     this.value = moment(this.value).format('YYYY-MM-DD');
  //     console.log(this.value, 'value......');
  //   }

  //   this.params = params;
  //   console.log(this.params, 'params......');
  // }
  calendarList = [];
  filteredData(date_: any) {

    // console.log(date_,this.calendarList);
    var return_data =  this.calendarList.filter(
      (item) => new Date(item.date.split('T')[0]).getTime() == new Date(date_).getTime()
    );
    // console.log(date_,return_data);
    return return_data;
  }
  async agInit(params: any): Promise<void> {
    // this.callApiForAdd();
    // console.log(this.airFreightService.getCalendar(),"===================================================================");


    // Set the minimum date to today in the format YYYY-MM-DD
    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
    this.airFreightService.getCalendar().subscribe(val => {
      this.calendarList = val.body.data;
      // console.log(this.calendarList);
      var loop = true;
      const today = new Date();
      var day_skip = 0;
      for (var i = 1; i < 50; i++) {
        if (loop) {
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + i);
          const year = tomorrow.getFullYear();
          const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const day = String(tomorrow.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`;
          // console.log(formattedDate);
          // console.log(this.filteredData(formattedDate)[0]);

          if (this.filteredData(formattedDate)[0]?.work_status == '1') {
            // console.log(formattedDate);
            if (day_skip == 1) {
              // this.minDate = new Date().toISOString().split('T')[0];
              this.minDate = formattedDate;
              loop = false;
            }
            day_skip++;
          }
        }
      }
      // console.log(this.minDate);
    });
    // (filteredData[0]?.work_status == '0')
    // console.log(this.minDate);
    params.data.arrivalDate = this;
    console.log(params.data.arrivalDate, '');
    if (params.column === 'arriving_date') {
      this.value = params.data.arriving_date;
      console.log(this.value, 'value......');
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log(this.params.data, 'ui');
    if (this.params.column === 'arriving_date') {
      if (this.value !== this.params.data.arriving_date) {
        this.params.data.arriving_date = this.value;
        this.params.data.isUpdated = true;
      }
    }
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
