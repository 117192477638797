<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
  <div class="col-sm-7 movable" cdkDrag cdkDragHandle>
    <h3 class="head">
      Breaks
    </h3>
  </div>
  <div class="col-sm-4 movable">
    <button class="review_btn breakShift_btn" (click)="openBreaks()">
      ADD BREAKS
    </button>
  </div>
  <div class="col-sm-1">
    <button type="button" class="btn btn-dark btn-circle btn-xl" mat-dialog-close="cancel" data-dismiss="modal"
      aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>
<div class="successMessage alert-dismissible">
  <div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
    <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
      aria-label="Close"></button>
  </div>
  <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
    {{ warningMessage }}
    <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
      aria-label="Close"></button>
  </div>
</div>

<div class="col-md-12">
  <ag-grid-angular #osPartsGrid style="
      width: 95%;
      margin-top: 35px;
      height: 180px !important;
      top: 3%;
      margin-bottom: 2%;
    " class="ag-theme-balham table-outer" [rowData]="rowData" [columnDefs]="columnDefs" [rowSelection]="rowSelection"
    [gridOptions]="gridOptions" [context]="context" [editType]="editType" [suppressClickEdit]="suppressClickEdit"
     [suppressClickEdit]="true" >
  </ag-grid-angular>

</div>

