import { Component, Inject } from '@angular/core';

import { FormBuilder, FormGroup, Validators, isFormArray } from '@angular/forms';
import { NumberSymbol } from '@angular/common'; import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import { successMessage, warningMessage } from 'src/app/constants';
import { AirorderDeleteComponent } from '../../delete-airorder/airorder-delete.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { MatTabChangeEvent } from '@angular/material/tabs';



@Component({
  selector: 'app-config-parameter',
  templateUrl: './config-parameter.component.html',
  styleUrls: ['./config-parameter.component.scss'],
})
export class ConfigParameterComponent {
  [x: string]: any;
  selectedTabIndex: number = 0;
  selectedRole: any;
  roles: string[] = ['MGR', 'KVC', 'COO'];
  userForm: FormGroup;
  formSubmitted = false;
  cost_per_kilo: Number;
  cost_per_cubic: number;
  user: string;
  userRole: string;
  itContact: string;
  warningMessage: string;
  successMessage: string;
  airCostData: any = [];
  budgetCodes: any = [];
  contactData: any = [];
  airCode: any = [];
  airCodeData: any = []
  namcValue: string;
  // dialogRef: any;
  budget_code: string;
  budget_desc: string;
  contact_role: string;
  RoleDetails: any = [];
  selectedDockData: any = [];
  fromHoursHH: string;
  fromMintuesMM: string;
  hoursArray = new Array(24);
  mintuesArray = new Array(60);



  constructor(private fb: FormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly airFreightService: AirfreightApicallsService,
    private readonly lookupservice: LookupService,
    public dialog: MatDialog,


  ) {

    // Initialize the form group with two fields and their validators
    this.userForm = this.fb.group({
      cost_per_kilo: ['', [Validators.required]],
      cost_per_cubic: ['', [Validators.required]],
      budget_code1: ['', [Validators.required]],
      budget_code: ['', [Validators.required]],
      budget_desc: ['', [Validators.required]],
      contact_name: ['', [Validators.required]],
      contact_role: [null, Validators.required],
      airCode: ['', [Validators.required]],
      dockNo: [null, Validators.required],
      air_case_code1: ['', Validators.required],
      fromHoursHH: ['', Validators.required],
      fromMintuesMM: ['', Validators.required],
    });
  }
  onInputChange(event: any): void {
    // Remove any non-numeric characters (except dot for floating point numbers)
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9.]/g, ''); // Removes any non-numeric characters except '.'

    // Prevent entering multiple dots in a number
    const dotCount = numericValue.split('.').length - 1;
    if (dotCount > 1) {
      event.target.value = numericValue.substring(0, numericValue.lastIndexOf('.'));
    } else {
      event.target.value = numericValue;
    }
  }
  addbudgetCode() {
    this.removeMessage();
    // Check if budget code or description are missing
    if (!this.userForm.value.budget_code) {
      this.warningMessage = 'Please enter budget code ';
      return; // Early exit if validation fails
    }

    const data3 = this.userForm.value.budget_code;
    let isupdate = this.budgetCodes.includes(data3);  // Check if budget_code already exists

    var updatedData = [];
    var updatedFields = {
      business_entity: this.namcValue,
      budget_code: this.userForm.value.budget_code,
      budget_desc: this.userForm.value.budget_desc,
      userid: localStorage.getItem('UserName'),
    };
    updatedData.push(updatedFields);
    localStorage.setItem('isBudgetCodeSetToDefault', 'true')

    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      budget_code: this.userForm.value.budget_code,
      budget_desc: (this.userForm.value.budget_desc === undefined || this.userForm.value.budget_desc === null)
        ? ""
        : this.userForm.value.budget_desc,
      userid: localStorage.getItem('UserName'),
      isupdate: isupdate,
      is_default: false
    };


    // Make the API call
    this.airFreightService.addBudgetcode(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        console.log("data", data);
        if (data.body.addResponse?.insertedCount >= 1) {
          this.successMessage = successMessage.recordUpdated;

          if (!isupdate) {
            // Only push new codes to the list, if it's not an update
            this.budgetCodes.push(data.body.addResponse?.insertedRows[0]);
          } else {
            // If it's an update, replace the existing code with updated fields
            const updatedBudgetCode = data.body.addResponse?.insertedRows[0];
            const index = this.budgetCodes.findIndex((code) => code.budget_code === updatedBudgetCode.budget_code);

            if (index !== -1) {
              // Replace the old entry with the updated one
              this.budgetCodes[index] = updatedBudgetCode;
            }
          }

          // Make sure budgetCodes is unique after adding or updating
          this.budgetCodes = [
            ...new Map(this.budgetCodes.map(item => [item.budget_code, item])).values()
          ];
          // Reset form fields
          this.userForm.get('budget_code').reset();
          this.userForm.get('budget_desc').reset();

          this.spinner.hide();
          window.scroll(0, 0);
        } else {
          this.warningMessage = `${warningMessage.apiLogicFail} ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });
  }

  deleteBudgetCode(budetCodeToDelete) {
    console.log("inside delete", budetCodeToDelete.budget_code);
    let itemToUpdate = this.existbudgetCode.find(item => item.budget_code === budetCodeToDelete.budget_code);
    if (itemToUpdate) {
      itemToUpdate.isDelete = true;
    }
    console.log("itemToUpdate", itemToUpdate)
    if (itemToUpdate) {
      this.warningMessage = 'You can not delete a budget code that is still used. ';
      return; // Early exit if validation fails

    }
    let exportData = []
    exportData.push({ ...budetCodeToDelete, isDeleteBudgetCode: true });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component9';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        console.log("inside save");
        console.log("data to delete", exportData)
        const data = {
          offset: 0,
          limit: 1200,
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          budget_code: exportData[0].budget_code,
          data: exportData

        };
        localStorage.setItem('isBudgetCodeSetToDefault', 'true')
        localStorage.setItem('isAirCsaeCodeSetToDefault', 'true')


        this.airFreightService.getDeleteBudgetCode(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.data.DeletedCount >= 1) {
              this.successMessage = 'Budget code deleted successfully';
              const index = this.budgetCodes.findIndex((item) =>
                item.budget_code.trim().toLowerCase() === exportData[0]?.budget_code.trim().toLowerCase()
              );

              if (index !== -1) {
                // Remove the contact from the array if it exists
                this.budgetCodes.splice(index, 1);
              }

              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.spinner.hide();
            }
          },
        });


      }
    });


  }

  ngOnInit(): void {
    console.log("userform", this.userForm)
    this.removeMessage();
    localStorage.setItem('isBudgetCodeSetToDefault', 'false')
    this.namcValue = localStorage.getItem('namcvalue');
    this.user = localStorage.getItem('workdayId');

    this.userRole = localStorage.getItem('UserRoles');
    this.selectedRole = null;


    this.callApi();


    // console.log("inside buget code page ")



  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  errorCallback = (error) => {
    console.log(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    // this.rowData = [];
  };
  deleteContact(contact) {
    console.log("inside dlete")
    console.log(contact);
    const exportData = [];
    // exportData.push(data);
    exportData.push({ ...contact, isContact: true });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component9';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        console.log("inside save");
        console.log("data to delete", exportData)
        const data = {
          offset: 0,
          limit: 1200,
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          data: exportData,
        };
        localStorage.setItem('isContactadded', 'true')

        this.airFreightService.getDeleteContact(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.data.contactDeletedCount >= 1) {
              this.successMessage = 'Contact deleted successfully';
              const index = this.contactData.findIndex((item) =>
                item.contact_name.trim().toLowerCase() === exportData[0]?.contact_name.trim().toLowerCase()
              );

              if (index !== -1) {
                // Remove the contact from the array if it exists
                this.contactData.splice(index, 1);
              }

              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.spinner.hide();
            }
          },
        });


      }
    });


  }





  // Getters for form controls to use in the template
  get username() {
    return this.userForm.get('username');
  }
  // get cost_per_kilo() {
  //   return this.userForm.get('cost_per_kilo');
  // }


  get email() {
    return this.userForm.get('email');
  }
  // addAirCost(){
  //   console.log("this.formval",this.userForm)
  //   console.log("++++++++++++++++++++++++++++",this.airCostData)
  //   if (this.userForm.invalid) {
  //     // If form is invalid, mark all controls as touched to show validation errors
  //     this.userForm.markAllAsTouched();
  //     return;
  //   }
  //   console.log("this.formval",this.userForm)
  //   console.log("++++++++++++++++++++++++++++",this.airCostData)
  //   const formValue = this.userForm.getRawValue(); // This will include disabled controls like partNo
  //   var updatedData = [];
  //   var updatedFields = {
  //     business_entity: this.namcValue,
  //     cost_per_kilo_old:this.airCostData[0].cost_per_kilo,
  //     cost_per_cubic_old:this.airCostData[0].cost_per_cubic,
  //     cost_per_kilo:this.userForm.value.cost_per_kilo,
  //     cost_per_cubic:this.userForm.value.cost_per_cubic


  //   };
  //   updatedData.push(updatedFields);

  //   const data = {
  //     offset: 0,
  //     limit: 1200,
  //     business_entity: this.namcValue,
  //     workid: this.user,
  //     user_role: this.userRole,
  //     data: updatedData,
  //   };
  //   // Get the values at that index (the form group values)
  //   this.airFreightService.getDeleteAirorder(data).subscribe({
  //     error: this.errorCallback,
  //     next: (data) => {
  //       if (data.body.data.table1DeletedCount >= 1 || data.body.data.table2DeletedCount >= 1) {
  //         this.successMessage = successMessage.deleteRecord;
  //         this.spinner.hide();
  //         window.scroll(0, 0);
  //       } else {
  //         this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  //         this.spinner.hide();
  //       }
  //     },
  //   });


  // }



  addAirCost() {
    console.log("this.userForm", this.userForm);
    console.log("+", this.airCostData);
    this.removeMessage();

    if (this.airCostData[0]?.cost_per_kilo == this.userForm.value.cost_per_kilo
      && this.airCostData[0]?.cost_per_cubic == this.userForm.value.cost_per_cubic) {
      this.warningMessage = 'please update the data'
    }
    if (this.warningMessage) {
      return;
    }


    var updatedData = [];
    var updatedFields = {
      business_entity: this.namcValue,
      cost_per_kilo_old: this.airCostData[0]?.cost_per_kilo,
      cost_per_cubic_old: this.airCostData[0]?.cost_per_cubic,
      cost_per_kilo: this.userForm.value.cost_per_kilo,
      cost_per_cubic: this.userForm.value.cost_per_cubic,
      userid: localStorage.getItem('UserName'),
    };
    updatedData.push(updatedFields);

    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData,
    };

    this.airFreightService.editAirCost(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        if (data.body.data.updatedCount >= 1) {
          this.successMessage = successMessage.recordUpdated;
          this.userForm.value.cost_per_kilo =
            this.userForm.patchValue({
              cost_per_kilo: this.userForm.value.cost_per_kilo,
              cost_per_cubic: this.userForm.value.cost_per_cubic
            });
          this.airCostData[0].cost_per_kilo = this.userForm?.value?.cost_per_kilo,
            this.airCostData[0].cost_per_cubic = this.userForm?.value?.cost_per_cubic


          this.spinner.hide();
          window.scroll(0, 0);
          // this.router.navigate(['ordering/airfreightorder']);
          this.dialogRef.close("activate");  // Ensure this is called when the Activate button is clicked


          // Close the popup/modal
          // this.dialogRef.close();  

          // Navigate back to the main component (or home page)
          // this.router.navigate(['/main']);  // Replace '/main' with your desired route path
        } else {
          // this.dialogRef.close("activate1");
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });
  }
  addAirCasecode() {
    console.log("this.formval", this.userForm);
    // console.log("++++++++++++++++++++++++++++", this.airCostData);

    // if (this.userForm.invalid) {
    //   this.userForm.markAllAsTouched();
    //   return;
    // }
    if (this.userForm.value.airCode == '' || this.userForm.value.airCode == null && this.userForm.value.dockNo != null) {
      this.warningMessage = 'Dock And Air code is required'
    }
    if (this.userForm.value.airCode == this.userForm.value.dockNo.air_case_code) {
      this.warningMessage = 'Plesae update the data'
    }
    console.log("this.selectedDockData.air_case_code", this.selectedDockData)
    if (
      this.selectedDockData?.some(code => code.air_case_code.toLowerCase() === this.userForm.value.airCode?.toLowerCase())) {
      this.warningMessage = 'The aircase code for the dock already exists.';
    }

    if (this.userForm.value.airCode != this.userForm.value.dockNo.air_case_code) {
      const isUpdateAir = true;
    }
    if (this.warningMessage) {
      return;
    }


    var updatedData = [];
    var updatedFields = {
      business_entity: this.namcValue,
      air_case_code_old: this.userForm.value.airCode,
      air_case_code: this.userForm.value.airCode,
      dock: this.userForm.value.dockNo.dock,
      userid: localStorage.getItem('UserName'),
      isUpdate: false
    };
    updatedData.push(updatedFields);

    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData,
      air_case_code_old: this.userForm.value.airCode,
      air_case_code: this.userForm.value.airCode,
      dock: this.userForm.value.dockNo.dock,
      userid: localStorage.getItem('UserName'),

    };
    localStorage.setItem('isAirCaseCodeSetToDefault', 'true')



    this.airFreightService.editAddAirCaseCode(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        if (data.body.addResponse.insertedCount >= 1) {
          this.successMessage = successMessage.recordUpdated;
          this.selectedDockData.push(data.body.addResponse?.insertedRows[0]);
          this.airCodeandDock.push(data.body.addResponse?.insertedRows[0])
          this.userForm.get('airCode').reset();

          this.spinner.hide();
          window.scroll(0, 0);
          // this.router.navigate(['ordering/airfreightorder']);
        } else {
          // this.dialogRef.close("activate1");
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });
  }

  addContact() {
    this.removeMessage();
    console.log(this.userForm)
    if (!this.userForm?.value?.contact_name) {
      this.warningMessage = 'Please enter the contact name and select the role';
      return;
    }
    if (this.userForm?.value?.contact_role === null) {
      this.warningMessage = 'Please enter the contact name and select the role';
      return;
    }



    const data3 = this.userForm.value?.contact_name.trim();  // Trim the input value

    let isUpdate = this.contactData.some(item => item.contact_name.trim().toLowerCase() === data3.toLowerCase());
    let contactId = this.contactData.filter(item => item.contact_name.trim().toLowerCase() === data3.toLowerCase());
    console.log("contact", contactId)
    console.log(isUpdate)
    var updatedData = [];
    var updatedFields = {
      business_entity: this.namcValue,
      contact_name: this.userForm?.value?.contact_name,
      contact_role: this.userForm?.value?.contact_role,
      userid: localStorage.getItem('UserName'),
    };
    updatedData.push(updatedFields);
    localStorage.setItem('isContactadded', 'true')


    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      contact_name: this.userForm?.value?.contact_name,
      contact_role: this.userForm?.value?.contact_role,
      contact_id: contactId[0]?.contact_id,
      userid: localStorage.getItem('UserName'),
      isUpdate: isUpdate
    };

    // Make the API call
    this.airFreightService.addContact(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        console.log("data", data);
        if (data.body.addResponse?.insertedCount >= 1) {
          this.successMessage = successMessage.recordUpdated;

          if (!isUpdate) {
            // Only push new codes to the list, if it's not an update
            this.contactData.push(data.body.addResponse?.insertedRows[0]);
          } else {
            // If it's an update, replace the existing code with updated fields
            const updatedBudgetCode = data.body.addResponse?.insertedRows[0];
            const index = this.contactData.findIndex((code) => code.contact_name === updatedBudgetCode.contact_name);

            if (index !== -1) {
              // Replace the old entry with the updated one
              this.contact_data[index] = updatedBudgetCode;
            }
          }

          // Make sure budgetCodes is unique after adding or updating
          this.contactData = [
            ...new Map(this.contactData.map(item => [`${item.contact_name}-${item.contact_role}`, item])).values()
          ];          // Reset form fields
          this.userForm.get('contact_name').reset();
          this.userForm.get('contact_role').reset();

          this.spinner.hide();
          window.scroll(0, 0);
        } else {
          this.warningMessage = `${warningMessage.apiLogicFail} ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });




  }


  // Submit function
  onSubmit() {
    if (this.userForm.valid) {
      this.formSubmitted = true;
      console.log('Form Submitted!', this.userForm.value);
    }
  }

  callApi() {
    const addApiSubcriptions = [
      this.airFreightService.getAirCostData(),
      this.airFreightService.getBudgetCodesDefault(),
      this.lookupservice.getDock(),
      this.airFreightService.getContactName(),
      this.airFreightService.getConfigDock(),
      this.airFreightService.getAirfreightBudgetCode()

    ];

    forkJoin(addApiSubcriptions).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.spinner.show();
        if (res[0].body.data) {
          // this.addAirOrderPart.push(...res[0].body.data);
          const AirCostData = res[0]?.body.data;
          // const BudgetCodeData = res[1]?.body.data;
          // const contactData=res[2]?.boday.data

          console.log("this.airCostData", res[0].body.data[0]?.cost_per_kilo)
          let fromHoursHH: number | undefined, fromMinutesMM: number | undefined;


          // if (res[0]?.body.data[0]?.receiving_etatime) {
          //   const [hh, mm] = res[0]?.body.data[0]?.receiving_etatime.split(':').map(Number);
          //   fromHoursHH = hh;
          //   fromMinutesMM = mm;
          // }
          const receivingEtaTime = res[0]?.body.data[0]?.receiving_etatime;

          if (receivingEtaTime && typeof receivingEtaTime === 'string' && receivingEtaTime.includes(':')) {
            const [hh, mm] = receivingEtaTime.split(':').map(Number);
            fromHoursHH = hh;
            fromMinutesMM = mm;
          } else {
            // Handle the case when receivingEtaTime is invalid or doesn't have the correct format
            console.error('Invalid or missing receivingEtaTime');
          }

          console.log("aa", fromHoursHH,
            fromMinutesMM)
          this.userForm.patchValue({
            cost_per_kilo: res[0]?.body.data[0]?.cost_per_kilo,
            cost_per_cubic: res[0]?.body.data[0]?.cost_per_cubic,
            dockNo: res[2]?.body.data[0],
            fromHoursHH: fromHoursHH,
            fromMintuesMM: fromMinutesMM,

            // receivingEtaDate:(res[0]?.body.data[0]?.receiving_eta_time).slice(-2)
          });
          // const BudgetCodeData = res[1]?.body.data;
          // const contactData=res[2]?.boday.data

          this.airCostData = AirCostData
          const airCodeData = res[2]?.body.data
          const findAircasecode = res[4]?.body.data
          const existbudgetcode = res[5]?.body.data
          this.existbudgetCode = existbudgetcode
          console.log("this.airCostData", this.airCostData)
          console.log("airCodeData", airCodeData);
          console.log("findAircasecode", findAircasecode);


          this.airCodeDockData = airCodeData
          console.log("aircase code", this.airCodeDockData)
          this.airCodeandDock = findAircasecode

          this.selectedDockData = [];
          this.airCodeandDock.forEach(item => {
            // Check if the condition is met (dock === 'a')
            if (item.dock === res[2]?.body.data[0]?.dock) {
              // Push item into filteredArray if condition is true
              this.selectedDockData.push(item);
            }
          });

          const defaultAirCaseCode = this.airCodeandDock.find(budget => budget.is_default)?.air_case_code;

          if (defaultAirCaseCode) {
            this.userForm.get('air_case_code1').setValue(defaultAirCaseCode);  // Set the default budget_code
          }
        }
        const BudgetCodeData = res[1]?.body.data;
        const contactData = res[3]?.body.data
        this.budgetCodes = BudgetCodeData;
        this.contactData = contactData
        const defaultBudgetCode = this.budgetCodes.find(budget => budget.is_default)?.budget_code;

        if (defaultBudgetCode) {
          this.userForm.get('budget_code1').setValue(defaultBudgetCode);  // Set the default budget_code
        }


        // this.budgetCode = budgetCode;
        // this.airCode=airCode

        // if (this.addAirOrderDestcode.length === 1) {
        //   // Set the entire object to the form control, not just the dest_code
        //   this.selectedDestCode = this.addAirOrderDestcode[0]; // The object to be selected

        //   //// this.addOrEditform.controls.destCode.setValue(this.selectedDestCode);
        // }

        this.spinner.hide();
      },
    });

  }
  formatNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  onDockChange(data) {
    console.log("this.for", this.userForm)
    let slecteddock = this.userForm.value.dockNo.dock

    console.log("aircase code", this.airCodeandDock)

    // Loop through each item in the dataArray
    this.selectedDockData = [];
    this.airCodeandDock.forEach(item => {
      // Check if the condition is met (dock === 'a')
      if (item.dock === slecteddock) {
        // Push item into filteredArray if condition is true
        this.selectedDockData.push(item);
      }
    });
    console.log("++++++", this.selectedDockData)

    // this.userForm.patchValue({
    //   airCode: this.userForm?.value?.dockNo.air_case_code,
    // });

  }
  updateETAtime() {
    const fromHoursHH = this.userForm.get('fromHoursHH')?.value;
    const fromMintuesMM = this.userForm.get('fromMintuesMM')?.value;
    const fromTimeNew = `${fromHoursHH.toString().padStart(2, '0')}:${fromMintuesMM.toString().padStart(2, '0')}:00`;
    console.log("fromTimeNew", fromTimeNew, this.airCostData[0]?.receiving_etatime)


    if (fromTimeNew === this.airCostData[0]?.receiving_etatime) {
      this.warningMessage = 'Please Update the time';
      return;
    }
    var updatedFields = {
      business_entity: this.namcValue,
      receiving_eta_time: fromTimeNew,
      receiving_eta_time_old: this.airCostData[0]?.receiving_etatime,
      userid: localStorage.getItem('UserName'),
    };

    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      receiving_eta_time: this.userForm.value.receivingEtaDate,
      receiving_eta_time_old: this.airCostData[0]?.receiving_etatime,
      userid: localStorage.getItem('UserName'),
      data: [updatedFields]

    };


    // Make the API call
    this.airFreightService.updateEtatime(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        console.log("data", data);
        if (data.body.data?.updatedCount >= 1) {
          this.successMessage = successMessage.recordUpdated;



          this.spinner.hide();
          window.scroll(0, 0);
        } else {
          this.warningMessage = `${warningMessage.apiLogicFail} ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });


  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  setDefaultRole() {
    // Example condition for default value
    let condition = true;  // Your condition logic here
    if (condition) {
      this.selectedRole = 'KVC';  // Set default to 'KVC'
    } else {
      this.selectedRole = 'MNG';  // Or set to another role based on the condition
    }
  }
  onRoleChange(selectedRole: any) {
    console.log('Selected Role:', selectedRole);
    this.selectedRole = selectedRole
  }

  onBudgetCodeSelect(BudgetCode) {
    localStorage.setItem('isBudgetCodeSetToDefault', 'true')
    let updatedData2 = [];
    var updatedFields = {
      business_entity: this.namcValue,
      budget_code: BudgetCode,
      is_default: 'true',
      is_not_default: 'false',
      userid: localStorage.getItem('UserName'),
    };
    updatedData2.push(updatedFields);


    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      budget_code: BudgetCode,
      is_default: 'true',
      is_not_default: 'false',
    };

    this.airFreightService.setdefaultBudgetCode(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        if (data.body.data.updatedCount >= 1) {
          this.successMessage = 'Updated default Budget Code';

          this.spinner.hide();
          window.scroll(0, 0);
        } else {
          // this.dialogRef.close("activate1");
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });

  }



  // deleteAirCode(AirCodeToDelete) {
  //   console.log("inside delete", AirCodeToDelete);
  //   this.warningMessage='';
  //   let a;

  //   this.airFreightService.getAirfreightAirCaseCodes().subscribe({
  //     error: this.errorCallback,
  //     next: (data) => {
  //       if (data.body.data) {
  //         let isordercreated = data.body.data
  //         const combinationExists = isordercreated.some(item => item.air_case_code === AirCodeToDelete.air_case_code
  //           && item.dock === AirCodeToDelete.dock);
  //         if (combinationExists) {
  //           this.warningMessage = 'You can not delete a air code that is still used. ';
  //           return;

  //         }else{
  //           this.warningMessage=''
  //         }


  //         this.spinner.hide();
  //         window.scroll(0, 0);
  //       } else {
  //         // this.dialogRef.close("activate1");
  //         this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  //         this.spinner.hide();
  //       }
  //     },
  //   });
  //   console.log(this.warningMessage,"_____*________")

  //   if (this.warningMessage) {
  //     console.log(this.warningMessage,"_____*________")

  //     return;
  //   }else{
  //     console.log(this.warningMessage,"______+_______")



  //   let exportData = []
  //   exportData.push({ ...AirCodeToDelete, isDeleteAirCaseCode: true });

  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.id = 'modal-component9';
  //   dialogConfig.height = '204px';
  //   dialogConfig.width = '470px';
  //   dialogConfig.data = exportData;

  //   const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

  //   dialogRef.afterClosed().subscribe((res) => {
  //     if (res === 'cancel') {
  //       return;
  //     } else if (res === 'save') {
  //       console.log("inside save");
  //       console.log("data to delete", exportData)
  //       const data = {
  //         offset: 0,
  //         limit: 1200,
  //         business_entity: this.namcValue,
  //         workid: this.user,
  //         user_role: this.userRole,
  //         air_case_code: exportData[0].air_case_code,
  //         dock: exportData[0].dock,
  //         data: exportData

  //       };
  //       localStorage.setItem('isAirCaseCodeSetToDefault', 'true')

  //       this.airFreightService.deletAirCaseCode(data).subscribe({
  //         error: this.errorCallback,
  //         next: (data) => {
  //           if (data.body.addResponse.DeletedCount >= 1) {
  //             this.successMessage = 'Air code deleted successfully';
  //             const index = this.selectedDockData.findIndex((item) =>
  //               item.air_case_code.trim().toLowerCase() === exportData[0]?.air_case_code.trim().toLowerCase()
  //             );

  //             if (index !== -1) {
  //               // Remove the contact from the array if it exists
  //               this.selectedDockData.splice(index, 1);

  //             }
  //             console.log("this.airCodeandDock", this.airCodeandDock)
  //             // Loop through the array backwards to avoid index issues when removing items
  //             for (let i = this.airCodeandDock.length - 1; i >= 0; i--) {
  //               if (
  //                 this.airCodeandDock[i].dock.trim().toLowerCase() === exportData[0].dock.trim().toLowerCase() &&
  //                 this.airCodeandDock[i].air_case_code.trim().toLowerCase() === exportData[0].air_case_code.trim().toLowerCase()
  //               ) {
  //                 // Remove the item at index i
  //                 this.airCodeandDock.splice(i, 1);
  //               }
  //             }


  //             console.log("this.airCodeandDock", this.airCodeandDock)

  //             this.spinner.hide();
  //             window.scroll(0, 0);
  //           } else {
  //             this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  //             this.spinner.hide();
  //           }
  //         },
  //       });


  //     }
  //   });
  // }


  // }



  deleteAirCode(AirCodeToDelete) {
    console.log("inside delete", AirCodeToDelete);
    this.warningMessage = '';

    this.airFreightService.getAirfreightAirCaseCodes().subscribe({
      error: this.errorCallback,
      next: (data) => {
        if (data.body.data) {
          let isordercreated = data.body.data;

          // Check if the combination exists in the data
          const combinationExists = isordercreated.some(item =>
            item.air_case_code === AirCodeToDelete.air_case_code && item.dock === AirCodeToDelete.dock
          );

          if (combinationExists) {

            this.warningMessage = 'You cannot delete an air code that is still used.';
            console.log(this.warningMessage);
            return;
          }

          // If the combination doesn't exist, proceed with the pop-up
          this.warningMessage = '';
          this.spinner.hide();
          window.scroll(0, 0);

          let exportData = [];
          exportData.push({ ...AirCodeToDelete, isDeleteAirCaseCode: true });

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'modal-component9';
          dialogConfig.height = '204px';
          dialogConfig.width = '200px';
          dialogConfig.data = exportData;

          // Open the delete confirmation pop-up
          const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

          dialogRef.afterClosed().subscribe((res) => {
            if (res === 'cancel') {
              return;  // If canceled, do nothing
            } else if (res === 'save') {
              console.log("inside save");
              console.log("data to delete", exportData);

              // Construct data for deletion
              const data = {
                offset: 0,
                limit: 1200,
                business_entity: this.namcValue,
                workid: this.user,
                user_role: this.userRole,
                air_case_code: exportData[0].air_case_code,
                dock: exportData[0].dock,
                data: exportData
              };

              // Mark this as a default deletion process
              localStorage.setItem('isAirCaseCodeSetToDefault', 'true');

              // Call the deletion service
              this.airFreightService.deletAirCaseCode(data).subscribe({
                error: this.errorCallback,
                next: (response) => {
                  if (response.body.addResponse.DeletedCount >= 1) {
                    this.successMessage = 'Air code deleted successfully';

                    // Remove the deleted air code from selectedDockData
                    const index = this.selectedDockData.findIndex((item) =>
                      item.air_case_code.trim().toLowerCase() === exportData[0]?.air_case_code.trim().toLowerCase()
                    );

                    if (index !== -1) {
                      this.selectedDockData.splice(index, 1);
                    }

                    // Remove the deleted air code from airCodeandDock array
                    for (let i = this.airCodeandDock.length - 1; i >= 0; i--) {
                      if (
                        this.airCodeandDock[i].dock.trim().toLowerCase() === exportData[0].dock.trim().toLowerCase() &&
                        this.airCodeandDock[i].air_case_code.trim().toLowerCase() === exportData[0].air_case_code.trim().toLowerCase()
                      ) {
                        this.airCodeandDock.splice(i, 1);
                      }
                    }

                    console.log("this.airCodeandDock", this.airCodeandDock);
                    this.spinner.hide();
                    window.scroll(0, 0);
                  } else {
                    this.warningMessage = `${warningMessage.apiLogicFail} ${this.itContact}.`;
                    this.spinner.hide();
                  }
                },
              });
            }
          });
        } else {
          // Handle the case when the data is malformed or not present
          this.warningMessage = `${warningMessage.apiLogicFail} ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });



    if (this.warningMessage) {
      return;
    }
  }



  // set default air case code
  onAircodeSelect(air_case_code) {
    console.log("user form", this.userForm)
    console.log(air_case_code)
    localStorage.setItem('isAirCaseCodeSetToDefault', 'true')
    let updatedData2 = [];
    var updatedFields = {
      business_entity: this.namcValue,
      air_case_code: air_case_code,
      dock: this.userForm.value.dockNo.dock,
      is_default: 'true',
      is_not_default: 'false',
      userid: localStorage.getItem('UserName'),
    };
    updatedData2.push(updatedFields);


    const data = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      air_case_code: air_case_code,
      dock: this.userForm.value.dockNo.dock,
      is_default: 'true',
      is_not_default: 'false',
    };
    localStorage.setItem('isAirCaseCodeSetToDefault', 'true')

    this.airFreightService.setdefaultAirCode(data).subscribe({
      error: this.errorCallback,
      next: (data) => {
        if (data.body.data.updatedCount >= 1) {
          this.successMessage = 'Updated default Aircase Code';

          this.spinner.hide();
          window.scroll(0, 0);
        } else {
          // this.dialogRef.close("activate1");
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
          this.spinner.hide();
        }
      },
    });

  }

  onTabChange(event: MatTabChangeEvent): void {
    // You can extract the index from the event
    this.selectedTabIndex = event.index;
    this.removeMessage()
    console.log('Selected Tab Index:', this.selectedTabIndex);
  }
}










