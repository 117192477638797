<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage " class="alert alert-success" role="alert">
      {{ successMessage }}

      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}

      <button
        *ngIf="warningMessage"
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>

  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Procurement</h2>
      </div>
      <!-- <div class="p-2">
        <button (click)="getdownloadTemplate()" type="button" class="btn btn-light">
          <img
            src="./assets/images/Group 115533.png"
            class="downloadIcon"
            alt="download icon"
            (click)="getdownloadTemplate()"
          />
          TEMPLATE
        </button>
      </div> -->
      <!-- <div class="p-2">
        <button type="button" class="master-primary-btn" (click)="addNew()">
          ADD AIR FREIGHT
        </button>

        
      </div> -->
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DEST CODE</label>
                <p-multiSelect
                  [(ngModel)]="selectedDestCode"
                  [showToggleAll]="false"
                  (onChange)="onDestCodeChange()"
                  [options]="destCodeList"
                  optionLabel="dest_code"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  [appendTo]="'body'"
                  [panelStyle]="{ width: '60%' }"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">ORDER DT FROM </label>
                <input
                  [(ngModel)]="ordDate"
                  [max]="ordDateTo"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">ORDER DT TO</label>
                <input
                  [(ngModel)]="ordDateTo"
                  [min]="ordDate"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div> -->
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">DOCK</label>
                <p-multiSelect
                  [showToggleAll]="false"
                  [(ngModel)]="selectedDock"
                  [options]="dockList"
                  (onChange)="dockChange()"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="dock"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                  [appendTo]="'body'"
                >
                </p-multiSelect>
              </div>
            </div>
            <!-- <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">AIR CASE CODE</label>
                <p-multiSelect
                  [showToggleAll]="false"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [(ngModel)]="selectedAircaseCode"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  (onChange)="airCaseCodeChange()"
                  selected="false"
                  [options]="airCaseCode"
                  optionLabel="air_case_code"
                  [appendTo]="'body'"

                >
                </p-multiSelect>
              </div>
            </div> -->
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">PART NO</label>
                <p-multiSelect
                  [(ngModel)]="selectedPartNo"
                  (onChange)="onPartNoChange($event)"
                  [showToggleAll]="false"
                  [options]="partNoList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="part_no"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                  [disabled]="disablePartNumber"
                  [appendTo]="'body'"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">KANBAN</label>
                <p-multiSelect
                  [(ngModel)]="selectedKanban"
                  [showToggleAll]="false"
                  (onChange)="onKanbanChange()"
                  [options]="kanbanList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="kanban"
                  [panelStyle]="{ width: '140%' }"
                  selected="false"
                  [disabled]="disableKanban"
                  [appendTo]="'body'"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3" style="height: 23.98px;">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">WEEK</label>
                <input
                class="key"
                type="text"
                placeholder="YYYYDD"
                style="width: 100%; height: 23.98px;"
              />
                <!-- <p-multiSelect
                  [(ngModel)]="selectedStatus"
                  [showToggleAll]="false"
                  [options]="statusList"
                  optionLabel="air_order_status"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  
                  selected="false"
                  [appendTo]="'body'"
                >
                </p-multiSelect> -->
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                  (click)="onResetDropDown()"
                />
              </label>
              <label class="resetLabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-sm-6">
        <span class="edit_icon">
          <img
            class="img-tool"
            (click)="exportAsXLSX()"
            src="./assets/images/download.svg"
            alt="download icon"
            title="Download"
          />
        </span>
      </div> -->

      <div class="col-sm-4"></div>

      <!-- <div class="col-sm-4">
        <div class="records">
          <p-multiSelect #dd
            class="page-drpdwn-label showhide"
            [(ngModel)]="selectedShowHideList"
            (onChange)="onShowHideChange($event)"
            [options]="showHideList"
            optionLabel="label"
            [displaySelectedLabel]="false"
            [defaultLabel]="'SHOW/HIDE COLUMN'"
            [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '50%', background: '#F5F5F5' }"
            [resetFilterOnHide]="true"
          >
            <p-footer>
              <div class="flex justify-content-between p-1" style="width: 100%; padding: 10px;">
                <button type="button" (click)="saveShowHide(); dd.hide()" class="save_btn" >Save</button>
              </div>
            </p-footer>
          </p-multiSelect>
        </div>
      </div> -->

      <!-- <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div> -->
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card1 spacingLeft">
        <div id="dvgrid" *ngIf="isEditMode">
          <!-- <div class="m-2">
            <div class="row">
              <div class="col-4">
                <label for="input1">Kanban</label>
                <input type="text" id="input1" class="form-control" />
              </div>
              <div class="col-4">
                <label for="input2">Vehicle</label>
                <input type="text" id="input2" class="form-control" />
              </div>
              <div class="col-4">
                <label for="input3">PART NO</label>
                <input type="text" id="input3" class="form-control" />
              </div>
            </div>
  
            <div class="row">
              <div class="col-4">
                <label for="input4">VAN FROM</label>
                <input type="text" id="input4" class="form-control" />
              </div>
              <div class="col-4">
                <label for="input5">VAN TO</label>
                <input type="text" id="input5" class="form-control" />
              </div>
              <div class="col-4">
                <label for="input6">Location</label>
                <input type="text" id="input6" class="form-control" />
              </div>
            </div>
  
            <div class="row">
              <div class="col-4">
                <label for="input7">Product Code</label>
                <input type="text" id="input7" class="form-control" />
              </div>
              <div class="col-4">
                <label for="input8">Quantity</label>
                <input type="text" id="input8" class="form-control" />
              </div>
              <div class="col-4">
                <label for="input9">Price</label>
                <input type="text" id="input9" class="form-control" />
              </div>
            </div>
  
          </div> -->
          <div class="filter  d-flex " >
            <div class="d-flex  align-items-left col-lg-4 col-md-6 col-12">
                <label class="searchLable" for="family-code" class="col-4">KANBAN</label>
                <input type="text" id="family-code" style="width: 100%;" readonly>
            </div>
            <div class="d-flex  align-items-left col-lg-4 col-md-6 col-12"></div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
                <label class="searchLable" for="sop" class="col-4">VEHICLE</label>
                <input type="text" id="sop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex  align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">PART NO</label>
                <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
                <label for="eop" class="col-4">VAN FROM</label>
                <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="sop" class="col-4">VAN TO</label>
              <input type="text" id="sop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex  align-items-left col-lg-4 col-md-6 col-12">
              <label for="sop" class="col-4">DOCK</label>
              <input type="text" id="sop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">LINEOFF FROM</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">LINEOFF TO</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">SPECIALIST</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">SOP COND</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">BO COND</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">PC ADDRESS</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">ORDLOT</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">BOXLOT</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">SEQ ADDRESS</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">INVADJ</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">VENDSHARE</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <!-- <label for="eop" class="col-4">VENDSHARE</label> -->
              <!-- <input type="text" id="eop" style="width: 100%;"> -->
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">PACKCASE</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">COLOUR</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4"> LS ADDRESS</label>
              <!-- <input type="texta" id="eop" style="width: 100%;" readonly> -->
              <textarea id="comments" name="comments" rows="4" cols="50" readonly class="textareaColour"></textarea>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4" style="line-height: 1; margin: 0;">PARTS COUNT GROUPS</label>
              <!-- <input type="text" id="eop" style="width: 100%;" readonly> -->
              <textarea id="comments" name="comments" rows="4" cols="50" readonly class="textareaColour"></textarea>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <!-- <label for="eop" class="col-4">COLOUR</label> -->
              <!-- <input type="text" id="eop" style="width: 100%;"> -->
            </div>
            
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">COMMENT</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <!-- <label for="eop" class="col-4">DESCRIPTION</label> -->
              <!-- <input type="text" id="eop" style="width: 100%;"> -->
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <!-- <label for="eop" class="col-4">DESCRIPTION</label> -->
              <!-- <input type="text" id="eop" style="width: 100%;"> -->
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">DESCRIPTION</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <!-- <label for="eop" class="col-4">DESCRIPTION</label> -->
              <!-- <input type="text" id="eop" style="width: 100%;"> -->
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <!-- <label for="eop" class="col-4">DESCRIPTION</label> -->
              <!-- <input type="text" id="eop" style="width: 100%;"> -->
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">INV POLICY</label>
              <input type="text" id="eop" style="width: 100%; height: 29%" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">PART CONTROL</label>
              <!-- <input type="text" id="eop" style="width: 100%;" readonly> -->
              <textarea id="comments" name="comments" rows="4" cols="50" readonly class="textareaColour"></textarea>

            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">PCA CODE</label>
              <!-- <input type="text" id="eop" style="width: 100%;" readonly> -->
              <textarea id="comments" name="comments" rows="4" cols="50" readonly class="textareaColour"></textarea>

            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">MIN</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">MAX</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>
            <div class="d-flex align-items-left col-lg-4 col-md-6 col-12">
              <label for="eop" class="col-4">SPEED</label>
              <input type="text" id="eop" style="width: 100%;" readonly>
            </div>

          </div>
  
          <br>
          <div class="grid-agg px-0">
            <ag-grid-angular 
              style="width:100%;height:26vh;" 
              class="ag-theme-balham grid-items" 
              [gridOptions]="gridOptions"
              [columnDefs]="columnDefs1" 
              [rowData]="rowData1" 
              [suppressCellSelection]="true"
              [headerHeight]="40" 
              >
            </ag-grid-angular>
          </div>
          <div>
            <!-- <ag-grid-angular
              #osPartsGrid
              style="width: 100%; top: 1%; margin-bottom: 5%; height:25vh;"
              class="ag-theme-balham"
              [columnDefs]="columnDefs"
              [rowData]="rowData"
              [rowSelection]="rowSelection"
              [gridOptions]="gridOptions"
              [pagination]="true"
              [paginationPageSize]="paginationPageSize"
              [context]="context"
              [suppressClickEdit]="suppressClickEdit"
              [frameworkComponents]="frameworkComponents"
              [tooltipShowDelay]="tooltipShowDelay"
              [tooltipMouseTrack]="true"
              [editType]="editType"
              [groupRowsSticky]="groupRowsSticky"
              [autoGroupColumnDef]="autoGroupColumnDef"
              [groupDefaultExpanded]="groupDefaultExpanded"
              [showOpenedGroup]="showOpenedGroup"
              [groupSelectsChildren]="groupSelectsChildren"
              [suppressRowClickSelection]="suppressRowClickSelection"
              [suppressAggFuncInHeader]="suppressAggFuncInHeader"
              [groupDisplayType]="groupDisplayType">
            </ag-grid-angular> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="filter mx-1 d-flex" >
    <div class="d-flex  align-items-center col-lg-4 col-md-6 col-12">
        <label for="family-code" class="col-4">Family Code</label>
        <input type="text" id="family-code" style="width: 100%;">
    </div>
    <div class="d-flex  align-items-center col-lg-4 col-md-6 col-12"></div>
    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="sop" class="col-4">SOP From Date</label>
        <input type="date" id="sop" style="width: 100%;" >
    </div>
    <div class="d-flex  align-items-center col-lg-4 col-md-6 col-12">
      <label for="eop" class="col-4">EOP From Date</label>
        <input type="date" id="eop" style="width: 100%;">
    </div>
    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
        <label for="eop" class="col-4">EOP From Date</label>
        <input type="date" id="eop" style="width: 100%;">
    </div>
    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
      <label for="sop" class="col-4">SOP From Date</label>
      <input type="date" id="sop" style="width: 100%;" >
    </div>
    <div class="d-flex  align-items-center col-lg-4 col-md-6 col-12">
      <label for="sop" class="col-4">SOP From Date</label>
      <input type="date" id="sop" style="width: 100%;" >
    </div>
    <div class="d-flex align-items-center col-lg-4 col-md-6 col-12">
      <label for="eop" class="col-4">EOP From Date</label>
      <input type="date" id="eop" style="width: 100%;">
    </div>
  </div> -->
  
  
  <!-- <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div id="dvgrid" class="gridStyle" *ngIf="isEditMode">
          <div class=" d-flex input-container m-2">
       
  
            <div class="d-flex row col-4">
              <div class="col-3">
                <label for="input1">Kanban</label>
              </div>
              <div class="col-9">
                <input type="text" id="input1" class="form-control" />
              </div>
            </div>
            <div class="d-flex row col-4">
              <div class="col-3">
                
              </div>
              <div class="col-9">
                
              </div>
            </div>
            <div class="d-flex row col-4">
              <div class="col-3">
                <label for="input1">Vehicle</label>
              </div>
              <div class="col-9">
                <input type="text" id="input1" class="form-control" />
              </div>
            </div>
            
          </div>
          <div class="  d-flex input-container ">
            <div class="d-flex row col-4">
              <div class="col-3">
                <label for="input1">PART NO</label>
              </div>
              <div class="col-9">
                <input type="text" id="input1" class="form-control" />
              </div>
            </div>
            <div class="d-flex row col-4">
              <div class="col-3">
                <label for="input1">VAN FROM</label>
              </div>
              <div class="col-9">
                <input type="text" id="input1" class="form-control" />
              </div>
            </div>
            <div class="d-flex row col-4">
              <div class="col-3">
                <label for="input1">VAN TO</label>
              </div>
              <div class="col-9">
                <input type="text" id="input1" class="form-control" />
              </div>
            </div>
          </div>
          <br>
          <div>
            <ag-grid-angular #osPartsGrid style="width: 100%; top: 1%; margin-bottom: 5%" class="ag-theme-balham"
              [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
              [pagination]="true" [paginationPageSize]="paginationPageSize" [context]="context"
              [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
              [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true" [editType]="editType"
              [groupRowsSticky]="groupRowsSticky" [autoGroupColumnDef]="autoGroupColumnDef"
              [groupDefaultExpanded]="groupDefaultExpanded" [editType]="editType" [showOpenedGroup]="showOpenedGroup"
              [groupSelectsChildren]="groupSelectsChildren" [suppressRowClickSelection]="suppressRowClickSelection"
              [suppressAggFuncInHeader]="suppressAggFuncInHeader" [groupDisplayType]="groupDisplayType">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>
