import { Component, OnInit, ViewChild } from '@angular/core';
import { GriddataService } from '../services/griddata.service';
import { Subscription, forkJoin } from 'rxjs';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { logErrors } from '../shared/logger';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { dateFilterComparator, getUnique, getUniqueByKey, resetSortingAndFilters } from '../constants';
import { NoofVehiclesVannedEditorComponent } from './no-of-vehicles-vanned.component';
import { StockingPaceServiceService } from '../services/stocking-pace-service.service';
import { ExcelService } from '../services/excel.service';
import { validateVanningDates, warningMessage } from '../constants';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-stocking-pace-inquiry',
  templateUrl: './stocking-pace-inquiry.component.html',
  styleUrls: ['./stocking-pace-inquiry.component.scss'],
})
export class StockingPaceInquiryComponent implements OnInit {
  headerCheckboxChecked: any;
  dateComparator: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  isEditMode = false;
  editEnabled: boolean = false;
  defaultPageSize: any = 100000;
  offset: number = 0;
  user: any;
  userRole: any;
  dataCount: number = 0;
  stockingPaceSubscription$: Subscription;
  selectedDock: any = [];
  selectedModuleType: any = [];
  selectedVanMonth: any = [];

  columnDefs: any;
  rowData: any;
  rowSelection: any;
  gridOptions: any;
  pagination: any;
  paginationPageSize: any;
  context: any;
  suppressClickEdit: boolean;
  frameworkComponents: any;
  tooltipShowDelay: any;
  editType: any;
  search_form = {
    dock: '',
    module_type: '',
    van_month: '',
  };
  dockModuleList: any = [];
  dockList: any = [];
  vanMonthList: any = [];
  moduleTypeList: any = [];
  // temp_dock_module_type: any = [];
  disablemodule = true;
  constructor(
    private readonly gridDataService: GriddataService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService,
    private readonly stockingService: StockingPaceServiceService,
    private readonly excelService: ExcelService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  ngOnInit(): void {
    console.log('on Init call.');
    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Dock',
        field: 'dock',
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Module Description',
        field: 'module_description',
        width: 210,
        floatingFilter: true,
        tooltipField: 'name',
      },
      {
        headerName: 'Module Type',
        field: 'module_type',
        width: 170,
        floatingFilter: true,
      },
      {
        headerName: 'Vanning From',
        field: 'vanning_from',
        width: 170,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,
      },
      {
        headerName: 'Vanning To',
        field: 'vanning_to',
        width: 170,
        floatingFilter: true,
        tooltipField: 'userid',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,
      },
      {
        headerName: 'No of Vehicles Vanned',
        field: 'total_veh_qty',
        width: 200,
        floatingFilter: true,
        // tooltipField: 'userid',
        // cellRendererFramework: NoofVehiclesVannedEditorComponent,
        // cellRendererParams: { column: 'veh_vanned' },
      },
      {
        headerName: 'No of Modules',
        field: 'total_module_qty',
        width: 200,
        floatingFilter: true,
        tooltipField: 'userid',
      },
      {
        headerName: 'Calculated Pitch',
        field: 'est_stocking_pace',
        width: 150,
        floatingFilter: true,
        tooltipField: 'userid',
      },
      // {
      //   headerName: 'User Id',
      //   field: 'to_char',
      //   width: 150,
      //   floatingFilter: true,
      //   headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
      //   // comparator: this.dateComparator,
      // },
      {
        headerName: 'Updated DT',
        field: 'updateddt',
        width: 150,
        floatingFilter: true,
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        // comparator: this.dateComparator,
      },
    ];
    // this.gridOptions = {
    //   onSelectionChanged: this.onSelectionChanged.bind(this)
    // };
    // this.setPageSizeToAll(this.defaultPageSize);

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.stockingPaceSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
    // this.rowData = [
    //   {
    //     dock: 'm3',
    //     veh_vanned: '200',
    //   },
    //   {
    //     dock: 'm4',
    //     veh_vanned: '300',
    //   },
    // ];
  }

  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('userName');

  onSelectionChanged() {
    throw new Error('Method not implemented.');
  }

  warningMessage: string;
  successMessage: string;

  removeMessage() {
    this.successMessage = '';
    this.warningMessage = '';
  }

  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onEditMode() {
    this.removeMessage();
    let data = [];

    // if (this.uploadEnabled) {
    //   return;
    // }
    data = this.getModifiedRowData();
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (!rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      //this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();

    this.selectedDock = [];
    this.selectedModuleType = [];
    this.selectedVanMonth = [];
    this.appendDockModuleData(this.dockModuleList);
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];

    this.offset = 0;
  }

  onSearch(offset) {
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      // const { valid, error } = validateVanningDates({
      //   vanningFrom: this.vanningFrom,
      //   vanningTo: this.vanningTo,
      // });

      // if (!valid) {
      //   this.warningMessage = error;
      //   window.scroll(0, 0);

      //   this.spinner.hide();

      //   return;
      // }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.rundownSearchError;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let selectedDock;
    let selectedModuleType;
    let selectedVanMonth;

    // if (this.selectedKanban.length > 0) {
    //   if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
    //     kanban = [];
    //     this.selectedKanban.forEach((element) => {
    //       kanban.push(element.kanban);
    //     });
    //   }
    // }

    // if (this.selectedPartNo.length > 0) {
    //   if (!this.selectedPartNo.some((a) => a.item_id === 'ALL')) {
    //     selectedPartNo = [];
    //     this.selectedPartNo.forEach((element) => {
    //       selectedPartNo.push(element.item_id);
    //     });
    //   }
    // }

    // if (this.selectedDestCode.length > 0) {
    //   if (!this.selectedDestCode.some((a) => a.dest_code === 'ALL')) {
    //     selectedDestCode = [];
    //     this.selectedDestCode.forEach((element) => {
    //       selectedDestCode.push(element.dest_code);
    //     });
    //   }
    // }

    // if (this.selectedContainer.length > 0) {
    //   if (!this.selectedContainer.some((a) => a.cont_code === 'ALL')) {
    //     selectedContainer = [];
    //     this.selectedContainer.forEach((element) => {
    //       selectedContainer.push(element.cont_code);
    //     });
    //   }
    // }

    // if (this.selectedSupplier.length > 0) {
    //   if (!this.selectedSupplier.some((a) => a.customer_supp === 'ALL')) {
    //     selectedSupplier = [];
    //     this.selectedSupplier.forEach((element) => {
    //       selectedSupplier.push(element.customer_supp);
    //     });
    //   }
    // }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }
    if (this.selectedModuleType.length > 0) {
      if (!this.selectedModuleType.some((a) => a.module_type === 'ALL')) {
        selectedModuleType = [];
        this.selectedModuleType.forEach((element) => {
          selectedModuleType.push(element.module_type);
        });
      }
      if (this.selectedVanMonth.length > 0) {
        if (!this.selectedVanMonth.some((a) => a.vanning_month === 'ALL')) {
          selectedVanMonth = [];
          this.selectedVanMonth.forEach((element) => {
            selectedVanMonth.push(element.vanning_month);
          });
        }
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,

      dock: selectedDock,
      module_type: selectedModuleType,
      vanning_month: selectedVanMonth,
    };

    this.stockingService.getStockingPaceData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }
  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  checkIfAnySelected() {
    if (this.selectedModuleType.length > 0 && this.selectedDock.length > 0 && this.selectedVanMonth.length > 0) {
      return true;
    }
    return false;
  }
  initializeData() {
    console.log('initialize data called');
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();

    //   this.setVanningDates();
    //   this.lookupService.getEasternDate().subscribe({
    //     error: this.errorCallback,
    //     next: (res) => {
    //       this.easternDate = res.body.data;
    //     },
    //   });
    // }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };
  appendDockModuleData(data) {
    

    data.forEach((item) => {
      if (!this.dockList.some((dockItem) => dockItem.dock === item.dock)) {
        this.dockList.push({ dock: item.dock });
      }
      this.moduleTypeList.push({ module_type: item.module_type });
    });
  }

  loadDrop() {
    // this.spinner.show();

    // const data = {
    //   business_entity: this.namcValue,

    //   workid: this.user,
    //   user_role: this.userRole,
    // };

    const lookupSubscriptions = [this.stockingService.getDockAndModuleType(), this.stockingService.getVanMonth()];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];
        this.vanMonthList = [];
        this.dockModuleList = [];
        this.moduleTypeList = [];
        this.dockList.push({ dock: 'ALL' });
        this.moduleTypeList.push({ module_type: 'ALL' });
        
        if (res[0].body != 'No Content') {
          this.dockModuleList.push(...res[0].body);
          this.appendDockModuleData(this.dockModuleList);
        }
        this.vanMonthList.push({ vanning_month: 'ALL' });
        if (res[1].body != 'No Content') {
          this.vanMonthList.push(...res[1].body);
        }

        //if item id list, add ALL option
        // if (res[1].body.data) {
        //   this.partNoList.push({ item_id: 'ALL' });
        // }
        // if (res[2].body.data) {
        //   this.destCodeList.push({ dest_code: 'ALL' });
        // }
        // if (res[4].body.data) {
        //   this.containerList.push({ cont_code: 'ALL' });
        // }
        // if (res[3].body.data) {
        //   this.supplierList.push({ customer_supp: 'ALL' });
        // }
        // if (res[6].body.data) {
        //   this.dockList.push({ dock: 'ALL' });
        // }
        // if (res[7].body.data) {
        //   this.adjList.push({ adj_type: 'ALL' });
        // }
        // if (res[5].body.data) {
        //   this.specialistList.push({ EMPLOYEE: 'ALL' });
        // }

        // this.partNoList.push(...res[1].body.data);
        // this.destCodeList.push(...res[2].body.data);
        // this.containerList.push(...res[4].body.data);
        // this.supplierList.push(...res[3].body.data);

        // this.specialistList.push(...res[5].body.data);
        // this.dockList.push(...res[6].body.data);
        // this.adjList.push(...res[7].body.data);

        // this.adjList = parseAdjustmentType(this.adjList);

        // // set vanning dates in UI dropdowns
        // const vanningDatesData = res[8].body.data.minVanDate[0].vanning_date;

        // if (!vanningDatesData) {
        //   this.spinner.hide();
        //   return;
        // }

        // this.vanningFrom = res[8].body.data.minVanDate[0].vanning_date;
        // // this.vanningTo = res[8].body.data.minVanDateTo[0].vanning_date;

        // this.onInitVanningFrom = this.vanningFrom;

        // const { adjs_columns } = res[9].body.showHideColumnsResponse;

        // try {
        //   const hideItems = JSON.parse(adjs_columns);

        //   if (typeof hideItems === 'object' && hideItems.length > 0) {
        //     this.selectedShowHideList = this.showHideList.filter(
        //       (sItem) =>
        //         !hideItems.map((item) => item.value).includes(sItem.value)
        //     );

        //     // logData(hideItems);

        //     this.onShowHideChange({
        //       value: this.selectedShowHideList,
        //       onInit: true,
        //     });
        //   }
        // } catch (error) {}

        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  dockChange(): void {
    this.disablemodule = true;

    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
        this.moduleTypeList = [];
        this.moduleTypeList.push({ module_type: 'ALL' });
        this.dockModuleList.forEach((item: any) => {
          this.moduleTypeList.push({ module_type: item.module_type });
        });
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');
        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
      this.disablemodule = false;
    }

    if (this.selectedDock[0].dock != 'ALL') {
      this.moduleTypeList = [];
      this.selectedModuleType = [];
      this.moduleTypeList.push({ module_type: 'ALL' });
      this.dockModuleList.forEach((item: any) => {
        if (this.selectedDock.some((s_dock) => s_dock.dock === item.dock)) {
          this.moduleTypeList.push({ module_type: item.module_type });
        }
      });
      this.disablemodule = false;
    }
  }

  moduleChange(): void {
    if (this.selectedModuleType.length >= 1) {
      if (this.selectedModuleType[this.selectedModuleType.length - 1].module_type === 'ALL') {
        this.selectedModuleType = [];
        this.selectedModuleType.push(this.moduleTypeList[0]);
      } else {
        let indexAll = this.selectedModuleType.findIndex((data) => data.module_type === 'ALL');
        if (indexAll > -1) {
          this.selectedModuleType.splice(indexAll, 1);
        }
      }
    }
  }

  vanMonthChange() {
    if (this.selectedVanMonth.length >= 1) {
      if (this.selectedVanMonth[this.selectedVanMonth.length - 1].vanning_month === 'ALL') {
        this.selectedVanMonth = [];
        this.selectedVanMonth.push(this.vanMonthList[0]);
      } else {
        let indexAll = this.selectedVanMonth.findIndex((data) => data.vanning_month === 'ALL');
        if (indexAll > -1) {
          this.selectedVanMonth.splice(indexAll, 1);
        }
      }
    }
  }
  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  // onDockChange() {
  //   // Clear the existing moduleTypeList
  //   this.moduleTypeList = [];

  //   // Iterate over the dockModuleList and filter based on selected dock
  //   this.dockModuleList.forEach((element) => {
  //     // Check if the element's dock matches the selectedDock
  //     if (element.dock === this.selectedDock) {
  //       // If a match is found, push the corresponding module_type to moduleTypeList
  //       this.moduleTypeList.push({ module_type: element.module_type });
  //     }
  //   });

  //   // Optionally, log the updated moduleTypeList for debugging
  //   console.log('Updated Module Type List:', this.moduleTypeList);
  // }

  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api
    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              dock: e.dock,
              module_description: e.module_description,
              module_type: e.module_type,
              vanning_from: e.vanning_from,
              vanning_to: e.vanning_to,
              total_veh_qty: e.total_veh_qty,
              total_module_qty: e.total_module_qty,
              est_stocking_pace: e.est_stocking_pace,
              // user_id: e.user_id,
              updated_dt: e.updated_dt,
            };
            exportData.push(obj);
          });

          const headers = [
            'DOCK',
            'MODULE DESCRIPTION',
            'MODULE TYPE',
            'VANNING FROM',
            'VANNING TO',
            'NO OF VEHICLES VANNED',
            'NO OF MODULES',
            'CALCULATED PITCH',
            'UPDATED DT',
          ];

          const timestamp = easternCurrentDate
            .split('.')[0]
            .replace('T', '_')
            .split('')
            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(exportData, 'Stockingpaceinquiry_' + timestamp, headers);
        }
        this.spinner.hide();
      }, 1000);
    }
  }
  consoleLog(element, console_for) {
    console.log(element, 'ms-1 - ' + console_for);
  }
}
