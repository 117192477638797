import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as mm from 'moment-timezone';

import { forkJoin, Subscription } from 'rxjs';
import { LookupService } from '../services/lookup/lookup.service';
import { checkAccess, dateFilterComparator, resetSortingAndFilters, validateVanningDates } from '../constants';

import { ProdProgressFinalEditComponent } from './edit-dialog/progress-grid-final-edit.component';

import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';

import { OSPStateService } from '../services/osp-state.service';
import { ProdprogressService } from '../services/production-progress/production-progress.service';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { freezingMesssage } from '../constants';
import { ProgressEditDialogComponent } from './edit-dialog/edit-dialog.component';
import { warningMessage, successMessage } from '../constants';
import { logData, logErrors } from '../shared/logger';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, IDetailCellRendererParams } from 'ag-grid-community';
import { updateDevanPlanComponent } from './confirmation-popup/delete-airorder/update-devanplan.component';
import { SetupActionsComponent } from '../core/setup-actions/setup-actions.component';
import { editCumulplanComponent } from './edit-dialog/edit-cumul-plannet.component';
import { EditCumulPlanNetComponent } from './edit-dialog/edit-popup/cumul-plannet-popup.component';
import { ProdProgressIntvolEditComponent } from './edit-dialog/progress-grid-intvol-edit-component';
import { recalculateComponent } from './recalculate/recalculate-component';

@Component({
  selector: 'app-production-progress-maintenance',
  templateUrl: './production-progress.component.html',
  styleUrls: ['./production-progress.component.scss'],
})
export class ProductionProgressComponent implements OnInit, OnDestroy {
  showDownloadUploadButtons: boolean = true;

  namcValue = '01';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'custom';
  public groupDefaultExpanded = 1;
  rowData: any = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;

  public defaultColDef;
  rowSelection;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  public detailCellRendererParams;
  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = {
    customTooltip: CustomTooltipComponent,
    buttonRenderer: editCumulplanComponent,
    buttonRenderer2: recalculateComponent,

  };
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  isFinalupdated: string ="false";
  public themeClass: string =
    "ag-theme-balham";

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanListSelected = [];
  // Dropdown Options
  prodDate: any;
  prodDateTo: any;
  onInitprodDate: any;
  onIntprodDateTo: any;


  dockList: any = [];
  specialistList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;
  treeData

  showHideList: any = [
    // { value: 'vanning_date', label: 'Vanning DT' },
    { value: 'production_date', label: 'Production Date' },
    { value: 'vehicle_type', label: 'Module Type' },
    { value: 'shift', label: 'Shift' },
    { value: 'plan_vol', label: 'Plan Vol' },
    { value: 'int_plan_vol', label: 'Int Plan Vol' },
    { value: 'ALC', label: 'ALC' },
    { value: 'Final', label: 'final' },
    { value: 'plan_net', label: 'Plan Net' },
    { value: 'cumul_plan_net', label: 'Cumul Plan Net' },
    { value: 'date_time', label: 'Updated DT' },
    { value: 'userid', label: 'User' },
    { value: 'comment', label: 'Comments' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns

  selectedLinePath: any = [];

  // Dropdown Options
  linePathList: any = [];

  page = 0;
  apiDefaultLimit = 5000;
  icons: { groupExpanded: string; groupContracted: string };
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;

  minVanDate: any;
  maxVanDate: any;

  uploading: boolean = false;



  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;

  flalSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  onInitVanningFrom: any;
  onInitVanningTo: any;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  flalLicenseKeySubscription$: Subscription;
  animateRows: boolean;
  groupUseEntireRow: boolean;
  spinnerMessage: any;
  modalComponent: string;
  modifiedRowData: any;
  selectedLine: any;
  selectedModuleType: any;
  lineList: any[];
  moduleTypeList: any[];
  prodProgressSubscription$: Subscription;
  userName: string;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService,
    private readonly prodProgressService: ProdprogressService,
    private readonly stateService: OSPStateService,
    public dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }


  fileName: string = '';
  access1;



  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    if (this.flalSubscription$) this.flalSubscription$.unsubscribe();
    if (this.flalLicenseKeySubscription$) this.flalLicenseKeySubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };
    this.icons = {
      groupExpanded:
        '<img src="/assets/images/cellgrid_minus.png" style="height: 18px; width: 18px;padding-right: 2px"/>',
      groupContracted:
        '<img src="/assets/images/cellgrid_plus.png" style="height: 14px; width: 14px;padding-right: 2px"/>',
    };

    this.gridOptions.defaultColDef.floatingFilter = true;
    // this.detailCellRendererParams = {
    //   detailGridOptions: {
    //     columnDefs: [
    //       { headerName: 'Plan Lo DT', width: 100, field: 'plan_lodate' },
    //       { headerName: 'Plan Lo QTY', field: 'plan_loqty' },
    //     ],
    //     defaultColDef: {
    //       flex: 1,
    //     },
    //     masterDetail: true,
    //     detailRowHeight: 25, // Default height for rows
    //     detailRowAutoHeight: true,
    //     getRowHeight: function (params) {
    //       // If the row has child data
    //       if (params.node && params.node.detail) {
    //         const childData = params.node.data.children; // Assuming 'children' is the field with child data
    //         if (childData && childData.length > 0) {
    //           return 500; // Adjust this height if there are child rows
    //         }
    //       }
    //       // Return 0 if there are no child data
    //       return 0; // This will collapse the row if no child data exists
    //     },
    //     getRowClass: function (params) {
    //       // Check if the row has children and is a group node
    //       if (!params.node.parent.group && !params.node.data.children) {
    //         return 'no-expand'; // This class could hide the row, or you can use it to modify styles
    //       }
    //       return ''; // Default style when there is data
    //     },
    //   },

    //   getDetailRowData: (params) => {
    //     params.successCallback(params.data.children);
    //   },
    // } as IDetailCellRendererParams;

    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { headerName: 'Plan Lo DT', field: "plan_lodate", flex: 1 },
          { headerName: 'Plan Lo QTY', field: "plan_loqty", flex: 1 }
        ],
        defaultColDef: {
          flex: 1,  // Make columns flexible
        },
        masterDetail: true,
        detailRowHeight: 25,
        detailRowAutoHeight: true,
        getRowHeight: function (params) {
          if (params.node && params.node.detail) {
            return 500; // Adjust this value for detail rows as needed
          }
          return 50; // Adjust this value for regular rows
        },
        getRowClass: function (params) {
          if (!params.node.parent.group) {
            return 'no-expand';
          }
          return '';
        },
        getContextMenuItems: (params) => {
          return [
            'copy',
            'paste',
            'separator',
            {
              name: 'Custom Action',
              action: () => {
                alert('Custom Action triggered!');
              },
            },
          ];
        },
        rowData: [], // Ensure this is populated with correct data
      },
      getDetailRowData: (params) => {
        if (params.data && params.data.children) {
          params.successCallback(params.data.children); // Ensure you pass the correct data
        }
      },
    } as IDetailCellRendererParams;



    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 40,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: '',
        sortable: false,
        width: 50,
        cellRenderer: 'buttonRenderer2',

        cellRendererParams: {
          label: 'Click 2',
        },
        pinned: 'left',


      },
      {
        headerName: 'Prod DT',
        field: 'actual_date',
        sortable: true,
        width: 130,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,


        tooltipField: 'actual_date',
        headerTooltip: 'Production Date (mm/dd/yyyy)',

        cellRenderer: 'agGroupCellRenderer',
      },
      // {
      //   headerName: 'Module Type',
      //   field: 'module_type',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,
      //   tooltipField: 'module_type',
      //   headerTooltip: 'Module Type',


      // },
      {
        headerName: 'Shift',
        field: 'actual_shift',
        sortable: true,
        width: 80,
        floatingFilter: true,
        tooltipField: 'actual_shift',
        headerTooltip: 'Actual Shift',


      },
      {
        headerName: 'Plan VOL',
        field: 'plan_volume',
        sortable: true,
        width: 100,
        floatingFilter: true,
        headerTooltip: 'Plan Volume',
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Int Plan VOL',
        field: 'intPlanVol',
        sortable: true,
        width: 120,
        floatingFilter: true,
        cellRendererParams: { column: 'int_plan_volume' },
        cellRendererFramework: ProdProgressIntvolEditComponent,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.int_plan_volume;
          } else {
            const value = params.data.intPlanVol?.value ?? params.data.int_plan_volume;

            return value;
          }
        },

        headerTooltip: 'Internal Plan Volume',
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },

      },
      {
        headerName: 'ALC',
        field: 'alc_result',
        sortable: true,
        width: 100,
        floatingFilter: true,
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Final',
        field: 'Final',
        sortable: true,
        width: 90,
        floatingFilter: true,
        cellRendererFramework: ProdProgressFinalEditComponent,
        cellRendererParams: { column: 'final' },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.final;
          } else {
            const value = params.data.Final?.value ?? params.data.final;

            return value;
          }
        },

        filter: 'agNumberColumnFilter',


      },
      {
        headerName: 'Plan Net',
        field: 'plan_net',
        sortable: true,
        width: 90,
        floatingFilter: true,
        tooltipField: 'plan_net',
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.plan_net;
          } else {
            const value = params.data.Final?.value ?? params.data.plan_net;

            return value;
          }
        },

        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Cumul Plan Net',
        field: 'cumul_plan_net',
        sortable: true,
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        tooltipField: 'cumul_plan_net',
        headerTooltip: 'Cumulative Plan Net',
      },
      {
        headerName: '',
        sortable: false,
        width: 50,
        cellRenderer: 'buttonRenderer',

        cellRendererParams: {
          label: 'Click 2',
        },

      },



      // {
      //   headerName: 'Plan LO Date',
      //   field: 'plan_lo_dt',
      //   sortable: true,
      //   width: 130,
      //   floatingFilter: true,

      // },
      // {
      //   headerName: 'Plan LO QTY',
      //   field: 'plan_lo_qty',
      //   sortable: true,
      //   width: 130,
      //   floatingFilter: true,

      // },
      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'userid',
        sortable: true,
        width: 180,
        floatingFilter: true,
        tooltipField: 'userid',

      },
      {
        headerName: 'Updated DT',
        field: 'updated_datetime',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        sortable: true,
        width: 160,
        floatingFilter: true,
        tooltipField: 'updated_datetime',
      },



    ],    // Define the raw data with a custom structure


      this.headerHeight = 45;
    this.setPageSizeToAll();
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.prodProgressSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });


  }



  initializeData() {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.spinner.show();
    this.rowData = [];

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }



    this.loadDrop();

  }



  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    const dataShowHide = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };


    const lookupSubscriptions = [
      // this.prodProgressService.getLine(data),
      // this.prodProgressService.getModuletype(data),
      this.prodProgressService.getDock(data),

    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = []
        if (res[0]?.body) {
          this.dockList.push(...res[0].body)
        }




        this.spinner.hide();
      },

      error: this.errorCallback,
    });

    this.spinner.hide();

  }


  onSearch(offset) {
    // this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;


    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);


      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch2;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let selectedDock;
    let selectedModuleType
    let selectedLine

    // if (this.selectedModuleType.length > 0) {
    //   if (!this.selectedModuleType.some((a) => a.module_type === 'ALL')) {
    //     selectedModuleType = [];
    //     this.selectedModuleType.forEach((element) => {
    //       selectedModuleType.push(element.kanban);
    //     });
    //   }
    // }

    // if (this.selectedLine.length > 0) {
    //   if (!this.selectedLine.some((a) => a.line === 'ALL')) {
    //     selectedLine = [];
    //     this.selectedLine.forEach((element) => {
    //       selectedLine.push(element.line);
    //     });
    //   }
    // }






    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }




    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: [this.selectedDock.dock],
      // moduleType: selectedModuleType,
      // line: selectedLine,
      prod_date_from: this.prodDate,
      prod_date_to: this.prodDateTo
    };

    this.prodProgressService.getProdProgressData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  checkIfAnySelected() {
    // return true
    console.log("this.prod", this.prodDate)

    if (
      this.selectedDock.length != 0 &&
      this.prodDate
    ) {
      return true;
    }

    return false;
  }
  hideSpinner() {
    this.spinner.hide();
  }
  // onLineChange(): void {
  //   if (this.selectedLine.length >= 1) {
  //     this.selectedLine = {
  //       line: [this.selectedLine.line],
  //     };
  //   }
  // }
  // onModuleTypeChange(): void {
  //   if (this.selectedModuleType.length >= 1) {
  //     this.selectedModuleType = {
  //       module_type: [this.selectedModuleType.line],
  //     };
  //   }
  // }


  onEditMode() {
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);


    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  ReviewChanges() {
    this.removeMessage();

    this.modifiedRowData = this.getModifiedRowData();
    console.log('editedRecords', this.modifiedRowData);
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.Updated);


    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    console.log('value',this.modifiedRowData.intPlanVol)



    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';

        // validation


        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  reviewData() {
    this.openReviewDialog(this.modifiedRowData);
  }

  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ProgressEditDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          var updatedData = [];
          console.log(data.modifiedRowData)
          data.modifiedRowData.forEach((e) => {
            var updatedFields = {
              actual_date:e.actual_date,
              cumul_plan_net:e.cumul_plan_net,
              plan_volume:e.plan_volume,
              plan_net:e.plan_net,
              final:e.final,
              actual_shift:e.actual_shift,
              alc_result:e.alc_result,
              dock:e.dock,
              int_plan_volume:e.int_plan_volume,
              userid: localStorage.getItem('UserName'),
            };


            updatedData.push(updatedFields);

          });

          this.spinner.show();
          this.isFinalupdated =localStorage.getItem('isFinal')
          console.log("this.isFinalupadated ",this.isFinalupdated )
          if(this.isFinalupdated === 'true'){
            console.log("inside if")
            this.spinner.hide();
            return;
          }
          
          this.editProdProgress(updatedData);


        }
      });

    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }

  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    dialogConfig.data = 'ospipe'
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        // this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  updatedRows=[];
  editProdProgress(updatedData) {
    var data1 = {
      business_entity: this.namcValue,
      userid: this.userName,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
    };
    console.log("data1",data1)
    this.spinner.show();
    this.prodProgressService.IntplanVolUpdate(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        console.log('response.body.data', response);

        const  updatedRows  = response.body.data;
        this.updatedRows.push(...updatedRows);


        if (updatedData.length > this.editApiEndPosition) {
          this.timesEditApiCalled++;

          this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

          this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
          return this.editProdProgress(updatedData);
        }

        this.spinner.hide();
        this.timesEditApiCalled = 0;
        this.editApiStartPosition = 0;
        this.editApiEndPosition = this.editApiDefaultLimit;

        if (updatedRows.length > 0) {
          this.successMessage = successMessage.recordUpdated;
          this.rowData = [];
          this.spinner.hide();
          this.rowData = this.updatedRows;
          this.updatedRows = [];
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

          // this.rowData = updatedRows;
          this.rowData = [];
          this.spinner.hide();
          this.rowData = updatedRows;
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);


          
        } else {
          this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
        }

        window.scroll(0, 0);
        this.setPageSizeToAll();

        // this.onSearch(0, false);
      },
    });
  }
  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }



  // saveShowHide() {
  //   let shift_column = this.shift_column;
  //   const data = {
  //     business_entity: this.namcValue,
  //     business_entity_name: this.namcName,
  //     workid: this.user,
  //     user_role: this.userRole,
  //   };

  //   if (shift_column) {
  //     this.lookupService.updateUserShowHideColumns(shift_column).subscribe({
  //       error: this.errorCallback,
  //       next: (res) => {
  //         this.spinner.hide();
  //       },
  //     });
  //   } else {
  //     this.spinner.hide();
  //   }
  // }



  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  getModifiedRowData(): any {
    console.log(this.rowData)
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  getSelectedRows(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedModuleType = [];
    this.selectedLine = [];

    this.kanbanSearchValue = null;


    this.prodDate = this.onInitprodDate;
    this.prodDateTo = this.onIntprodDateTo;


    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }
  Recalculate(data){
    const exportData = [];
    // exportData.push(data);
    exportData.push({ ...data, isRecalculate: true });


    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '200px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData
    const dialogRef = this.dialog.open(updateDevanPlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          };
        }


      }
    })
      ;


  }
  editData(data){
    console.log("inside main component",data)
    const exportData = [];
    exportData.push(data);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '300px';
    dialogConfig.width = '600px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(EditCumulPlanNetComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        return;

  }})}
























  convertNaN(value) {
    value = Math.round(parseFloat(value?.trim()));
    return isNaN(value) ? '' : value;
  }





  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    //this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  // showSpinner(message?: string) {
  //   if (!message) {
  //     this.spinnerMessage = this.spinnerMessage;
  //   } else {
  //     this.spinnerMessage = message;
  //   }
  // }
  fa_columns
  saveShowHide() {
    this.spinner.show();
    let fa_columns = this.fa_columns;
    if (fa_columns) {
      this.lookupService.updateUserShowHideColumns({ fa_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }
  exportAsXLSX(): void {
    this.groupDefaultExpanded = -1
    this.spinner.show();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let data = [];
    if (this.headerCheckboxChecked) {
      console.log('Hello');
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getSelectedRowData();
    }


    setTimeout(async () => {
      if (data.length > 0) {
        const exportData: any = [];

        data.forEach((e) => {
          const obj = {
            production_date: e.actual_date,
            vehicle_type: e.vehicle_type,
            shift: e.shift,
            plan_vol: e.plan_volume,
            int_plan_vol: e.int_plan_volume,
            alc: e.alc_result,
            final: e.final,
            plan_net: e.plan_net,
            cumul_plan_net: e.cumul_plan_net,
            updated_userid: e.updated_userid,
            updated_date_time: e.updated_datetime,
          };
          exportData.push(obj);
        });
        const headers = [
          'Production date',
          'Module Type',
          'Plan Vol',
          'Int Plan Vol',
          'ALC',
          'Final',
          'Plan Net',
          'Cumul Plan Net',
          'User',
          'Updated DT',
        ];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        if (exportData.length > 0) {
          this.excelService.exportAsExcelFile(exportData, 'ProductionProgress' + timestamp, headers);
        }
      } else {
        this.warningMessage = warningMessage.noRowsSelected;
      }

      this.hideSpinner();
    }, 1000);
  }

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  onDockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }



  updateDevanPlan() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '200px';
    dialogConfig.width = '470px';
    dialogConfig.data = 'ospipe'
    const dialogRef = this.dialog.open(updateDevanPlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          };
        }


      }
    })
      ;
  }





}


