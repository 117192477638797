import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'app-devanbuttonrender',
  template: `
  <span *ngIf="isPastDate">
  <img class="detailiconhome" style="margin-right: 2px;" (click)="editData('value')"
  src="./assets/images/Edit_icon.svg" title="edit" alt="Edit">
</span>

  `,
  styles: [
    `
      .badge {
        width: 94px;
        font-size: 12px;
        border-radius: 35px;
        font-weight: 600;
        cursor:pointer;

      }
      .complete {
        color: #000000;
        border: 1px solid #000000;
        cursor:pointer;

      }
      .badgeerror {
        background-color: #000000;
        border: 1px solid #000000;
        cursor:pointer;

      }
    `,
  ],
})
export class editCumulplanComponent implements ICellRendererAngularComp {
  params;
  isPastDate:boolean ;
  reportdate = new Date();


  agInit(params): void {
    this.params = params;
    console.log("devannutton", params);
    // this.label="DEVAN";
    console.log("this.reportdate",this.reportdate)
    let date2= moment(this.reportdate).format('MM/DD/YYYY');
    console.log("date",date2)


    this.params = params;

    let date = moment(this.reportdate).format('MM/DD/YYYY');  // Today's date formatted as 'MM/DD/YYYY'
    console.log("Today's Date:", date);
    
    // Assuming 'this.params.data.actual_date' is also a date in 'MM/DD/YYYY' format
    let actualDate = moment(this.params.data.actual_date, 'MM/DD/YYYY'); // Convert actual_date to moment object
    
    // Compare the two dates
    if (actualDate.isBefore(moment(date, 'MM/DD/YYYY'), 'day') && !this.params.data.checkedEditMode 
    && this.params.data.actual_shift == '1') {
        this.isPastDate = true; // If actual_date is before today's date, set isPastDate to true
    } else {
        this.isPastDate = false; // Otherwise, set it to false (future or today's date)
    }
    
    console.log("isPastDate:", this.isPastDate);
    

  

  }

  refresh(params?: any): boolean {
    return true;
  }


  editData(value) {
    console.log("editdata")
    this.params.context.componentParent.editData(
      this.params.data
    );

  }
}
