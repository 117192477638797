import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      style="width:93px"
      type="date"
      [(ngModel)]="value"
      (keydown)="stopTyping()"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
export class GridShiftsDateEditorComponent {
  params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  disableDate = '';
  reportdate = new Date();

  constructor() {}

  agInit(params: any): void {
    this.params = params;

    if (params.column === 'toDate') {
      params.data.toDate = this;
      this.value = params.data.to_date;
      this.disableDate = moment(this.reportdate)?.format('MM/DD/YYYY');
      // Check if the value is empty or set to '12/31/2999'
      if (this.value === '12/31/2999' || !this.value) {
        this.isEmpty = true;
        this.value = '';  // Treat '12/31/2999' or empty as an empty string
      } else {
        this.isEmpty = false;
        this.value = moment(this.value).format('MM/DD/YYYY');
      }
    }

    if (params.column === 'fromDate') {
      params.data.fromDate = this;
      this.value = params.data.from_date;
      this.disableDate = moment(this.reportdate).format('MM/DD/YYYY');

      if (this.value) {
        this.isEmpty = false;
        this.value = moment(this.value).format('MM/DD/YYYY');
      } else {
        this.isEmpty = true;
      }
    }

    if (params.data.checkedEditMode) {
      this.showInput = false;
      this.value = moment(this.value).format('YYYY-MM-DD');
    } else {
      this.showInput = true;
      if (this.value) {
        this.value = moment(this.value).format('MM/DD/YYYY');
      }
    }
    // if (!this.params.data.checkedEditMode && this.value) {
    //   this.value = moment(this.value).format('MM/DD/YYYY');
    // }
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'toDate') {
      if (this.value !== this.params.data.toDate) {
        this.params.data.toDate = this.value;
        this.params.data.isToDate = true;
        this.params.data.to_date = this.params.data.toDate;
      }
      else {
        this.params.data.to_date = this.value;
      }
      this.params.data.to_date = moment(this.params.data.to_date).format('MM/DD/YYYY')
    } else if (this.params.column === 'fromDate') {
      if (this.value !== this.params.data.fromDate) {
        this.params.data.fromDate = this.value;
        this.params.data.isFromDate = true;
        this.params.data.from_date = this.params.data.fromDate;
      }
      else {
        this.params.data.from_date = this.value;
      }
      this.params.data.from_date = moment(this.params.data.from_date).format('MM/DD/YYYY')

    }
    
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  stopTyping() {
    return false;
  }
}
