import { Component, OnInit, Inject, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { Router } from '@angular/router';

@Component({
  selector: 'app-air-freight-actions',
  templateUrl: './air-freight-actions.component.html',
  styleUrls: ['./air-freight-actions.component.scss'],
})
export class AirFreightActionsComponent {
  editAccess: boolean = false;
  download: boolean = false;
  deleteAccess: boolean = false;
  params: any;
  master_id: any;
  master_name: any;
  /*editmode*/
  isEditMode: boolean = false;
  showIcon: boolean;

  constructor(public dialog: MatDialog, private router: Router) {}
  access;
  refresh(): boolean {
    return false;
  }
  agInit(params): void {
    this.params = params;
    
    this.showIcon = params.showIcon(params); // Call the function
  

  }

  ngOnInit(): void {}

  checkChanged() {
    console.log(this.params.node.key)
    
    this.params.context.componentParent.userCheckChanged(this.params.node.key);
  }
  deleteChanged() {
    this.params.context.componentParent.userDeleteChanged(this.params.node.rowIndex);
  }
  editData(params: any): void {
    
    console.log(this.params,'params')
    this.checkChanged()
   
  }
  deleteData() {
    this.deleteChanged();
  }
}
