
import { Component, OnInit, Inject, EventEmitter, ChangeDetectorRef, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PipeLookupService } from '../../services/pipe-lookup/pipe-lookup.service'; 
import { log } from 'console';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-module-type-edit-dialog',
  templateUrl: './module-type-edit-dialog.component.html',
  styleUrls: ['./module-type-edit-dialog.component.scss']
})

export class ModuleTypeEditDialogComponent implements OnInit{
    moduleTypeChanged = new EventEmitter();
    addMasterEventEmitter = new EventEmitter();
    addModuleMasterEventEmitter = new EventEmitter();
    editMasterEventEmitter = new EventEmitter();
    master_id: any;
    master_name: any;
    key_item: any;
    data_item: any;
    business_entity: any;
    new_key_item: any;
    cstatus: any;
    showaddpopup: boolean = false;
    showeditpopup: boolean = false;
    popupname: any;
    moduleType: any;
    moduleTypeDesc: any;
    line: any;
    dock: any;
    ospLinePath: any;
    trackingPoint: any;
    moduleTypeDockActive: any;
    dataItem: any;
    enableError: boolean = false;
    userForm: UntypedFormGroup;
    // selectedModuleType: any = [];
    selectedModuleType1: any = null;
    selectedModuleTypeAdd: any = null;
    moduleTypeToDockForpopup:any = [];
    newDockList:any = [];

    addOrEditform: UntypedFormGroup = this.formBuilder.group({
      dock: ['', Validators.required],
      module_type: ['', Validators.required],
      line: ['', Validators.required],
      linePathList: ['', Validators.required],
      tracking_point: ['', Validators.required],
      module_desc: ['', Validators.required],
      is_module_type_to_dock: ['', Validators.required],
    },
    );
    selectedModuleTypeText: any;
    parentData: any;
    lineList: any;
    filteredLineList: any[] = [];
    searchedValue: string = '';
    filteredTrackingPointList: any[] = [];
    filteredModuleTypeList: any[] = [];

    
    
    constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      private readonly dialogeRef: MatDialogRef<ModuleTypeEditDialogComponent>,
      private readonly formBuilder: UntypedFormBuilder,
      private cdr: ChangeDetectorRef,
      private readonly pipeLookupService: PipeLookupService
    ) {
      this.parentData = data;
    }



    get addOrEdit() {
      return this.addOrEditform.controls;
    }
  
    
    ngOnInit(): void {
      this.filteredLineList = [...this.parentData.lineList];
      this.filteredTrackingPointList = [...this.parentData.trackingPointList];
      this.filteredModuleTypeList = [...this.parentData.moduleTypeList];



      this.business_entity = localStorage.getItem('namcvalue');
      
      console.log("data---",this.data);
      
  
      if (this.data?.mode === 'edit') {

        if (this.data.data?.dock) {
          this.addOrEdit.dock.setValue(
            this.data.data.dock 
          );
          
        }
        if (this.data?.policy_name) {
          this.addOrEdit.policyName.setValue(this.data?.policy_name);
          // this.addOrEdit.masterId.disable();
          this.addOrEditform.controls.policyName.setValue(this.data?.policy_name);
        }
        if (this.data?.mkey) {
          this.addOrEdit.keyItem.setValue(this.data?.mkey);
        }
        if (this.data?.mdata) {
          this.addOrEdit.dataItem.setValue(this.data?.mdata);
        }
      }
    }

    submitted: boolean = false;
    addMasterDetails() {
      console.log("adddata---",this.addOrEditform,this.addOrEdit);
      
      if (this.addOrEditform.invalid) {
        this.submitted = true;
        return;
      }
  
      const addEmittedData = {
        dock: this.addOrEdit.dock.value.dock,
        module_type: this.addOrEdit.module_type.value.module_type,
        module_desc: this.addOrEdit.module_desc,
        line: this.addOrEdit.line.value.line,
        line_path: this.addOrEdit.linePathList.value.line_path,
        tracking_point: this.addOrEdit.tracking_point.value.tracking_point,
        is_module_type_to_dock: this.addOrEdit.is_module_type_to_dock.value.value,
      };
      console.log("addMaster--",this.addOrEdit,addEmittedData);
  
      this.addMasterEventEmitter.emit(addEmittedData);
      this.dialogeRef.close();
    }
    moduleMasterDetails() {
      console.log("moduleMasterDetails---",this.addOrEditform,this.addOrEdit);
      
      // if (this.addOrEditform.invalid) {
      //   this.submitted = true;
      //   return;
      // }
  
      const addModuleTypeEmittedData = {
        dock: this.addOrEdit.dock.value.moduleType,
      };
      // console.log("addMaster--",this.addOrEdit,addEmittedData);
  
      // this.addMasterEventEmitter.emit(addEmittedData);
      // this.dialogeRef.close();
    }
    editRowMasterDetails() {
      if (this.addOrEditform.invalid) {
        this.submitted = true;
        return;
      }
  
      const editEmittedData = {
        master_id: this.addOrEdit.masterId.value.master_id,
        master_name: this.addOrEdit.masterName.value.name,
        description: this.addOrEdit.description.value,
        data_item: this.addOrEdit.dataItem.value,
      };
      this.editMasterEventEmitter.emit(editEmittedData);
      this.dialogeRef.close();
      
    }
  
    callRespectiveFunction() {
      if (this.data?.mode === 'edit') {
        this.editRowMasterDetails();
      } else {
        console.log("add function is called");
        
        this.addMasterDetails();
      }
    }
    onKeyPress(event: KeyboardEvent) {
      const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
      const isNumber = /^[0-9]$/.test(event.key);
  
      // Allow number keys, allowed keys, and prevent default for others
      if (!isNumber && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    }

    moduleTypeChange()
    {
      console.log(this.parentData.dockList);
      console.log(typeof this.parentData.dockList);

      let data = {
        "module_type" : this.selectedModuleTypeAdd,
      }
      // let res = this.pipeLookupService.getActiveSelectionModuleToDock(data);
      console.log('Selected Module Type:', this.selectedModuleTypeAdd);

      const lookupSubscriptions = [
        this.pipeLookupService.getActiveSelectionModuleToDock(this.selectedModuleTypeAdd),
      ];
      forkJoin(lookupSubscriptions).subscribe({
        next: (res) => {
          const count = parseInt(res[0]['body'][0].count);
          console.log(count);
          if(count > 0)
          {
            console.log(count);
            this.moduleTypeToDockForpopup = [{value: 'No'}];
          }
          else
          {
            this.moduleTypeToDockForpopup = [{value: 'Yes'}, {value: 'No'}];
          }
          if(res[0]['body'] != "No Content" && res[0]['body'] != '')
          {
            // Extract the dock values from sql_result
            const docksToRemove = res[0]['body'].map(item => item.dock);
            console.log(docksToRemove);

            // Remove those docks from this.parentData.dockList
             this.newDockList = this.parentData.dockList.filter(dock => !docksToRemove.includes(dock.dock));
            console.log(this.newDockList);
          }
          else 
          {
            this.newDockList = this.parentData.dockList;
            console.log(this.newDockList);
          }

        },
        // error: this.errorCallback,
      });
    }
  
  onFilter(event: any) {
    const query = event.filter;
    this.searchedValue = query;
  
    // Check if the query length is exactly 1 character
    if (query.length === 1) {
      // Check if the searched value exists in the list
      const isExisting = this.parentData.lineList.some(line => line.line.toLowerCase() === query.toLowerCase());
  
      if (!isExisting) {
        // If the searched value doesn't exist, add it temporarily to the list with a new class
        this.filteredLineList = [
          ...this.parentData.lineList,
          { line: query, isNew: true, newItemClass: 'new-item' } // Add new item with `newItemClass` for styling
        ];
      } else {
        // If the searched value exists, just filter the list based on the query
        this.filteredLineList = this.parentData.lineList.filter(line =>
          line.line.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else {
      // If the query is not a single character, reset the filtered list to show only valid items with one character in `line`
      this.filteredLineList = this.parentData.lineList.filter(line => line.line.length === 1);
    }
  }

  onFilterTrackingPoint(event: any) {
    const query = event.filter;
    this.searchedValue = query;
  
    // Check if the query length is 2 characters
    if (query.length === 2) {
      // Check if the searched value exists in the list
      const isExisting = this.parentData.trackingPointList.some(tracking_point => tracking_point.tracking_point.toLowerCase() === query.toLowerCase());
  
      if (!isExisting) {
        // If the searched value doesn't exist, add it temporarily to the list with a new class
        this.filteredTrackingPointList = [
          ...this.parentData.trackingPointList,
          { tracking_point: query, isNew: true, newItemClass: 'new-item' } // Add new item with `newItemClass` for styling
        ];
      } else {
        // If the searched value exists, just filter the list based on the query
        this.filteredTrackingPointList = this.parentData.trackingPointList.filter(tracking_point =>
          tracking_point.tracking_point.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else if (query.length === 1) {
      // If the query length is exactly 1 character, filter the list to show only items starting with that character
      this.filteredTrackingPointList = this.parentData.trackingPointList.filter(tracking_point =>
        tracking_point.tracking_point.toLowerCase().startsWith(query.toLowerCase()) // Matches items starting with the query
      );
    } else {
      // If the query is not a 1-character or 2-character string, reset the filtered list to show valid items with two characters in `tracking_point`
      this.filteredTrackingPointList = this.parentData.trackingPointList.filter(tracking_point => tracking_point.tracking_point.length === 2);
    }
  }

  
  onFilterModuleType(event: any) {
    const query = event.filter;
    this.searchedValue = query;
  
    // Check if the query length is 2 characters
    if (query.length === 3) {
      // Check if the searched value exists in the list
      const isExisting = this.parentData.moduleTypeList.some(module_type => module_type.module_type.toLowerCase() === query.toLowerCase());
  
      if (!isExisting) {
        // If the searched value doesn't exist, add it temporarily to the list with a new class
        this.filteredModuleTypeList = [
          ...this.parentData.moduleTypeList,
          { module_type: query, isNew: true, newItemClass: 'new-item' } // Add new item with `newItemClass` for styling
        ];
      } else {
        // If the searched value exists, just filter the list based on the query
        this.filteredModuleTypeList = this.parentData.moduleTypeList.filter(module_type =>
          module_type.module_type.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else if (query.length === 1 || query.length === 2) {
      // If the query length is exactly 1 character, filter the list to show only items starting with that character
      this.filteredModuleTypeList = this.parentData.moduleTypeList.filter(module_type =>
        module_type.module_type.toLowerCase().startsWith(query.toLowerCase()) // Matches items starting with the query
      );
    } else {
      // If the query is not a 1-character or 2-character string, reset the filtered list to show valid items with two characters in `tracking_point`
      this.filteredModuleTypeList = this.parentData.moduleTypeList.filter(tracking_point => tracking_point.tracking_point.length === 2);
    }
  }
}
