<div *ngIf="data">
  <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3 class="head" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
        {{ data.mode | uppercase }} INVENTORY POLICY
      </h3>
    </div>

    <div class="col-sm-1">
      <button type="button" class="btn btn-dark btn-circle btn-xl" [mat-dialog-close]="true" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <form [formGroup]="addOrEditform">
    <div class="row">
      <div class="col-md-6">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">DOCK </label>

          <p-dropdown id="selSupplier" [options]="data.dockList" [filter]="true" [virtualScroll]="true" [itemSize]="30"
            [showTransitionOptions]="'0ms'" placeholder="Select" optionLabel="dock" formControlName="dock" (onChange)="onDockChange()"
            [resetFilterOnHide]="true" [hideTransitionOptions]="'0ms'" autoWidth="true" [style]="{ width: '100%' }"
            appendTo="body" scrollHeight="180px">
          </p-dropdown>
          <ng-container *ngIf="addOrEdit.dock.errors && submitted">
            <small *ngIf="addOrEdit.dock.errors?.required">
              Dock is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="drpchildctrl">
          <label class="searchLable">PART NO</label>

          <p-multiSelect id="selSupplier" [options]="partNoList" [filter]="true" [virtualScroll]="true"
            [itemSize]="30" [showTransitionOptions]="'0ms'" placeholder="Select" optionLabel="item_id"
            formControlName="part_no" [resetFilterOnHide]="true" [hideTransitionOptions]="'0ms'" autoWidth="true"
            [style]="{ width: '100%' }" appendTo="body" scrollHeight="180px">
          </p-multiSelect>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">POLICY NAME</label>
          <input class="key" type="text" autoWidth="false" maxlength="25" formControlName="policyName"
            placeholder="--Type--" ng-required="true" />

          <ng-container *ngIf="addOrEdit.policyName.errors && submitted">
            <small *ngIf="addOrEdit.policyName.errors?.required">
              Policy Name is required.
            </small>
          </ng-container>
        </div>
      </div>

      <div class="col-sm-6" style="margin-top: 10px">
        <div class="drpchildctrl">
          <label class="searchLable">DESCRIPTION</label>
          <input class="key" type="text" autoWidth="false" maxlength="250" formControlName="description"
            placeholder="--Type--" ng-required="true" />
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">EFFECTIVE FROM</label>
          <input class="key" autoWidth="false" formControlName="effectiveFrom" (keydown)="onKeyPressNoDecimal($event)"
            maxlength="6" placeholder="yyyyww" ng-required="true" />
          <ng-container *ngIf="addOrEdit.effectiveFrom.errors && submitted">
            <small *ngIf="addOrEdit.effectiveFrom.errors?.required">
              Effective From is required.
            </small>
          </ng-container>
         
          <ng-container *ngIf="addOrEditform.hasError('effectiveFromInvalid')">
            <small style="color: red;">
              Effective From is invalid (yyyyww).
            </small>
          </ng-container>
      
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <label class="searchLable">EFFECTIVE TO</label>
          <input class="key" autoWidth="false" formControlName="effectiveTo" (keydown)="onKeyPressNoDecimal($event)"
            maxlength="6" placeholder="yyyyww" />
            <ng-container *ngIf="addOrEditform.hasError('effectiveToInvalid')">
              <small style="color: red;">
                Effective To is invalid (yyyyww).
              </small>
            </ng-container>
            <ng-container *ngIf="addOrEditform.hasError('efMoreThanEt')">
              <small style="color: red;">
               Effective From  cannot be greater than Effective To.
              </small>
              </ng-container>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">MIN DAYS</label>
          <input class="key" autoWidth="false" formControlName="minDays" type="text" (keydown)="onKeyPress($event)"
            maxlength="6" placeholder="--Type--" />
          <ng-container *ngIf="addOrEdit.minDays.errors && submitted">
            <small *ngIf="addOrEdit.minDays.errors?.required">
              Min Days is required.
            </small>
            <small *ngIf="addOrEdit.minDays.errors?.pattern">
              Effective From format is yyyyww.
            </small>
          </ng-container>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">MAX DAYS</label>
          <input class="key" autoWidth="false" formControlName="maxDays" type="text" (keydown)="onKeyPress($event)"
            maxlength="6" placeholder="--Type--" />
          <ng-container *ngIf="addOrEdit.maxDays.errors && submitted">
            <small *ngIf="addOrEdit.maxDays.errors?.required">
              Max Days are required.
            </small>      
          </ng-container>
          <ng-container *ngIf="addOrEditform.hasError('minDaysMoreThanMaxDays')">
            <small style="color: red;">
              Min days cannot be greater than Max days.
            </small>
          </ng-container>
          
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">MIN BOXES</label>
          <input class="key" autoWidth="false" formControlName="minBoxes" type="text" (keydown)="onKeyPress($event)"
            maxlength="5" placeholder="--Type--" />
          <ng-container *ngIf="addOrEdit.minBoxes.errors && submitted">
            <small *ngIf="addOrEdit.minBoxes.errors?.required">
              Min Boxes is required.
            </small>
          </ng-container>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">MAX BOXES</label>
          <input class="key" autoWidth="false" formControlName="maxBoxes" type="text" (keydown)="onKeyPress($event)"
            maxlength="5" placeholder="--Type--" />
            <ng-container *ngIf="addOrEdit.maxBoxes.errors && submitted">
              <small *ngIf="addOrEdit.maxBoxes.errors?.required">
                Max Boxes are required.
              </small>      
            </ng-container>
            <ng-container *ngIf="addOrEditform.hasError('minBoxesMoreThanMaxBoxes')">
              <small style="color: red;">
                Min Boxes cannot be greater than Max Boxes.
              </small>
            </ng-container>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="errorMessage">
    <p style="color: red ;">{{ errorMessage }}</p>
  </ng-container>
  <div class="text-right" style="margin-top: 20px">
    <button class="review_btn" mat-button (click)="callRespectiveFunction()">
      Save
    </button>

    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      Cancel
    </button>
  </div>
</div>