<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Shifts</h2>
      </div>
      <div class="p-2">
        <button  type="button" class="search_btn policy_btn" (click)="openDialog()">
          ADD SHIFT
        </button>
      </div>

    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">DOCK</label>
                <p-dropdown [options]="dockList"
                            [(ngModel)]="selectedDock"
                            [filter]="true"
                            [virtualScroll]="true"
                            [itemSize]="12"
                            [showTransitionOptions]="'0ms'"
                            placeholder="--Select--"
                            optionLabel="dock"
                            [filterBy]="'dock'"
                            [resetFilterOnHide]="true"
                            [hideTransitionOptions]="'0ms'"
                            autoWidth="true"
                            [style]="{ width: '110%' }"
                            (onChange)="onDockChange()"
                            appendTo="body"
                            scrollHeight="140px"
                            [panelStyle]="{ width: '50%' }" 
                            >
                </p-dropdown>
              </div>
              
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img src="./assets/images/Group 115380.png" alt="refresh icon" />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        <span class="edit_icon">
          <img
            *ngIf="access1.edit"
            (click)="onEditMode()"
            class="img-tool"
            src="./assets/images/edit.svg"
            alt="edit button icon"
            title="Edit"
          />
         
        </span>
        <span class="copy_icon">
          <img
           
            (click)="copyDialog()"
            class="img-tool"
            src="./assets/images/copy.svg"
            alt="edit button icon"
            title="Copy"
          />
        </span>
      </div>
      <div class="col-sm-2">
        <div class="records">
          <div class="drpchildctrl"></div>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div id="dvgrid" class="gridStyle">
    <app-grid class="table-outer" #osPartsGrid [columnDefs]="columnDefs" [rowData]="rowData"
      [rowSelection]="rowSelection" [gridOptions]="gridOptions" [pagination]="pagination"
      [paginationPageSize]="paginationPageSize" [context]="context" [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents" [tooltipShowDelay]="tooltipShowDelay"
      [icons]="icons" [detailRowHeight]="detailRowHeight"
       [tooltipMouseTrack]="true"
       
      [editType]="editType">
    </app-grid>
  </div>
  <div *ngIf="isEditMode" class="saveCancel">
    <button
      matRipple
      [matRippleCentered]="true"
      [matRippleUnbounded]="false"
      class="review_btn"
      (click)="reviewData()"
    >
      Review Changes
    </button>
    <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>