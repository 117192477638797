import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
      (keypress)="onKeyPress($event)"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class UpdatedStockingPaceComponent {
  /*
	<label *ngIf="!showInput" >{{value}}  </label>
		<input *ngIf="showInput" #input  [(ngModel)]="value" style="width: 100%" (change)="textUpdated()"
		(keydown)="onKeyDown($event)"/>*/
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  constructor() {}

  agInit(params: any): void {
    if (params.column === 'modified_stocking_pace') {
      params.data.modifiedStockingPace = this;
      this.value = params.data.modified_stocking_pace;
      if (this.value > 0) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;

    if (this.params.data.checkedEditMode) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'modified_stocking_pace') {
      if (this.value !== this.params.data.modified_stocking_pace) {
        this.params.data.modified_stocking_pace = this.value;
        this.params.data.isProdVolUpdated = true;
      }
    }
    // else {
    //   this.params.data.finalquantity = this.value;
    // }
  }
  // ((event.charCode >= 48 && event.charCode <= 57) ||
  // (event.charCode === 45 && event.target.value === '')) &&
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
