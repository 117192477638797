import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExcelService } from '../services/excel.service';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subscription } from 'rxjs';
import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';
import { LookupService } from '../services/lookup/lookup.service';
import {
  resetSortingAndFilters,
  successMessage,
  validateVanningDates,
  warningMessage,
} from '../constants';
import { logErrors } from '../shared/logger';
import { PartsActionsComponent } from './parts-actions/parts-actions.component';
import { PartsEditDialogComponent } from './parts-edit-dialog/parts-edit-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { PartsDeleteDialogComponent } from './parts-delete-dialog/parts-delete-dialog.component';
import { ColDef, IDetailCellRendererParams } from 'ag-grid-community';
import { PartsInventoryService } from '../services/parts-inventory/parts-inventory.service';

@Component({
  selector: 'app-configuration-parts',
  templateUrl: './configuration-parts.component.html',
  styleUrls: ['./configuration-parts.component.scss']
})
export class ConfigurationPartsComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('UserName');

  count = 0;
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;
  modalComponent = 'modal-component';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';

  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedpolicyName: any = [];
  effectiveFrom: any;
  effectiveTo: any;
  adjustSubscription$: Subscription;

  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  partNoListAdd: any = [];
  dockList: any = [];
  dockListForpopup: any = [];
  specialistList: any = [];
  policyNameList: any = [];
  qpcforParts: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;
  successMessage = '';

  kanbanSelected = 1;

  easternDate: any;
  defaultPageSize: any = 100000;
  itContact: any;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly OspService: PartsInventoryService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(
        Number(this.paginationPageSize)
      );
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }


  public columnDefs: ColDef[]

  public defaultColDef;
  public groupDefaultExpanded = 1;
  public detailCellRendererParams


  ngOnInit(): void {

    this.detailCellRendererParams = {
      detailGridOptions: {

        columnDefs: [
          {
            headerName: 'Part No',
            field: 'part_no',
            width: 120,
            headerTooltip: 'Part Number',
            tooltipField: 'part_no',
          },
          {
            headerName: 'Part Description',
            field: 'part_description',
            width: 120,
            headerTooltip: 'Part Description',
            tooltipField: 'part_description',
          },
          {
            headerName: 'Dock',
            field: 'dock',
            width: 120,
            headerTooltip: 'dock',

          },
          {
            headerName: 'Kanban',
            field: 'kanban',
            width: 100,
            headerTooltip: 'Kanban',

          },
          {
            headerName: 'SPC',
            field: 'EMPLOYEE',
            width: 10,

            headerTooltip: 'Specialist',

          },



        ],
        defaultColDef: {
          flex: 1,
        },
        groupDefaultExpanded: 1,
        masterDetail: true,
        detailRowHeight: 0.5,
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.children);
      },
    } as IDetailCellRendererParams;


    this.columnDefsMaster = [
      {
        headerName: '',
        cellRendererFramework: PartsActionsComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        field: 'actions',
        width: 80,
        sortable: false,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Policy Name',
        field: 'policy_name',
        width: 170,
        floatingFilter: true,
        tooltipField: 'policy_name',
        headerTooltip: 'Policy Name',
        cellRenderer: "agGroupCellRenderer"
      },
      {
        headerName: 'Policy Description',
        field: 'description',
        sortable: true,
        width: 170,
        floatingFilter: true,
        tooltipField: 'description',
        headerTooltip: 'Policy descripton',
        valueGetter: function (params) {
          if (params.data.description === 'undefined') {
            return '';
          } else {
            return params.data.description;
          }
        },

      },
      {
        headerName: 'Effective From',
        field: 'yearweek_from',
        sortable: true,
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Effective from(yyyyww)'
      },
      {
        headerName: 'Effective To',
        field: 'yearweek_to',
        sortable: true,
        width: 120,
        floatingFilter: true,

        headerTooltip: 'Effective To(yyyyww)',
        valueGetter: function (params) {
          if (params.data.yearweek_to === '299953') {
            return '';
          } else {
            return params.data.yearweek_to;
          }
        },

      },
      {
        headerName: 'Min Days',
        field: 'min_days',
        sortable: true,
        width: 90,
        floatingFilter: true,
        headerTooltip: 'Minimum days'
      },
      {
        headerName: 'Max Days',
        field: 'max_days',
        sortable: true,
        width: 90,
        floatingFilter: true,
        headerTooltip: 'Maximum days'

      },
      {
        headerName: 'Min Boxes',
        field: 'min_boxes',
        sortable: true,
        width: 90,
        floatingFilter: true,
        headerTooltip: 'Minimum boxes'

      },

      {
        headerName: 'Max Boxes',
        field: 'max_boxes',
        sortable: true,
        width: 90,
        floatingFilter: true,
        headerTooltip: 'Maximum boxes'

      },
      {
        headerName: 'User',
        field: 'userid',
        width: 200,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        tooltipField: 'userid',
      },
      {
        headerName: 'Updated DT',
        field: 'updated_datetime',
        width: 205,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        tooltipField: 'updated_datetime',
      },

    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.adjustSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });
  }


  initializeData() {
    this.spinner.show();
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();


    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.easternDate = res.body.data;
      },
    });
  }
  checkIfAnySelected() {

    if (
      this.selectedDock.length > 0
    ) {
      return true;
    }
    return false;
  }


  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.effectiveFrom,
        vanningTo: this.effectiveTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.rundownSearchError;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let kanban;
    let selectedPartNo;
    let selectedDock;
    let selectedSpecialist;
    let selectedpolicyName;


    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }

    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.item_id === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.item_id);
        });
      }
    }


    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }

    if (this.selectedpolicyName.length > 0) {
      if (!this.selectedpolicyName.some((a) => a.dock === 'ALL')) {
        selectedpolicyName = [];
        this.selectedpolicyName.forEach((element) => {
          selectedpolicyName.push(element.policy_name);
        });
      }
    }

    if (this.selectedSpecialist.length > 0) {
      if (!this.selectedSpecialist.some((a) => a.EMPLOYEE === 'ALL')) {
        selectedSpecialist = [];
        this.selectedSpecialist.forEach((element) => {
          selectedSpecialist.push(element.EMPLOYEE);
        });
      }
    }



    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      part_no: selectedPartNo,
      policy_name: selectedpolicyName,
      dock: selectedDock,
      spc: selectedSpecialist,
      eff_from: this.effectiveFrom,
      eff_to: this.effectiveTo,
      currenteasternDate: this.easternDate,
    };

    this.OspService.getpartInventoryGrid(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getKanban(),
      this.lookupService.getPartNumber(),
      this.lookupService.getSpecialist(),
      this.lookupService.getDock(),
      this.OspService.getpolicyName(data),
      this.lookupService.getqpcforpart(),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.partNoListAdd = [];
        this.dockList = [];
        this.dockListForpopup = [];
        this.specialistList = [];
        this.policyNameList = [];
        this.qpcforParts = [];
        if (res[0].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[0].body.data);

        //if item id list, add ALL option
        if (res[1].body.data) {
          this.partNoList.push({ item_id: 'ALL' });
        }

        if (res[3].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        if (res[2].body.data) {
          this.specialistList.push({ EMPLOYEE: 'ALL' });
        }
        if (res[4].body) {
          this.policyNameList.push({ policy_name: 'ALL' });
        }
        if (res[5].body) {
          this.qpcforParts.push(...res[5].body.data);
        }
        this.partNoList.push(...res[1].body.data);
        this.partNoListAdd.push(...res[1].body.data)

        this.specialistList.push(...res[2].body.data);
        this.dockList.push(...res[3].body.data);
        this.dockListForpopup.push(...res[3].body.data)
        this.policyNameList.push(...res[4].body);

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedpolicyName = [];
    this.effectiveFrom = '';
    this.effectiveTo = '';

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
      (x) => x.rowCheckBox === true
    ).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;
      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode =
      this.osPartsGrid?.gridOptions?.api?.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (
        this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL'
      ) {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex(
          (data) => data.kanban === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (
        this.selectedPartNo[this.selectedPartNo.length - 1].item_id === 'ALL'
      ) {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex(
          (data) => data.item_id === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }


  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex(
          (data) => data.dock === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }

  policyNameChange(): void {
    if (this.selectedpolicyName.length >= 1) {
      if (this.selectedpolicyName[this.selectedpolicyName.length - 1].policy_name === 'ALL') {
        this.selectedpolicyName = [];
        this.selectedpolicyName.push(this.policyNameList[0]);
      } else {
        let indexAll = this.selectedpolicyName.findIndex(
          (data) => data.policy_name === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedpolicyName.splice(indexAll, 1);
        }
      }
    }
  }


  specialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (
        this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE ===
        'ALL'
      ) {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex(
          (data) => data.EMPLOYEE === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }


  userDeleteChanged(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    this.opendeleteDialog(rowNode.data);
  }

  userEdit(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    this.openeditDialog(rowNode.data);
  }

  //delete dialog popup
  opendeleteDialog(dataTODelete) {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      userid: this.userName,
      policy_id: dataTODelete?.policy_id
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;

    dialogConfig.width = '390px';
    const dialogRef = this.dialog.open(PartsDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.removeMessage();
      if (res === 'cancel') {
        return;
      }
      if (res === 'save') {
        this.spinner.show();
        this.OspService.deletePolicy(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.table1DeletedCount >= 1 || data.body.table2DeletedCount >= 1) {
              this.onSearch(0);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    });
  }
  //edit dialog popup
  openeditDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      partNoList: this.qpcforParts,
      dockList: this.dockList,
      data,
      mode: 'edit',
    };

    const dialogRef = this.dialog.open(PartsEditDialogComponent, dialogConfig);
    const editGetResponse =
      dialogRef.componentInstance.editMasterEventEmitter.subscribe((res) => {
        var data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          policy_id: dialogConfig.data.data.policy_id,
          dock: res.dock,
          policy_name: res.policyName,
          description: res.description,
          parts: res.part_no || [],
          min_days: res.minDays,
          max_days: res.maxDays,
          yearweek_to: res.effectiveTo === null ? '' : res.effectiveTo,
          yearweek_from: res.effectiveFrom,
          min_boxes: res.minBoxes,
          max_boxes: res.maxBoxes,
          plant: '01',
        }

        this.OspService.editpolicy(
          data
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            this.spinner.hide();
            const updatedRows = response.body.updatedRows;
            if (updatedRows.length > 0) {
              this.successMessage = successMessage.policyUpdated;
              this.rowData = updatedRows;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }

            window.scroll(0, 0);
            this.setPageSizeToAll();
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      editGetResponse.unsubscribe();
    });
  }
  // add dialog pop up
  openDialog() {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '650px';
    dialogConfig.height = '500px';
    dialogConfig.data = {
      partNoList: this.qpcforParts,
      dockList: this.dockListForpopup,
      mode: 'add',
    };
    const dialogRef = this.dialog.open(PartsEditDialogComponent, dialogConfig);
    const addGetResponse =
      dialogRef.componentInstance.addMasterEventEmitter.subscribe((res) => {
        var data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          dock: res.dock,
          policy_name: res.policyName,
          description: res.description,
          part_number: res.part_no || [],
          min_days: res.minDays,
          max_days: res.maxDays,
          yearweek_to: res.effectiveTo,
          yearweek_from: res.effectiveFrom,
          min_boxes: res.minBoxes,
          max_boxes: res.maxBoxes,
          plant: '01',


        }

        this.OspService.addpolicy(
          data
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            this.spinner.hide();
            const updatedRows = response.body.updatedRows;
            if (updatedRows.length > 0) {
              this.successMessage = successMessage.policyAdded;
              this.rowData = updatedRows;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }

            window.scroll(0, 0);
            this.setPageSizeToAll();
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }
}
