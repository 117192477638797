import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { getUnique, getUniqueByKey } from 'src/app/constants';
import { PartsInventoryService } from 'src/app/services/parts-inventory/parts-inventory.service';
import { logErrors } from 'src/app/shared/logger';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-parts-edit-dialog',
  templateUrl: './parts-edit-dialog.component.html',
  styleUrls: ['./parts-edit-dialog.component.scss'],
})
export class PartsEditDialogComponent implements OnInit {
  addMasterEventEmitter = new EventEmitter();
  editMasterEventEmitter = new EventEmitter();
  namcValue = localStorage.getItem('namcvalue');
  user = localStorage.getItem('workdayId');
  userRole = localStorage.getItem('UserRoles');
  resData: any;
  warningMessage = '';
  errorMessage: string | null = null;
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  popupname: any;
  dock: any;
  policyName: any;
  description: any;
  dataItem: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;
  partNoList = [];

  addOrEditform: UntypedFormGroup = this.formBuilder.group(
    {
      dock: ['', Validators.required],
      policyName: ['', Validators.required],
      description: [''],
      part_no: [''],
      maxDays: ['', Validators.required],
      minDays: ['', Validators.required],
      effectiveTo: [''],
      effectiveFrom: ['', Validators.required],
      minBoxes: ['', Validators.required],
      maxBoxes: ['', Validators.required],
    },
    {
      validator: [
        (group: UntypedFormGroup) => this.validateMinMaxFields(group, 'minDays', 'maxDays', 'minDaysMoreThanMaxDays'),
        (group: UntypedFormGroup) => this.validateMinMaxFields(group, 'minBoxes', 'maxBoxes', 'minBoxesMoreThanMaxBoxes'),
        (group: UntypedFormGroup) => this.validateEffectiveDate(group, 'effectiveFrom', 'effectiveFromInvalid'),
        (group: UntypedFormGroup) => this.validateEffectiveDate(group, 'effectiveTo', 'effectiveToInvalid'),
        this.effectiveFromLessThaneffectiveTo,
      ],
    }
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogeRef: MatDialogRef<PartsEditDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly OspService: PartsInventoryService,
    private readonly spinner: NgxSpinnerService
  ) { }
  get addOrEdit() {
    return this.addOrEditform.controls;
  }


  ngOnInit(): void {
    this.addOrEditform.get('part_no')?.disable();
    this.business_entity = localStorage.getItem('namcvalue');
    this.partNoList = this.data?.partNoList;

    if (this.data?.mode === 'edit') {
      if (this.data.data) {
        const newArray = this.data.data?.children?.map((item) => ({
          item_id: item.part_no,
        }));

        // Ensure part_no dropdown updates correctly
        this.addOrEditform.patchValue({
          dock: { dock: this.data.data.dock },
          policyName: this.data.data.policy_name,
          description: this.data.data.description === 'undefined' ? '' : this.data.data.description,
          maxDays: this.data.data.max_days,
          minDays: this.data.data.min_days,
          effectiveTo: this.data.data.yearweek_to === '299953' ? '' : this.data.data.yearweek_to,
          effectiveFrom: this.data.data.yearweek_from,
          minBoxes: this.data.data.min_boxes,
          maxBoxes: this.data.data.max_boxes,
          part_no: newArray, // Set part_no with item_id values
        });

        this.addOrEditform.get('dock')?.disable();
        this.addOrEditform.get('part_no')?.enable();
        this.onDockChange();
      }
    }
  }

  effectiveFromLessThaneffectiveTo(group: UntypedFormGroup) {
    const ef = group.get('effectiveFrom').value;
    const et = group.get('effectiveTo').value;
    return ef && et && parseInt(ef) > parseInt(et) ? { efMoreThanEt: true } : null;
  }

  validateMinMaxFields(group: UntypedFormGroup, minField: string, maxField: string, errorName: string) {
    const minValue = group.get(minField).value;
    const maxValue = group.get(maxField).value;
    return minValue && maxValue && parseFloat(minValue) > parseFloat(maxValue)
      ? { [errorName]: true }
      : null;
  }

  // Check if the value is 6 digits and numeric and valid year_Week
  validateEffectiveDate(group: UntypedFormGroup, fieldName: string, errorName: string) {
    const dateValue = group.get(fieldName).value;

    if (dateValue && dateValue.length === 6 && !isNaN(dateValue)) {
      const year = parseInt(dateValue.substring(0, 4), 10);
      const week = parseInt(dateValue.substring(4, 6), 10);

      if (year < 1000 || year > 9999) {
        return { [errorName]: true };
      }
      if (week < 1 || week > 53) {
        return { [errorName]: true };
      }
      return null;
    }

    if (dateValue && dateValue.length > 0) {
      return { [errorName]: true };
    } else {
      return null;
    }
  }


  submitted: boolean = false;
  // Function to check if a date is between two other dates (inclusive)
  isBetween(start, end, checkStart, checkEnd) {

    return (
      (checkStart >= start && checkEnd <= end && checkEnd >= start && checkStart <= end) ||
      (start >= checkStart && end <= checkEnd && end >= checkStart && start <= checkEnd)
    );
  }

  // Main logic to validate the input and check part validity
  addMasterDetails() {
    this.spinner.show();

    if (this.addOrEditform.invalid) {
      this.submitted = true;
      this.spinner.hide();
      return;
    }

    const addEmittedData = {
      dock: this.addOrEdit.dock.value.dock,
      policyName: this.addOrEdit.policyName.value,
      description: this.addOrEdit.description.value,
      part_no: this.addOrEdit.part_no.value,
      minDays: this.addOrEdit.minDays.value,
      maxDays: this.addOrEdit.maxDays.value,
      effectiveTo: this.addOrEdit.effectiveTo.value || '299953',
      effectiveFrom: this.addOrEdit.effectiveFrom.value,
      minBoxes: this.addOrEdit.minBoxes.value,
      maxBoxes: this.addOrEdit.maxBoxes.value,
    };

    const offset = 0;
    let selectedPartNo;
    let isCall = false;
    if (addEmittedData.part_no?.length > 0) {
      isCall = true;
      selectedPartNo = [];
      addEmittedData.part_no.forEach((element) => {
        selectedPartNo.push(element.item_id);
      });
    }

    // call API to check validation
    if (isCall) {
      const data = {
        offset: offset,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        dock: [addEmittedData.dock],
        user_role: this.userRole,
      };
      this.OspService.getpartInventoryGrid(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
          if (res.body.data?.length == 0) {
            this.errorMessage = null;
            this.spinner.hide();
            this.addMasterEventEmitter.emit(addEmittedData);
            this.dialogeRef.close();
          } else {
            // Convert emitted effectiveFrom and effectiveTo to integers
            const emittedEffectiveFrom = parseInt(addEmittedData.effectiveFrom);
            const emittedEffectiveTo = parseInt(addEmittedData.effectiveTo);

            // Filter parts matching selected part numbers from the response
            const filteredParts = res.body.data.flatMap((item) =>
              item.children
                .filter((child) => selectedPartNo.includes(child.part_no) && child.dock === addEmittedData.dock)
                .map((child) => ({
                  part_no: child.part_no,
                  effective_from: item.yearweek_from,
                  effective_to: item.yearweek_to,
                  policy_name: item.policy_name,
                }))
            );

            if (filteredParts?.length > 0) {
              // Function to check if the effective dates are valid
              const checkEffectiveDates = (part) => {
                const partEffectiveFrom = parseInt(part.effective_from);
                const partEffectiveTo = parseInt(part.effective_to);

                return this.isEffectiveDateInRange(emittedEffectiveFrom, emittedEffectiveTo, partEffectiveFrom, partEffectiveTo);
              };

              // Check all filtered parts for validity
              const checkResults = filteredParts.map((part) => {
                const isValid = checkEffectiveDates(part);
                return {
                  part_no: part.part_no,
                  policy_name: part.policy_name,
                  isEffectiveInRange: isValid,
                };
              });

              const invalidParts = checkResults.filter((result) => result.isEffectiveInRange);

              if (invalidParts?.length > 0) {
                const uniquePartNo = getUnique(
                  invalidParts.map((result) => result.part_no),
                  'part_no'
                );
                const uniquePolicyName = getUnique(
                  invalidParts.map((result) => result.policy_name),
                  'policy_name'
                );

                this.spinner.hide();
                this.errorMessage =
                  'Part number(s) ' +
                  uniquePartNo.join(' , ') +
                  " are already part of '" +
                  uniquePolicyName.join(' , ') +
                  "' inventory policy with effective dates overlapping with " +
                  addEmittedData.effectiveFrom +
                  (addEmittedData.effectiveTo === 299953 ? '' : ' to ' + addEmittedData.effectiveTo);

              } else {
                this.spinner.hide();
                this.addMasterEventEmitter.emit(addEmittedData);
                this.dialogeRef.close();
              }
            } else {
              this.spinner.hide();
              this.addMasterEventEmitter.emit(addEmittedData);
              this.dialogeRef.close();
            }
          }
        },
      });
    } else {
      this.spinner.hide();
      this.addMasterEventEmitter.emit(addEmittedData);
      this.dialogeRef.close();
    }
  }

  editRowMasterDetails() {
    this.spinner.show();
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      this.spinner.hide();
      return;
    }

    const editEmittedData = {
      dock: this.addOrEdit.dock.value.dock,
      policyName: this.addOrEdit.policyName.value,
      description: this.addOrEdit.description.value,
      part_no: this.addOrEdit.part_no.value === null ? '' : this.addOrEdit.part_no.value,
      minDays: this.addOrEdit.minDays.value,
      maxDays: this.addOrEdit.maxDays.value,
      effectiveTo: this.addOrEdit.effectiveTo.value || '299953',
      effectiveFrom: this.addOrEdit.effectiveFrom.value,
      minBoxes: this.addOrEdit.minBoxes.value,
      maxBoxes: this.addOrEdit.maxBoxes.value,
    };
    const offset = 0;
    let selectedPartNo;
    let isCall = false;
    if (editEmittedData.part_no?.length > 0) {
      isCall = true;
      selectedPartNo = [];

      editEmittedData.part_no.forEach((element) => {
        selectedPartNo.push(element.item_id);
      });
    }
    // call api to check validation effective from dataes have add parts number present in db or not if not it will allow to save
    if (isCall) {
      const data = {
        offset: offset,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        // part_no: selectedPartNo,
        dock: [editEmittedData.dock],
        // eff_from: editEmittedData.effectiveFrom,
        // eff_to: editEmittedData.effectiveTo ?? "",
        user_role: this.userRole,
      };
      this.OspService.getpartInventoryGrid(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
          const filteredData = res.body?.data?.filter((item) => item.policy_id !== this.data.data.policy_id);

          if (filteredData?.length === 0) {
            this.errorMessage = null;
            this.spinner.hide();

            this.editMasterEventEmitter.emit(editEmittedData);
            this.dialogeRef.close();
          } else {
            // Get part_no from addEmittedData
            const partNoFromAddEmittedData = selectedPartNo;

            // Convert emitted effectiveFrom and effectiveTo to Date objects
            const emittedEffectiveFrom = parseInt(editEmittedData.effectiveFrom);
            const emittedEffectiveTo = parseInt(editEmittedData.effectiveTo);

            // Filter parts matching selected part numbers from res.body.data
            const filteredParts = filteredData.flatMap((item) =>
              item.children
                .filter((child) => selectedPartNo.includes(child.part_no) && child.dock === editEmittedData.dock)
                .map((child) => ({
                  part_no: child.part_no,
                  effective_from: item.yearweek_from,
                  effective_to: item.yearweek_to,
                  policy_name: item.policy_name,
                }))
            );
            // Function to check if the emitted effective dates are within the range of the response
            const isEffectiveDateInRange = (effectiveFrom, effectiveTo, yearweekFrom, yearweekTo) => {
              return effectiveFrom <= yearweekTo && effectiveTo >= yearweekFrom;
            };

            // Modify the check for each part in filteredParts
            const checkEffectiveDates = (filteredPart) => {
              const emittedEffectiveFrom = parseInt(editEmittedData.effectiveFrom);
              const emittedEffectiveTo = parseInt(editEmittedData.effectiveTo);
              const effectiveFromPart = parseInt(filteredPart.effective_from);
              const effectiveToPart = parseInt(filteredPart.effective_to);

              // Check if emitted dates overlap with the part's effective dates
              return isEffectiveDateInRange(
                emittedEffectiveFrom,
                emittedEffectiveTo,
                effectiveFromPart,
                effectiveToPart
              );
            };
            const getPartsNo = [];
            const policyName = [];

            // Function to handle the part validation logic
            const checkPartsValidation = (filteredParts) => {
              const checkResults = filteredParts.map((part) => {
                const isValid = checkEffectiveDates(part);
                getPartsNo.push(part.part_no);
                policyName.push(part.policy_name);
                return {
                  part_no: part.part_no,
                  isEffectiveInRange: isValid,
                };
              });

              return checkResults;
            };

            // Inside the response handling
            if (filteredParts?.length > 0) {
              const checkResults = checkPartsValidation(filteredParts);

              const allPartsInValid = checkResults.some((result) => result.isEffectiveInRange);

              if (allPartsInValid) {
                const uniquePartNo = getUnique(getPartsNo, 'part_no');
                const uniquePolicyName = getUniqueByKey(policyName, 'policy_name');
                this.spinner.hide();
                this.errorMessage =
                  'Part number(s) ' +
                  uniquePartNo.join(' , ') +
                  " are already parts of '" +
                  uniquePolicyName.join(' , ') +
                  "' inventory policy with effective dates overlapping with " +
                  editEmittedData.effectiveFrom +
                  (editEmittedData.effectiveTo === 299953 ? '' : ' to ' + editEmittedData.effectiveTo);
              } else {
                this.spinner.hide();
                this.editMasterEventEmitter.emit(editEmittedData);
                this.dialogeRef.close();
              }
            } else {
              this.spinner.hide();
              this.editMasterEventEmitter.emit(editEmittedData);
              this.dialogeRef.close();
            }
          }
        },
      });
    } else {
      this.spinner.hide();

      this.editMasterEventEmitter.emit(editEmittedData);
      this.dialogeRef.close();
    }
  }
  isEffectiveDateInRange = (effectiveFrom, effectiveTo, yearweekFrom, yearweekTo) => {
    return effectiveFrom <= yearweekTo && effectiveTo >= yearweekFrom;
  };
  callRespectiveFunction() {
    if (this.data?.mode === 'edit') {
      this.editRowMasterDetails();
    } else {
      this.addMasterDetails();
    }
  }

  onKeyPressNoDecimal(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, decimal point, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);
    const isDecimal = event.key === '.';

    // Allow number keys, decimal point, allowed keys, and prevent default for others
    if (!isNumber && !isDecimal && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.warningMessage = 'Something went wrong! Please contact support team.';
  };

  onDockChange(): void {
    var dock = this.addOrEditform.controls.dock?.value?.dock;
    if (dock) {
      this.addOrEditform.get('part_no')?.enable();
    } else {
      this.addOrEditform.get('part_no')?.disable();
    }
    if (dock) {
      // Reset part dropdowns and reload options
      const partNoList = this.data.partNoList.filter((element) => element.dock === dock);
      // Populate new part options
      const uniquePartNoList = partNoList
        .map((item) => ({ item_id: item.item_id }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.item_id === value.item_id // Check for unique item_id
            )
        )
        .sort((a, b) => parseInt(a.item_id) - parseInt(b.item_id)); // Sort by item_id

      // Update dropdown options
      this.partNoList = uniquePartNoList;

      // Reset the selected value in the kanban and partNo form controls
      if (this.data?.mode === 'add') {
        this.addOrEditform.controls.part_no.reset();
      }
      this.addOrEditform.controls.part_no.enable();
    }
  }
}
