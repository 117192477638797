import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-module-stocking-grid-checkbox',
  template: `
  <input type="checkbox" [checked]="isChecked" (change)="onCheckboxChange($event)" />
  `,
  styles: [

  ],
})
export class ModuleStockingGridCheckboxComponent {

  // Define properties to hold the row data and checkbox state
  public params: any;
  public isChecked: boolean;

  agInit(params: any): void {
    this.params = params;
    if (params.column === 'stock') {
      params.data.stockCheck = this;
      this.isChecked = params.data.module_status === "STOCKED"
    }
  }

  onCheckboxChange(event: Event): void {

    this.isChecked = (event.target as HTMLInputElement).checked;
    this.params.data.stock = this.isChecked;
    console.log("checking checkbox value..." + this.isChecked);

    this.params.data.module_status = this.isChecked?'STOCKED':'UNSTOCKED';
    console.log("this.params.data.module_status",this.params.data.module_status);
    
    // Optionally, call the parent function (onCallAPI)
    if (this.params.onCallAPI) {
      this.params.onCallAPI(this.params.data); // Send the row data to the parent function
    }
  }

  getValue(): any {
    return this.isChecked;
  }

  // Optionally, refresh the component (called when grid state is updated)
  refresh(params: any): boolean {
    debugger
    this.agInit(params);
    return true;
  }



}
