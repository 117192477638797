import { Component, Inject, OnInit, EventEmitter } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-edit-cumulplan',
  templateUrl: './cumul-plannet-popup.component.html',
  styleUrls: ['./cumul-plannet-popup.component.scss'],
})
export class EditCumulPlanNetComponent implements OnInit {
  addBreaksEventEmitter = new EventEmitter();
  editBreaksEventEmitter = new EventEmitter();
  shiftNumbers: any[] = [];
  shiftNumber: any;
  dayWeek: any;
  startTime: any;
  endTime: any;
  toTime: any;
  fromTime: any;
  errorMessage: any;
  times: string[] = [];
  times2: string[] = [];
  hoursArray = new Array(24);
  mintuesArray = new Array(60);
  submitted: boolean = false;


  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    shiftNumber: [''],
    actualDate :[''],
    planVolume :[''],
    cumulPlannet: ['', Validators.required],

  },
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<EditCumulPlanNetComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService


  ) { }
  get addOrEdit() {
    return this.addOrEditform.controls;
  }
  ngOnInit(): void {
    console.log('addOrEditform:', this.addOrEditform);
    console.log('addOrEdit:', this.addOrEdit);
    console.log('this.data:', this.data);
    this.addOrEditform.patchValue({

      shiftNumber: this.data[0]?.actual_shift,
      actualDate:this.data[0]?.actual_date,
      planVolume:this.data[0]?.plan_volume,
      cumulPlannet: this.data[0]?.cumul_plan_net
    });
    this.addOrEditform.get('shiftNumber')?.disable();
    this.addOrEditform.get('actualDate')?.disable();
    this.addOrEditform.get('planVolume')?.disable();



  }
  Recalculate(){
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      this.spinner.hide();
      return;
    }


  }



}