<div class="background">
  <div #formTop></div>

  <div class="p-2 messageBackground reset_btn">
    <label class="resetIcon" (click)="goBack()">
      <img src="./assets/images/back arrow.png" alt="bell icon" class="bell" />
    </label>

    <label class="message calendarFont" (click)="goBack()"> BACK</label>
  </div>

  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Air Freight (Order No: {{ airOrderNo }})</h2>
      </div>
    </div>
  </div>

  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        (click)="removeMessage()"
        aria-label="Close"
      ></button>
    </div>

    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>

  <form [formGroup]="userForm">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-start">
          <div class="col-3">
            <label class="searchLable">ORDER TYPE</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="toOrder"
              optionLabel="order_type"
              placeholder="Select"
              formControlName="orderType"
              [appendTo]="'body'"
              [autoWidth]="false"
              filterBy="order_type"
            ></p-dropdown>
          </div>

          <div class="col-3">
            <label class="searchLable">DEST CODE</label>
            <p-dropdown
              formControlName="destCodeNo"
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="addAirOrderDestcode"
              optionLabel="dest_code"
              [appendTo]="'body'"
              [autoWidth]="false"
              filterBy="dest_code"
            ></p-dropdown>
          </div>
        </div>

        <!-- 2nd row -->
        <div class="row">
          <div class="col-3">
            <label required class="searchLable">RESPONSIBILITY</label>
            <div class="radio-group">
              <label class="radio">
                <input
                  formControlName="responsibilityregion"
                  value="Plant"
                  type="radio"
                />
                <span class="radio-label"> Plant</span>
              </label>
              <label class="radio">
                <input
                  formControlName="responsibilityregion"
                  value="TMC"
                  type="radio"
                />
                <span class="radio-label">TMC</span>
              </label>
            </div>
          </div>
          <div class="col-3">
            <label class="searchLable">RAISED BY</label>
            <input
              formControlName="raisedBy"
              class="key"
              type="text"
              placeholder="--Type--"
            />
          </div>

          <div class="col-3">
            <span class="v1"></span>
            <label class="searchLable">ORDER DT</label>
            <input formControlName="raisedDT" class="key" type="date" />
            <!-- <ng-container
              *ngIf="addOrEditform.controls.orderDate.errors && submitted"
            >
              <small *ngIf="addOrEditform.controls.orderDate.errors?.required">
                Order DT is required.
              </small>
            </ng-container> -->
          </div>
        </div>

        <!-- 3 row -->
        <div class="row">
          <div class="col-md-3">
            <label required class="searchLable">VOLUME</label>
            <div class="radio-group">
              <label class="radio">
                <input
                  formControlName="volumeSize"
                  value="Additional Parts"
                  type="radio"
                />
                <span class="radio-label">Additional Parts </span>
              </label>
              <label class="radio">
                <input
                  formControlName="volumeSize"
                  value="Pipeline"
                  type="radio"
                />
                <span class="radio-label">Pipeline </span>
              </label>
            </div>
          </div>

          <div class="col-md-3">
            <label class="searchLable">MANAGER</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="managerList"
              optionLabel="contact_name"
              formControlName="managerName"
              [appendTo]="'body'"
              [autoWidth]="false"
              (onChange)="getMangerIdByNameAndRole()"
              filterBy="contact_name"
            ></p-dropdown>
          </div>

          <!-- <div class="row"> -->
          <div class="col-md-3">
            <label class="searchLable">COMMENT</label>
            <input
              formControlName="firstComment"
              type="text"
              placeholder="--Type--"
              class="key"
              maxlength="250"

            />
          </div>
        </div>
        <!-- 4 row -->
        <div class="row">
          <div class="col-md-3">
            <label class="searchLable">BUDGET CODE</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="addbudgetCodes"
              optionLabel="budget_code"
              formControlName="budgetCode"
              [appendTo]="'body'"
              [autoWidth]="false"
              filterBy="budget_code"
            ></p-dropdown>
          </div>

          <div class="col-md-3">
            <label class="searchLable">VAN CENTER CONTACT</label>
            <p-dropdown
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="30"
              placeholder="Select"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [options]="kvcList"
              optionLabel="contact_name"
              formControlName="kvcName"
              [appendTo]="'body'"
              [autoWidth]="false"
              (onChange)="getKvcIDdByNameAndRole()"
              filterBy="contact_name"
            ></p-dropdown>
          </div>
          <div class="col-md-3">
            <label class="searchLable">REASON EXPLANATION</label>
            <input
              formControlName="secondComment"
              type="text"
              placeholder="--Type--"
              class="key"
              maxlength="250"

            />
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <mat-accordion>
          <mat-expansion-panel
          disabled="true"
            *ngFor="let part of uniquePartNumbers; let i = index"
            [expanded]="isExpanded[i]"
          >
            <mat-expansion-panel-header
             
            >
              <mat-icon>
                {{ isExpanded[i] ? "expand_more" : "chevron_right" }}
              </mat-icon>
              Part No: {{ part }}
              <!-- <button
                mat-icon-button
                (click)="onDeletePart(i)"
                style="margin-left: auto; padding: 0"
                aria-label="Delete Part" 
              </button> &nbsp; &nbsp;
              > -->
              <!-- <img
                class="detailiconhome"
                src="assets/images/icon-delete.svg"
                class="expansion-header-image"
                (click)="onDeletePart(i)"
                title="delete"
                alt="Delete"
              /> -->
              &nbsp; &nbsp;
            </mat-expansion-panel-header>

            <div [formArrayName]="'users'">
              <div [formGroupName]="i" style="margin-bottom: 10px">
                <div class="row">
                  <div class="col-3">
                    <label required class="searchLable">PART TYPE</label>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          formControlName="partType"
                          value="Production"
                          type="radio"
                        />
                        <span class="radio-label">Production </span>
                      </label>
                      <label class="radio">
                        <input
                          value="Trial"
                          formControlName="partType"
                          type="radio"
                        />
                        <span class="radio-label">Trial</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-3">
                    <span class="v1"></span>
                    <label class="searchLable">DOCK </label>
                    <p-dropdown
                      [filter]="true"
                      [virtualScroll]="true"
                      [itemSize]="30"
                      [style]="{ width: '100%' }"
                      scrollHeight="100px"
                      [options]="addAirOrderDock"
                      optionLabel="dock"
                      formControlName="dockNo"
                      [appendTo]="'body'"
                      [autoWidth]="false"
                      filterBy="dock"
                    ></p-dropdown>
                  </div>
                  <div class="col-3">
                    <label class="searchLable">KANBAN</label>
                    <p-dropdown
                      formControlName="kanbanNo"
                      [itemSize]="30"
                      placeholder="Select"
                      [style]="{ width: '100%' }"
                      scrollHeight="100px"
                      [options]="addAirOrderKanban"
                      [value]="getKanbanValue(i)"
                      optionLabel="kanban"
                      [appendTo]="'body'"
                      [autoWidth]="false"
                      filterBy="kanban"
                    >
                    </p-dropdown>
                  </div>

                  <!-- <div class="col-3">
                    <span class="v1"></span>
                    <label class="searchLable">NO OF PIECES TO ORDER</label>
                    <input
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      formControlName="piecesToOrder"
                    />
                  </div> -->

                  <div class="col-3">
                    <span class="v1"></span>
                    <label class="searchLable">PART NUMBER</label>
                    <p-dropdown
                      [filter]="true"
                      [virtualScroll]="true"
                      [itemSize]="30"
                      [style]="{ width: '100%' }"
                      scrollHeight="100px"
                      [options]="addAirOrderPart"
                      optionLabel="item_id"
                      formControlName="partNo"
                      [disabled]="true"
                      [readonlyInput]="true"
                      [appendTo]="'body'"
                      [autoWidth]="false"
                      filterBy="item_id"
                    ></p-dropdown>
                    <!-- <ng-container
                      *ngIf="addOrEditform.controls.partNo.errors && submitted"
                    >
                      <small *ngIf="addOrEditform.controls.partNo.errors?.required">
                        Part number is required.
                      </small>
                    </ng-container> -->
                  </div>

                  <div class="col-3">
                    <label class="searchLable">PART DESCRIPTION</label>
                    <input
                      formControlName="partDesc"
                      class="key"
                      type="text"
                      placeholder="--Type--"
                    />
                  </div>

                  <div class="col-3">
                    <span class="v1"></span>
                    <label class="searchLable">AIR CASE CODE </label>
                    <p-dropdown
                      [filter]="true"
                      [virtualScroll]="true"
                      [itemSize]="30"
                      [style]="{ width: '100%' }"
                      scrollHeight="100px"
                      [options]="airCaseCodesList"
                      optionLabel="air_case_code"
                      formControlName="airCase"
                      placeholder="Select"
                      [appendTo]="'body'"
                      [autoWidth]="false"
                      filterBy="air_case_code"
                    ></p-dropdown>
                    <!-- <ng-container
                      *ngIf="addOrEditform.controls.partNo.errors && submitted"
                    >
                      <small *ngIf="addOrEditform.controls.partNo.errors?.required">
                        Part number is required.
                      </small>
                    </ng-container> -->
                  </div>

                  <div class="col-3">
                    <span class="v1"></span>
                    <label class="searchLable">REASON</label>

                    <p-dropdown
                      [filter]="true"
                      [virtualScroll]="true"
                      [itemSize]="30"
                      [style]="{ width: '100%' }"
                      scrollHeight="100px"
                      [options]="addAirReason"
                      optionLabel="reason_code"
                      formControlName="reasonCode"
                      placeholder="Select"
                      [appendTo]="'body'"
                      [autoWidth]="false"
                      filterBy="reason_code"
                    ></p-dropdown>
                    <!-- <ng-container
                    *ngIf="addOrEditform.controls.reason.errors && submitted"
                  >
                    <small *ngIf="addOrEditform.controls.reason.errors?.required">
                      Reason  is required.
                    </small>
                  </ng-container> -->
                  </div>

                  <div class="col-3" *ngIf="!isLotsSelected">
                    <span class="v1"></span>
                    <label class="searchLable">NO OF PIECES TO ORDER</label>
                    <input
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      formControlName="piecesToOrder"
                      (input)="handleKeydown($event)"
                      (keydown)="handleKeyPress($event)"
                      maxlength="5"

                    />
                  </div>
                  <!-- <div class="col-3" *ngIf="isLotsSelected">
                    <span class="v1"></span>
                    <label class="searchLable">NO OF LOTS TO ORDER</label>
                    <input
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      formControlName="lotsToOrder"
                      maxlength="5"
                      (input)="handleKeydown('input1', $event)"
                      (keydown)="onKeyPress($event)"
                    /> -->
                  <div class="col-3" *ngIf="isLotsSelected">
                    <span class="v1"></span>
                    <label class="searchLable">NO OF LOTS TO ORDER</label>
                    <input
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      formControlName="lotsToOrder"
                      (input)="handleKeydown($event)"
                      (keydown)="handleKeyPress($event)"
                      maxlength="5"
                    />
                  </div>
                  <div class="col-3">
                    <span class="v1"></span>
                    <label class="searchLable">QPC</label>
                    <input
                      formControlName="qpcBox"
                      class="key"
                      type="text"
                      placeholder="--Type--"
                    />
                    <!-- <ng-container
                      *ngIf="addOrEditform.controls.qpc.errors && submitted"
                    >
                      <small *ngIf="addOrEditform.controls.qpc.errors?.required">
                        QPC is required.
                      </small>
                    </ng-container> -->
                  </div>

                  <!-- <div class="col-3">
                    <label class="searchLable">NO OF LOTS TO ORDER</label>
                    <input
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      required
                    />
                  </div> -->

                 

                  <div class="col-3">
                    <label class="searchLable">TOTAL ORDER</label>
                    <input
                      formControlName="totalOrder"
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      maxlength="10"

                    />
                  </div>

                  <div class="col-3">
                    <!-- <span class="v1"></span> -->
                    <label class="searchLable">CUBIC DIMENSION</label>
                    <input class="key" type="text" placeholder="--Type--" 
                    maxlength="5"
                    readonly
                    />
                  </div>

                  <div class="col-3">
                    <label class="searchLable">BOX WEIGHT(KG)</label>
                    <input
                      formControlName="boxWeight"
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      (input)="handleKeydown($event)"
                      (keydown)="handleKeyPress($event)"                     
                      maxlength="10"
                    />
                  </div>

                  <div class="col-3">
                    <label required class="searchLable">COST CALCULATION</label>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          formControlName="costCalc"
                          value="Weight"
                          type="radio"
                        />
                        <span class="radio-label"> Weight</span>
                      </label>
                      <label class="radio">
                        <input
                          formControlName="costCalc"
                          value="volume"
                          type="radio"
                        />
                        <span class="radio-label">Volume</span>
                      </label>
                    </div>
                  </div>

                  <div class="col-3">
                    <label class="searchLable">ESTD COST (USD)</label>
                    <input
                      formControlName="estCost"
                      class="key"
                      type="text"
                      placeholder="--Type--"
                      maxlength="10"
                      readonly

                    />
                  </div>

                  <div class="col-3">
                    <label required class="searchLable"
                      >VAN CENTER CONFIRMATION</label
                    >
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          formControlName="kvcConf"
                          type="radio"
                          value="Yes"
                        />
                        <span class="radio-label">Yes</span>
                      </label>

                      <label class="radio">
                        <input
                          formControlName="kvcConf"
                          type="radio"
                          value="No"
                        />
                        <span class="radio-label"> No</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-10">
                    <p style="font-weight: bold; margin-top: 17px">
                      Required Arrival Schdules
                    </p>
                  </div>
                  <div class="col-2 detailiconhome">
                    <!-- <label class="resetlabel1"> -->
                    <!-- <p (click)="addRow()">Add Arrival Date</p> -->
                    <button class="cancel_btn arrival_btn" (click)="addRow()">
                      ADD ARRIVAL DATE
                    </button>
                    <!-- </label> -->
                  </div>
                </div>

                <div class="row">
                  <!-- <app-grid
                    class="table-outer"
                    #osPartsGrid
                    [columnDefs]="columnDefs"
                    [rowData]="rowData"
                    [gridOptions]="gridOptions"
                    [frameworkComponents]="frameworkComponents"
                    [floatingFilter]="true"
                    [context]="context"
                  ></app-grid> -->

                  <ag-grid-angular
                    class="ag-theme-balham"
                    #ospMorningGrid
                    [columnDefs]="columnDefs"
                    style="width: 100%; height: 20vh"
                    [gridOptions]="gridOptions"
                    [rowData]="rowDatas"
                    [tooltipMouseTrack]="true"
                    [context]="context"
                  >
                  </ag-grid-angular>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <button class="review_btn" (click)="saveOrder(i)">Save</button>
              &nbsp;&nbsp;

              <button class="cancel_btn" (click)="onCancel()">Cancel</button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </form>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>
