<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
  <div class="col-sm-11 movable" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
    <h3 class="head">REVIEW CHANGES</h3>
  </div>
  <div class="col-sm-1">
    <button type="button" class="btn btn-dark btn-circle btn-xl" mat-dialog-close="cancel" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>

<div class="col-md-12">
  <ag-grid-angular
    #osPartsGrid
    style="width: 100%; margin-top: 7px; height: 220px !important; overflow-y: auto; top: 3%; margin-bottom: 3%;"
    class="ag-theme-balham table-outer"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [rowSelection]="rowSelection"
    [gridOptions]="gridOptions"
    [context]="context"
    [editType]="editType"
    [suppressClickEdit]="suppressClickEdit"
  >
  </ag-grid-angular>
</div>
<div class="text-right">
  <button class="review_btn" mat-button [hidden]="!enableSave" (click)="onSaveChanges()" mat-dialog-close="save">
    SAVE CHANGES
  </button>

  <button class="cancel_btn" mat-button mat-dialog-close="cancel">Cancel</button>
</div>
