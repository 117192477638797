import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class StockingPaceServiceService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');
  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      
    });
  };

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        
      }),
    };
  };

  httpCustomOptions1 = () => {
    return {
      params: {
        business_entity: this.getBusinessEntity(),
        user_role: this.getUserRole(),
        workid: this.getWorkID(),
      },
      headers: this.getApiHeaders(),
    };
  };

  apiUrl = '';

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.moduleStockingPaceUrl;
  }
  app;

  getStockingPaceData(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/get-stockingpace-inquirydata`, data, this.httpCustomOptions());
  }
  getDockAndModuleType(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getdockandmoduletype`, this.httpCustomOptions1());
  }
  getVanMonth(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/getvanningmonth`, this.httpCustomOptions1());
  }
  editStockingPaceData(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/editstockingpace`, data, this.httpCustomOptions());
  }

  //maintenance screen
  getMaintenanceModuleType(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/get-moduletype-stockingpace-maintenance`, this.httpCustomOptions1());
  }

  getStockingPaceMaintenanceData(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/get-stockingpace-maintenancedata`, data, this.httpCustomOptions());
  }
  stokingEdit(data): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/editstockingpacemaintenance`, 
      data,
      this.httpCustomOptions()
    );
  }
}
