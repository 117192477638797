import { Injectable, OnInit } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as fs from 'fs';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class OrderExcelServiceService implements OnInit {
  username:String;
  EmailId:String;
  constructor() {
    this.username = localStorage.getItem("UserName");
    this.EmailId = localStorage.getItem("UPN");
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  formatDate(date:Date){
    return moment(date).format('YYYY/MM/DD')+'';
  }
  mapResionCode(reasion){
    const array = [
      {"reason_code": "Sample",'value':1},
      {"reason_code": "Shortshipment (due to TMC)",'value':2},
      {"reason_code": "SMQR/ER",'value':3},
      {"reason_code": "Urgent ECI",'value':4},
      {"reason_code": "Order fluctuation",'value':5},
      {"reason_code": "Mis spec/Mis use/Mis order",'value':6},
      {"reason_code": "Scrap",'value':7},
      {"reason_code": "Backup",'value':8},
      {"reason_code": "Others (Repair etc)",'value':9}
  ]
  var returValue:any = reasion;
    array.forEach(element => {
      if(element.reason_code.toString().toUpperCase() == reasion.toUpperCase()){
        returValue = element.value;
      }
    });
    return returValue;
  }
  formatPart(partNo:string){
    if (partNo.length !== 12) {
      return 'Part No. must be exactly 12 characters long'
    }
    
    const part1 = partNo.slice(0, 5);
    const part2 = partNo.slice(5, 10);
    const part3 = partNo.slice(10, 12);
    
    return `${part1}-${part2}-${part3}`;
  }

  generateExcel(data: any) {
    console.log('excel data', data);
    var processed_data: any = [];
    data.forEach((element: any, index: number) => {
      var comment = "COMMENT: "+element.comment1 + "\nREASON EXPLANATION: " +element.comment2;
      var e: any = {
        srNo: index + 1,
        date_raised: element.date_raised?this.formatDate(element.date_raised):'NaN',
        air_order_no: element.air_order_no?element.air_order_no:'NaN',
        dest_code:  element.dest_code?element.dest_code:'NaN',
        companyName: localStorage.getItem('namcName')?localStorage.getItem('namcName'):'NaN',
        dock: element.dock?element.dock:'NaN',
        air_case_code: element.air_case_code?element.air_case_code:'NaN',
        part_no: element.part_no?this.formatPart(element.part_no):'NaN',
        kanban: element.kanban?element.kanban:'NaN',
        shelfNo: 'NaN',
        packingLotSize:  element.order_lot?element.order_lot:'NaN',
        numOfBox: element.number_of_lots?element.number_of_lots:'NaN',
        partialQty: "NaN",
        total_order: element.order_lot && element.number_of_lots? (element.order_lot * element.number_of_lots):'NaN',
        requestETAAirport: element.arrival_date?this.formatDate(element.arrival_date):'NaN',
        deadline_date: element.deadline_date?this.formatDate(element.deadline_date):'NaN',
        reason: element.reason?this.mapResionCode(element.reason):'NaN',
        responsibility: element.resp?(element.resp == 'Plant'? 'IMP':'TMC'):'NaN',
        partArrangement: element.volume? element.volume.trim() == 'Pipeline'?'PIPE':'ADD':'NaN',
        comment: comment,
        reasonCategory: 'AOR',
        packingShippingETA: 'Long',
        case_no:  element.case_no?element.case_no:'NaN',
        offBox: 'NaN',
        shippingDate: 'NaN',
        eta_date: element.eta_date?this.formatDate(element.eta_date):'NaN',
        personInCharge: this.username,
        tel: 'NaN',
        email: this.EmailId,
        tvcKvc: 'NaN',
        seaOrAir: 'AIR',
        // orderType: element.order_type?element.order_type:'NaN',
        orderType: '4',
        eKbnDockCode: 'NaN',
        eKbnPlantCode: 'NaN',
        goguchiProject: 'G',
        fd: 'NaN',
      };
      // var e: any = {
      //   srNo: index + 1,
      //   date_raised: element.date_raised?element.date_raised:'NaN',
      //   air_order_no: element.air_order_no?element.air_order_no:'NaN',
      //   dest_code: 'Destination/Plant Code',
      //   companyName: 'Company Name',
      //   dock: element.dock?element.dock:'NaN',
      //   air_case_code: element.air_case_code?element.air_case_code:'NaN',
      //   part_no: element.part_no?element.part_no:'NaN',
      //   kanban: element.kanban?element.kanban:'NaN',
      //   shelfNo: 'Shelf#',
      //   packingLotSize: 'Packing Lot Size',
      //   numOfBox: '#of box',
      //   partialQty: "Partial Q'ty (Yes/No)",
      //   total_order: 'Total Order',
      //   requestETAAirport: 'Request ETA Airport',
      //   deadline_date: element.deadline_date?element.deadline_date:'NaN',
      //   reason: element.reason?element.reason:'NaN',
      //   responsibility: 'Responsibility (IMP JAPAN)',
      //   partArrangement: 'Part Arrangement (ADD or PIPE)',
      //   comment: element.comment1?element.comment1:'NaN',
      //   reasonCategory: 'Reason Category',
      //   packingShippingETA: 'Packing,Shipping & ETA Information (Urgent/Long)',
      //   case_no:  element.case_no?element.case_no:'NaN',
      //   offBox: '#of box',
      //   shippingDate: 'Shipping Date',
      //   eta_date: element.eta_date?element.eta_date:'NaN',
      //   personInCharge: 'Person who is in charge',
      //   tel: 'TEL',
      //   email: 'E-mail address',
      //   tvcKvc: 'TVC/KVC UKEIRE',
      //   seaOrAir: 'SEA or AIR',
      //   orderType: element.order_type?element.order_type:'NaN',
      //   eKbnDockCode: 'e-Kbn Dock Code',
      //   eKbnPlantCode: 'e-Kbn Plant Code',
      //   goguchiProject: 'Goguchi/Project',
      //   fd: 'FD',
      // };
      processed_data.push(e);
    });

    this.getData(processed_data).catch((err) => console.error(err));
  }
  async getData(data: any) {
    const cellStyle = {
      font: { name: 'Arial', family: 2, size: 20, bold: true },
      font_b_f: { name: 'Arial', family: 2, size: 20, bold: false },
      alignment: { vertical: 'middle', horizontal: 'left', wrapText: true },
      left_font: { name: 'Arial Narrow', family: 2, size: 20, bold: false },
      left_border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
      first_border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'double' },
        right: { style: 'thin' },
      },
    };
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('OrderSheet');
    // Set the zoom level to 75%
    worksheet.views = [{ state: 'normal', zoomScale: 75 }];
    // Define columns for the worksheet
    worksheet.columns = [
      { header: null, key: 'srNo', width: 5 },
      { header: null, key: 'date_raised', width: 20 },
      { header: null, key: 'air_order_no', width: 20 },
      { header: null, key: 'dest_code', width: 20 },
      { header: null, key: 'companyName', width: 20 },
      { header: null, key: 'dock', width: 10 },
      { header: null, key: 'air_case_code', width: 10 },
      { header: null, key: 'part_no', width: 25 },
      { header: null, key: 'kanban', width: 10 },
      { header: null, key: 'shelfNo', width: 10 },
      { header: null, key: 'packingLotSize', width: 20 },
      { header: null, key: 'numOfBox', width: 10 },
      { header: null, key: 'partialQty', width: 20 },
      { header: null, key: 'total_order', width: 20 },
      { header: null, key: 'requestETAAirport', width: 20 },
      { header: null, key: 'deadline_date', width: 20 },
      { header: null, key: 'reason', width: 20 },
      { header: null, key: 'responsibility', width: 20 },
      { header: null, key: 'partArrangement', width: 20 },
      { header: null, key: 'comment', width: 40 },
      { header: null, key: 'reasonCategory', width: 20 },
      { header: null, key: 'packingShippingETA', width: 30 },
      { header: null, key: 'case_no', width: 10 },
      { header: null, key: 'offBox', width: 10 },
      { header: null, key: 'shippingDate', width: 20 },
      { header: null, key: 'eta_date', width: 20 },
      { header: null, key: 'personInCharge', width: 30 },
      { header: null, key: 'tel', width: 20 },
      { header: null, key: 'email', width: 30 },
      { header: null, key: 'tvcKvc', width: 10 },
      { header: null, key: 'seaOrAir', width: 10 },
      { header: null, key: 'orderType', width: 20 },
      { header: null, key: 'eKbnDockCode', width: 20 },
      { header: null, key: 'eKbnPlantCode', width: 20 },
      { header: null, key: 'goguchiProject', width: 20 },
      { header: null, key: 'fd', width: 10 },
    ];
    // const newRow = worksheet.getRow(2);
    // newRow.getCell('J').value = 'New Data 1';
    // newRow.getCell('K').value = 'New Data 2';
    // newRow.getCell('L').value = 'New Data 3';
    // newRow.commit();

    // row2
    worksheet.addRow([]);
    worksheet.mergeCells('J2:N2');
    const mergedCell: any = worksheet.getCell('J2');
    mergedCell.value = 'Reason Code';

    // row3
    worksheet.addRow([]);
    worksheet.mergeCells('J3:N3');
    const mergedCell_row3: any = worksheet.getCell('J3');
    mergedCell_row3.value = '① Sample';

    // row4
    worksheet.addRow([]);
    worksheet.mergeCells('J4:N4');
    const mergedCell_row4: any = worksheet.getCell('J4');
    mergedCell_row4.value = '② Shortshipment (due to TMC)';

    // row5
    worksheet.addRow([]);
    worksheet.mergeCells('J5:N5');
    const mergedCell_row5: any = worksheet.getCell('J5');
    mergedCell_row5.value = '③ SMQR/ER';

    // row6
    worksheet.addRow([]);
    worksheet.mergeCells('J6:N6');
    const mergedCell_row6: any = worksheet.getCell('J6');
    mergedCell_row6.value = '④ Urgent ECI';

    // row7
    worksheet.addRow([]);
    worksheet.mergeCells('J7:N7');
    const mergedCell_row7: any = worksheet.getCell('J7');
    mergedCell_row7.value = '⑤ Order fluctuation';

    // row8
    worksheet.addRow([]);
    worksheet.mergeCells('J8:N8');
    const mergedCell_row8: any = worksheet.getCell('J8');
    mergedCell_row8.value = '⑥ Mis spec/Mis use/Mis order';

    // row9
    worksheet.addRow([]);
    worksheet.mergeCells('A9:B9');
    const mergedCell_row9_a: any = worksheet.getCell('A9');
    mergedCell_row9_a.value = 'Car Family Code';

    worksheet.mergeCells('C9:D9');
    const mergedCell_row9_c: any = worksheet.getCell('C9');

    worksheet.mergeCells('J9:N9');
    const mergedCell_row9: any = worksheet.getCell('J9');
    mergedCell_row9.value = '⑦ Scrap';

    // row10
    worksheet.addRow([]);

    worksheet.mergeCells('A10:B10');
    const mergedCell_row10_a: any = worksheet.getCell('A10');
    mergedCell_row10_a.value = 'Re-export code';

    worksheet.mergeCells('C10:D10');
    const mergedCell_row10_c: any = worksheet.getCell('C10');

    worksheet.mergeCells('J10:N10');
    const mergedCell_row10: any = worksheet.getCell('J10');
    mergedCell_row10.value = '⑧ Backup';

    // row11
    worksheet.addRow([]);

    worksheet.mergeCells('A11:B11');
    const mergedCell_row11_a: any = worksheet.getCell('A11');
    mergedCell_row11_a.value = 'Line code';

    worksheet.mergeCells('C11:D11');
    const mergedCell_row11_c: any = worksheet.getCell('C11');

    worksheet.mergeCells('J11:N11');
    const mergedCell_row11: any = worksheet.getCell('J11');
    mergedCell_row11.value = '⑨ Others (Repair etc)';

    // row 12
    worksheet.addRow([]);

    // row 13
    worksheet.addRow([]);
    //row14
    worksheet.addRow({
      srNo: '',
      date_raised: 'Order date',
      air_order_no: 'Order No.',
      dest_code: 'Destination/Plant Code',
      companyName: 'Company Name',
      dock: 'Dock',
      air_case_code: 'Air code',
      part_no: 'Part #',
      kanban: 'Kanban',
      shelfNo: 'Shelf#',
      packingLotSize: 'Packing Lot Size',
      numOfBox: '#of box',
      partialQty: "Partial Q'ty (Yes/No)",
      total_order: 'Total Order',
      requestETAAirport: 'Request ETA Airport',
      deadline_date: 'Dead Line Date',
      reason: 'Reason Code',
      responsibility: 'Responsibility (IMP or TMC)',
      partArrangement: 'Part Arrangement (ADD or PIPE）',
      comment: 'Comment',
      reasonCategory: 'Reason Category',
      packingShippingETA: 'Packing,Shipping & ETA Information (Urgent/Long)',
      case_no: 'Case No.',
      offBox: '#of box',
      shippingDate: 'Shipping Date',
      eta_date: 'ETA Date',
      personInCharge: 'Person who is in charge',
      tel: 'TEL',
      email: 'E-mail address',
      tvcKvc: 'TVC/KVC UKEIRE',
      seaOrAir: 'SEA or AIR',
      orderType: 'Order Type',
      eKbnDockCode: 'e-Kbn Dock Code',
      eKbnPlantCode: 'e-Kbn Plant Code',
      goguchiProject: 'Goguchi/Project',
      fd: 'FD',
    });
    //row15
    worksheet.addRow([]);
    worksheet.mergeCells('A14:A15');
    worksheet.mergeCells('B14:B15');
    worksheet.mergeCells('C14:C15');
    worksheet.mergeCells('D14:D15');
    worksheet.mergeCells('E14:E15');
    worksheet.mergeCells('F14:F15');
    worksheet.mergeCells('G14:G15');
    worksheet.getCell('H15').value = '品番';
    worksheet.getCell('I15').value = '現地背番号';
    worksheet.getCell('J15').value = '現地棚番';
    worksheet.getCell('K15').value = '出荷ロット';
    worksheet.getCell('L15').value = '箱数';
    worksheet.getCell('M15').value = '端数';
    worksheet.getCell('N15').value = '合計';
    worksheet.getCell('O15').value = '希望納期';
    worksheet.getCell('P15').value = 'デッドライン';
    worksheet.getCell('Q15').value = '理由コード';
    worksheet.getCell('R15').value = '責任区分';
    worksheet.getCell('S15').value = '号口巻替え';
    worksheet.getCell('T15').value = 'コメント';
    worksheet.getCell('U15').value = 'オーダー理由';
    worksheet.getCell('V15').value = '梱包、配送、ETA情報(緊急/ロング)';
    worksheet.getCell('W15').value = 'ケースNo.';
    worksheet.getCell('X15').value = '箱数';
    worksheet.getCell('Y15').value = '出荷日';
    worksheet.getCell('Z15').value = '現地空港到着日';
    worksheet.mergeCells('AA14:AA15');
    worksheet.mergeCells('AB14:AB15');
    worksheet.mergeCells('AC14:AC15');
    worksheet.getCell('AD15').value = '拠点受入';
    worksheet.getCell('AE15').value = '輸送区分';
    worksheet.getCell('AF15').value = 'オーダータイプ';
    worksheet.getCell('AG15').value = 'e-Kbn 現地受入';
    worksheet.getCell('AH15').value = 'e-Kbn 納入先工区';
    worksheet.getCell('AI15').value = '号口/生準';
    worksheet.getCell('AJ15').value = 'FD';

    // worksheet.addRow(data);
    console.log(data);
    data.forEach((element: any, index: number) => {
      worksheet.addRow(element);
    });

    // row 16

    var peachcolor = [
      'B14',
      'C14',
      'D14',
      'E14',
      'F14',
      'G14',
      'AA14',
      'AB14',
      'AC14',
      'B15',
      'C15',
      'D15',
      'E15',
      'F15',
      'G15',
      'AA15',
      'AB15',
      'AC15',
    ];
    var yellowcolor = [
      'H14',
      'I14',
      'J14',
      'K14',
      'L14',
      'M14',
      'N14',
      'O14',
      'P14',
      'Q14',
      'R14',
      'S14',
      'T14',
      'U14',
      'V14',
      'H15',
      'I15',
      'J15',
      'K15',
      'L15',
      'M15',
      'N15',
      'O15',
      'P15',
      'Q15',
      'R15',
      'S15',
      'T15',
      'U15',
      'V15',
    ];
    var bluecolor = ['W14', 'X14', 'Y14', 'Z14', 'W15', 'X15', 'Y15', 'Z15'];
    var graycolor = [
      'AD14',
      'AE14',
      'AF14',
      'AG14',
      'AH14',
      'AI14',
      'AJ14',
      'AD15',
      'AE15',
      'AF15',
      'AG15',
      'AH15',
      'AI15',
      'AJ15',
    ];

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value !== null && cell.value !== undefined) {
          if(cell.address.toString().includes('14') ||cell.address.toString().includes('15'))
          cell.font = { name: 'Arial', family: 2, size: 16, bold:true};
        else
          cell.font = { name: 'Arial', family: 2, size: 14};
          cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
          cell.border = {top: { style: 'thin' },left: { style: 'thin' },bottom: { style: 'thin' },right: { style: 'thin' }};
          if(cell.value.toString().toUpperCase() == 'FD') cell.font = { color:{ argb:'FF0101'},bold:true};
          if(cell.value == 'NaN') cell.value = '';
        }
        if (peachcolor.includes(cell.address))
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FBE2D5' } };
        if (yellowcolor.includes(cell.address))
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFCC' } };
        if (bluecolor.includes(cell.address))
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CCECFF' } };
        if (graycolor.includes(cell.address))
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D9D9D9' } };
      });
    });

    mergedCell_row11.font = cellStyle.font_b_f;
    mergedCell_row11.alignment = cellStyle.alignment;
    mergedCell_row11.border = cellStyle.border;
    mergedCell_row11_c.font = cellStyle.left_font;
    mergedCell_row11_c.alignment = cellStyle.alignment;
    mergedCell_row11_c.border = cellStyle.left_border;
    mergedCell_row11_a.font = cellStyle.left_font;
    mergedCell_row11_a.alignment = cellStyle.alignment;
    mergedCell_row11_a.border = cellStyle.left_border;
    mergedCell_row10.font = cellStyle.font_b_f;
    mergedCell_row10.alignment = cellStyle.alignment;
    mergedCell_row10.border = cellStyle.border;
    mergedCell_row10_c.font = cellStyle.left_font;
    mergedCell_row10_c.alignment = cellStyle.alignment;
    mergedCell_row10_c.border = cellStyle.left_border;
    mergedCell_row10_a.font = cellStyle.left_font;
    mergedCell_row10_a.alignment = cellStyle.alignment;
    mergedCell_row10_a.border = cellStyle.left_border;
    mergedCell_row9.font = cellStyle.font_b_f;
    mergedCell_row9.alignment = cellStyle.alignment;
    mergedCell_row9.border = cellStyle.border;
    mergedCell_row9_c.font = cellStyle.left_font;
    mergedCell_row9_c.alignment = cellStyle.alignment;
    mergedCell_row9_c.border = cellStyle.left_border;
    mergedCell_row9_a.font = cellStyle.left_font;
    mergedCell_row9_a.alignment = cellStyle.alignment;
    mergedCell_row9_a.border = cellStyle.left_border;
    mergedCell_row8.font = cellStyle.font_b_f;
    mergedCell_row8.alignment = cellStyle.alignment;
    mergedCell_row8.border = cellStyle.border;
    mergedCell_row7.font = cellStyle.font_b_f;
    mergedCell_row7.alignment = cellStyle.alignment;
    mergedCell_row7.border = cellStyle.border;
    mergedCell_row6.font = cellStyle.font_b_f;
    mergedCell_row6.alignment = cellStyle.alignment;
    mergedCell_row6.border = cellStyle.border;
    mergedCell_row5.font = cellStyle.font_b_f;
    mergedCell_row5.alignment = cellStyle.alignment;
    mergedCell_row5.border = cellStyle.border;
    mergedCell_row4.font = cellStyle.font_b_f;
    mergedCell_row4.alignment = cellStyle.alignment;
    mergedCell_row4.border = cellStyle.border;
    mergedCell_row3.font = cellStyle.font_b_f;
    mergedCell_row3.alignment = cellStyle.alignment;
    mergedCell_row3.border = cellStyle.border;
    mergedCell.font = cellStyle.font;
    mergedCell.alignment = cellStyle.alignment;
    mergedCell.border = cellStyle.first_border;

    // Generate the Excel file as a buffer
    const buffer = await workbook.xlsx.writeBuffer();
    var file_name = '';
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    if(data.length > 0)
     file_name= 'ADGO_'+ data[0].air_order_no +'_'+ formattedDate;
    else
    file_name= 'ADGO_sample.xlsx';
    // Create a Blob from the buffer and trigger a download
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, file_name);
  }
}
