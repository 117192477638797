import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-module-type-delete-dialog',
  templateUrl: './module-type-delete-dialog.component.html',
  styleUrls: ['./module-type-delete-dialog.component.scss']
})

 
export class ModuleTypeDeleteDialogComponent implements OnInit {
  business_entity:any;

  constructor() { }

  ngOnInit(): void {
    this.business_entity=localStorage.getItem('namcvalue')
  }
  deleteRecord(){
    return {
      "business_entity":this.business_entity
    }
  }
}