import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from '../../environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class OspService {
  apiUrl = '';
  adjustmentapiUrl = '';
  lookupUrl = '';

  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: localStorage.getItem('Token'),
      'X-Api-Key': this.environment.setupKey,
    });
  }

  constructor(
    private readonly http: HttpClient,
    private readonly environment: EnvironmentConfiguration
  ) {
    this.apiUrl = environment.setupUrl;
    this.adjustmentapiUrl = environment.adjustmentEnquiryUrl;
    this.lookupUrl = environment.lookupUrl;
  }
  getMasterId(
    business_entity: string,
    userRole: string,
    workid: string
  ): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };
    const reqParams = {
      user_role: userRole,
      workid: workid,
      business_entity: business_entity,
    };
    return this.http.post<any>(
      `${this.apiUrl}/masterdetails/getMasterID`,
      reqParams,
      httpCustomOptions
    );
  }
  getMasterDetails(
    namc: string,
    userRole: string,
    workid: string,
    master_id
  ): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };
    const reqParams = {
      user_role: userRole,
      workid: workid,
      business_entity: namc,
      master_id: master_id,
    };
    return this.http.post<any>(
      `${this.apiUrl}/masterdetails/listMasterDetails`,
      reqParams,
      httpCustomOptions
    );
  }
  insertMasterDetails(
    namc: string,
    userRole: string,
    workid: string,
    master_id,
    key_item,
    data_item,
    name,
    userid
  ): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };
    const reqParams = {
      user_role: userRole,
      workid: workid,
      business_entity: namc,
      master_id: master_id,
      key_item: key_item,
      data_item: data_item,
      name: name,
      userid: userid,
    };

    return this.http.post<any>(
      `${this.apiUrl}/masterdetails/addmasterdetails`,
      reqParams,
      httpCustomOptions
    );
  }
  updateMasterDetails(
    namc: string,
    userRole: string,
    workid: string,
    master_id,
    key_item,
    data_item,
    old_key_item,
    old_data_item,
    userid
  ): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    const reqParams = {
      user_role: userRole,
      workid: workid,
      business_entity: namc,
      master_id: master_id,
      key_item: key_item,
      data_item: data_item,
      old_key_item: old_key_item,
      old_data_item: old_data_item,
      userid: userid,
    };

    return this.http.put<any>(
      `${this.apiUrl}/masterdetails/editMasterDetails`,
      reqParams,
      httpCustomOptions
    );
  }
  deleteMasterDetails(
    namc: string,
    userRole: string,
    workid: string,
    master_id,
    key_item
  ): Observable<any> {
    const httpCustomOptions = {
      body: {
        user_role: userRole,
        workid: workid,
        business_entity: namc,
        master_id: master_id,
        key_item: key_item,
      },
      headers: this.getHeaders(),
    };

    return this.http.delete<any>(
      `${this.apiUrl}/masterdetails/deletemasterdetails`,
      httpCustomOptions
    );
  }

  getSupplierName(business_entity, user_role, workid): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getHeaders(),
    };
    return this.http.get<any>(
      `${this.lookupUrl}/suppliername`,
      httpCustomOptions
    );
  }

  getAdjustmentSpecialist(business_entity, user_role, workid): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getHeaders(),
    };
    return this.http.get<any>(
      `${this.lookupUrl}/specialist`,
      httpCustomOptions
    );
  }

  getAdjustmentAdjtype(business_entity, user_role, workid): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getHeaders(),
    };
    return this.http.get<any>(`${this.lookupUrl}/adjtype`, httpCustomOptions);
  }

  getAdjustmentGrid(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    return this.http.post<any>(
      `${this.adjustmentapiUrl}`,
      data,
      httpCustomOptions
    );
  }

  getReport(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    const test = `${this.lookupUrl}/reportlist`;

    return this.http.post<any>(test, data, httpCustomOptions);
  }

  downloadReport(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    const test = `${this.lookupUrl}/downloadreport`;

    return this.http.post<any>(test, data, httpCustomOptions);
  }

  downloadMultipleReport(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    const test = `${this.lookupUrl}/downloadmultiplereport`;

    return this.http.post<any>(test, data, httpCustomOptions);
  }
  downloadMonthlyReport(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    const test = `${this.lookupUrl}/downloadmonthlyreport`;

    return this.http.post<any>(test, data, httpCustomOptions);
  }
  downloadMultiMonthlyReport(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };

    const test = `${this.lookupUrl}/downloadMonthlyMultipleReport`;

    return this.http.post<any>(test, data, httpCustomOptions);
  }


  //shift screen
  getShiftDetails(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }

    return this.http.post<any>(
      `${this.apiUrl}/getShiftData`,
      data,
      httpCustomOptions
    );
  }
  //delete shifts
  getDeleteShift(data1): Observable<any> {
    const httpCustomOptions = {
      body: data1,
      headers: this.getHeaders(),
    };

    return this.http.delete<any>(
      `${this.apiUrl}/deleteShift`,

      httpCustomOptions
    );
  }
  //edit shift screen
  editShifts(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }

    return this.http.post<any>(
      `${this.apiUrl}/editshift`,
      data,
      httpCustomOptions
    );
  }
  //delete Breaks
  getDeleteShiftBreaks(data1): Observable<any> {
    const httpCustomOptions = {
      body: data1,
      headers: this.getHeaders(),
    };

    return this.http.delete<any>(
      `${this.apiUrl}/deleteShiftBreak`,

      httpCustomOptions
    );
  }
  //addShifts
  // addShift(data): Observable<any> {
  //   const httpCustomOptions = {
  //     body: data,
  //     headers: this.getHeaders(),
  //   };
  //   return this.http.put<any>(
  //     `${this.apiUrl}/addshift`,
  //     httpCustomOptions
  //   );
  // }
  addShift(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }

    return this.http.put<any>(
      `${this.apiUrl}/addshift`,
      data,
      httpCustomOptions
    );
  }
  addBreak(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }

    return this.http.put<any>(
      `${this.apiUrl}/addBreak`,
      data,
      httpCustomOptions
    );
  }
  editBreak(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }
    return this.http.put<any>(
      `${this.apiUrl}/editBreak`,
      data,
      httpCustomOptions
    );
  }
  copyShift(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }
    return this.http.put<any>(
      `${this.apiUrl}/copy-all-shift`,
      data,
      httpCustomOptions
    );
  }
  getDock(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders()
    }
    return this.http.post<any>(
      `${this.apiUrl}/getDock`,
      data,
      httpCustomOptions
    );
  }

}
