import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { GriddataService } from 'src/app/services/griddata.service';
import { DeleteBtnCellRender } from './review-container.component/delete-btn-cell-render';
import { EditBtnCellRender } from './review-container.component/edit-btn-cell-render';
import { DeleteBreaksComponent } from '../delete-breaks/delete-breaks.component';
import * as moment from 'moment';
import {
  warningMessage,
  successMessage,
} from 'src/app/constants';
import { Subject } from 'rxjs';
import { OspService } from 'src/app/core/master-services/osp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditBreaksComponent } from '../edit-breaks/edit-breaks.component';


@Component({
  selector: 'app-review-changes',
  templateUrl: './review-changes.component.html',
  styleUrls: ['./review-changes.component.scss'],
})
export class ViewBreakComponent implements OnInit {
  rowData: any[] = [];
  hasData: boolean = false;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;
  editEnabled: boolean = false;
  today = moment().format('MM/DD/YYYY');
  actionCellRenderer: any;
  gridApi: any;
  headerCheckboxChecked: boolean = false;
  isEditMode: boolean;
  warningMessage: string;
  successMessage: string;
  modalComponent = 'modal-component';
  public frameworkComponents
  itContact: string;
  namcValue = '';
  user = '';
  userRole = '';
  //add breaks
  isExpanded: boolean[] = []; // Declare and initialize here
  shiftNumber: any;
  userName = '';
  noRows: boolean = false;
  rows: any[] = [];
  rowData2: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly dialogRef: MatDialogRef<ViewBreakComponent>,
    public dialog: MatDialog,
    private readonly ospService: OspService,
    private readonly spinner: NgxSpinnerService,

  ) {
    {
      this.context = {
        componentParent: this,
        refreshHeader: new Subject<void>()
      };
    }
    this.columnDefs = [
      {
        headerName: '',
        field: 'edit',
        colId: "action",
        sortable: false,
        unSortIcon: false,
        width: 55,
        pinned: 'left',
        editable: false,
        cellRendererFramework: EditBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.editeBreaks(field)
          },
        },
       
      },
      {
        headerName: '',
        field: 'delete',
        sortable: false,
        unSortIcon: false,
        pinned: 'left',
        width: 55,
        cellRendererFramework: DeleteBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.deleteButton(field)
          },
        },
        cellStyle: { cursor: 'pointer' },
      },
      {
        headerName: 'Shift No',
        field: 'shift_no',
        width: 140,
        editable: false,

      },
      {
        headerName: 'Break From',
        field: 'time_from',
        width: 160,
      },
      {
        headerName: 'To Break',
        field: 'time_to',
        width: 170,
      },

      
    ];
    this.defaultColDef = {
      editable: false
    };
    this.rowData = null;
    this.rowSelection = 'multiple';
    this.rowData = data
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;
    this.gridOptions = {
      frameworkComponents: {
        EditBtnCellRender: EditBtnCellRender,

      }};
    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  enableSave: boolean = false;
  ngOnInit() {
    if (this.data && this.data.rows ) {
      this.rowData = this.data.rows;
    }

    this.shiftNumber = this.data.rows[0]?.shift_no ?? this.data.result[0]?.shift_no;
    console.log(this.data,'329')
    console.log(this.shiftNumber,'shiftNumber')
    this.namcValue = localStorage.getItem('namcvalue');
    this.user = localStorage.getItem('workdayId');
    this.userRole = localStorage.getItem('UserRoles');
    this.userName = localStorage.getItem('UserName');
  }
 
  cellRendererFramework: EditBtnCellRender
 
  deleteButton(field: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '350px';
    dialogConfig.height = '200px';
    dialogConfig.data = {
      mode: 'Breaks',
    };
    console.log(dialogConfig.data,'delete breaks')
    const data1 = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      userid: localStorage.getItem('UserName'),
      user_role: this.userRole,
      shift_id: field?.shift_id,
      break_id: field?.break_id,
      time_from:field?.time_from,
      time_to:field?.time_to,
      duration:field?.duration,
    };
    const dialogRef = this.dialog.open(DeleteBreaksComponent, dialogConfig)
    console.log(dialogRef, 'deleteBreaks')

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.spinner.show();
        this.ospService.getDeleteShiftBreaks(data1).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.breakDeletedCount >= 1) {
              const index = this.rowData.findIndex((item) =>
                item.break_id === data1?.break_id
              );
              if (index !== -1) {
                this.rowData.splice(index, 1);
              }
              this.gridOptions.api.setRowData(this.rowData);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
            }
            else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.spinner.hide();
            }
          },
        });
      }
    })
   
  }
  hideSpinner() {
    this.spinner.hide();
  }
  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  };

  openBreaks() {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '350px';
    dialogConfig.data = {
      shift_no:this.data.rows[0]?.shift_no ?? this.data.result[0]?.shift_no,
      shift_id:this.data.rows[0]?.shift_id ?? this.data.result[0]?.shift_id,
      dock:this.data.rows[0]?.dock ?? this.data.result[0]?.dock,
      duration:this.data.rows[0]?.duration ?? this.data.result[0]?.duration,
      startTime:this.data.rows[0]?.start_time ?? this.data.result[0]?.start_time,
      endTime:this.data.rows[0]?.end_time ?? this.data.result[0]?.end_time,
      mode: 'add',
    };
    console.log(dialogConfig.data, 'add_1277')
    const dialogRef = this.dialog.open(EditBreaksComponent, dialogConfig);
    console.log(dialogRef.componentInstance.addBreaksEventEmitter,'componentInstance');
    const addGetResponse =
      dialogRef.componentInstance?.addBreaksEventEmitter?.subscribe({error: this.errorCallback,
        next:(res) => {
        console.log("res---",res);
       
        const data1 = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          dock: this.data.rows[0]?.dock ?? this.data.result[0]?.dock,
          shift_no: res.shiftNumber,
          shift_id: this.data.rows[0]?.shift_id ?? this.data.result[0]?.shift_id,
          start_time: res.fromTimeNew,
          end_time: res.toTimeNew,
          duration:this.data.rows[0]?.duration ?? this.data.result[0]?.duration,
          plant: '01',
        }
        this.spinner.show();
        this.ospService?.addBreak(data1)?.subscribe({
          error: this.errorCallback,
          next: (response) => {
            console.log("addShift",response);
            this.spinner.hide();
            const updatedRows  =  true;
            if (updatedRows) {
              this.rowData.push(response.body.updatedRows[0]);
              console.log(this.rowData,'rowData')
              this.successMessage = successMessage.shiftBreaksAdded;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }
   
            window.scroll(0, 0);
          },
        });
      }});
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }


  convertTimeToMinutes(time) {
    // Assuming time is in "HH:mm:ss" format. Convert it to total minutes.
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  convertMinutesToTimeFormat(minutes) {
    // Convert total minutes into HH:MM:SS format
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const seconds = 0; // Assuming zero seconds
    return `${this.padZero(hours)}:${this.padZero(mins)}:${this.padZero(seconds)}`;
  }

  padZero(num) {
    // Pad single digit numbers with a leading zero (e.g., 9 -> 09)
    return num < 10 ? `0${num}` : num.toString();
  }



  

  editeBreaks(field:any) {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.id = this.modalComponent;
    dialogConfig.width = '450px';
    dialogConfig.height = '350px';
    dialogConfig.data = {
      field,
      shift_no:this.data.rows[0]?.shift_no,
      toTime: this.data.rows[0]?.time_to,
      fromTime:this.data.rows[0]?.time_from,
      endtime:this.data.rows[0]?.start_time,
      mode: 'edit',
    };

    let startTimeInMinutes = this.convertTimeToMinutes( dialogConfig.data.field.time_from);
    let endTimeInMinutes = this.convertTimeToMinutes(dialogConfig.data.field.time_to);
    let breakDuration = endTimeInMinutes - startTimeInMinutes;
    console.log("breakDuration",breakDuration);
   const dialogRef = this.dialog.open(EditBreaksComponent, dialogConfig);
    console.log(dialogRef.componentInstance.editBreaksEventEmitter,'componentInstance');
    const addGetResponse =
      dialogRef.componentInstance?.editBreaksEventEmitter?.subscribe({error: this.errorCallback,
        next:(res) => {
        console.log("res---",res);
        let startTimeInMinutes2 = this.convertTimeToMinutes( res.fromTimeNew);
        let endTimeInMinutes2 = this.convertTimeToMinutes(res.toTimeNew);
        let breakDuration2 = endTimeInMinutes2 - startTimeInMinutes2;
        console.log("breakDuration2",breakDuration2);
        const oldDuration = breakDuration - breakDuration2
        console.log("oldDuration",oldDuration)
        const finalDuration = this.data.rows[0].duration + (oldDuration)
        console.log("finalDuration",finalDuration)

    
       let data3 = {
        business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          dock: this.data.rows[0].dock,
          shift_no: res.shiftNumber,
          shift_id: this.data.rows[0].shift_id,
          start_time:res.fromTimeNew,
          end_time: res.toTimeNew,
          break_id: this.data.rows[0].break_id,
          duration: finalDuration,
       }
        const data1 = {
          data:[data3],
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          dock: this.data.rows[0].dock,
          shift_no: res.shiftNumber,
          shift_id: this.data.rows[0].shift_id,
          start_time: res.toTime,
          end_time:  res.fromTime,
          plant: '01',
          duration: finalDuration,
          break_id: this.data.rows[0].break_id,


        }
        this.spinner.show();
        this.ospService?.editBreak(data1)?.subscribe({
          error: this.errorCallback,
          next: (response) => {
            this.spinner.hide();
            const updatedRows  = response.body.updatedRows;
            if (updatedRows.length > 0) {
              this.successMessage = successMessage.shiftBreaksUpdated;
              const index = this.rowData.findIndex((item) =>
              item.break_id === data1?.break_id
            );
            if (index !== -1) {
              this.rowData.splice(index, 1);
              this.rowData.unshift(updatedRows[0])
              this.gridOptions.api.setRowData(this.rowData);
            }
           

            

            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }
            window.scroll(0, 0);
          },
        });
      }});
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }

}

