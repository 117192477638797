import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <div *ngIf="!showInput" class="time-picker">
      <select [(ngModel)]="hours" (change)="updateTime()">
        <option *ngFor="let hour of hoursArray" [value]="hour">{{ hour }}</option>
      </select>
      :
      <select [(ngModel)]="minutes" (change)="updateTime()">
        <option *ngFor="let minute of minutesArray" [value]="minute">{{ minute }}</option>
      </select>
    </div>
  `,
  styles: [
    `
      .time-picker select {
        width: 50px;
        margin-right: 5px;
      }
      .time-picker {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class GridShiftsTimeEditorComponent {
  params: any;
  showInput = true;  // Controls visibility of the time picker
  value: any;        // The current value for startTime or endTime
  isEmpty = true;
  disableDate = '';
  hours: string;
  minutes: string;
  seconds: string;
  hoursArray: string[] = [];
  minutesArray: string[] = [];
  secondsArray: string[] = [];

  constructor() {
    this.hoursArray = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
    this.minutesArray = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
    this.secondsArray = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
  }

  agInit(params: any): void {
    this.params = params;

    if (params.column === 'startTime') {
      this.value = params.data.start_time;
    } else if (params.column === 'endTime') {
      this.value = params.data.end_time;
    }

    this.disableDate = moment().format('HH:mm:ss'); // Disable date input

    this.isEmpty = !this.value;

    if (this.value && moment(this.value, 'HH:mm:ss', true).isValid()) {
      const time = moment(this.value, 'HH:mm:ss');
      this.hours = time.format('HH');
      this.minutes = time.format('mm');
      this.seconds = time.format('ss');
    } else {
      this.hours = '00';
      this.minutes = '00';
      this.seconds = '00';
    }

    if (params.data.checkedEditMode) {
      this.showInput = false;
      this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
    } else {
      this.showInput = true;
    }

    if (!params.data.checkedEditMode && this.value) {
      this.value = moment(this.value, 'HH:mm:ss').format('HH:mm:ss');
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  updateTime() {
    const newTime = `${this.hours}:${this.minutes}:00`;
    // const newTime = `${this.hours}:${this.minutes}:${this.seconds}`;
    if (this.params?.column === 'startTime') {
      if (newTime !== this.params.data.startTime) {
        this.params.data.startTime = newTime;
        this.params.data.isStartTime = true;
        this.params.data.start_time = this.params.data.startTime
      }
    } else if (this.params?.column === 'endTime') {
      if (newTime !== this.params.data.endTime) {
        this.params.data.endTime = newTime;
        this.params.data.isEndTime = true;
        this.params.data.end_time = this.params.data.endTime
      }
    }
  }

  stopTyping() {
    return false;
  }
}
