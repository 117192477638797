<div class="background">
  <div class="successMessage alert-dismissible">

    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
        {{ warningMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>

      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Parts and Inventory Policies</h2>
      </div>
      <div class="p-2">
        <button type="button" class="search_btn policy_btn" (click)="openDialog()">
          ADD INVENTORY POLICY
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">

                <label class="searchLable">EFFECTIVE FROM</label>
                <input [(ngModel)]="effectiveFrom" class="key" type="text" placeholder="yyyyww"
                  (keydown)="onKeyPress($event)" maxlength="6" autoWidth="false" />
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">EFFECTIVE TO</label>
                <input [(ngModel)]="effectiveTo" (keydown)="onKeyPress($event)" maxlength="6" class="key"
                  placeholder="yyyyww" type="text" autoWidth="false" />
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DOCK</label>
                <p-multiSelect [(ngModel)]="selectedDock" [showToggleAll]="false" (onChange)="dockChange()"
                  [options]="dockList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="dock"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>


            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">PART NO</label>
                <p-multiSelect [(ngModel)]="selectedPartNo" [showToggleAll]="false" (onChange)="onPartNoChange($event)"
                  [options]="partNoList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="item_id"
                  [panelStyle]="{ width: '140%' }" [disabled]="disablePartNumber" optionDisabled="disabled"
                  selected="false">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">KANBAN </label>
                <p-multiSelect [(ngModel)]="selectedKanban" [showToggleAll]="false" (onChange)="onKanbanChange()"
                  [options]="kanbanList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="kanban"
                  [panelStyle]="{ width: '120%' }" [disabled]="disableKanban">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">SPECIALIST</label>
                <p-multiSelect [(ngModel)]="selectedSpecialist" [showToggleAll]="false" (onChange)="specialistChange()"
                  [options]="specialistList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="EMPLOYEE"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">POLICY NAME </label>
                <p-multiSelect [(ngModel)]="selectedpolicyName" [showToggleAll]="false" (onChange)="policyNameChange()"
                  [options]="policyNameList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="policy_name"
                  [panelStyle]="{ width: '120%' }">
                </p-multiSelect>
              </div>
            </div>


            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img src="./assets/images/Group 115380.png" alt="refresh icon" />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
      </div>
      <div class="col-sm-2">
        <div class="records">
        </div>
      </div>

      <div class="col-sm-2">
        <div class="records" style="margin-bottom: 4px;">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <ag-grid-angular class="table-outer" #osPartsGrid style="width: 100%; margin-bottom: 5%;" class="ag-theme-balham"
      [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
      [pagination]="true" [paginationPageSize]="paginationPageSize" [context]="context"
      [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay" [defaultColDef]="defaultColDef"
      [groupDefaultExpanded]="groupDefaultExpanded" [masterDetail]="true" [detailRowAutoHeight]="true"
      [detailCellRendererParams]="detailCellRendererParams" [tooltipMouseTrack]="true" [editType]="editType">
    </ag-grid-angular>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading... Please wait...</p>
</ngx-spinner>