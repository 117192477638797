<div *ngIf="data">
    <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
        <div class="col-sm-11 movable">
            <h3 class="head" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
                EDIT PRODUCTION PROGRESS
            </h3>
        </div>

        <div class="col-sm-1">
            <button type="button" class="btn btn-dark btn-circle btn-xl" [mat-dialog-close]="true" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
    <form [formGroup]="addOrEditform">
        <div class="row">
            <div class="col-md-6">
                <div class="drpchildctrl">
                    
                    <label required class="searchLable">Actual Date</label>
                    <input formControlName="actualDate" class="key" type="text" placeholder="--Type--" readonly />
                </div>
            </div>
            <div class="col-md-6">
                <div class="drpchildctrl">
                    
                    <label required class="searchLable">Actual Shift</label>
                    <input formControlName="shiftNumber" class="key" type="text" placeholder="--Type--" readonly />
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px">
            <div class="col-md-6">
                <div class="drpchildctrl">
                    <label required class="searchLable">Plan Volume</label>
                    <input formControlName="planVolume" class="key" type="text" placeholder="--Type--" readonly />
                </div>
            </div>
            <div class="col-md-6">
                <div class="drpchildctrl">
                    <label required class="searchLable">Cumul Plan Net</label>
                    <input class="key" type="text" autoWidth="false" maxlength="25" 
                    formControlName="cumulPlannet"
                    placeholder="--Type--" ng-required="true" />
                        </div>
                        <ng-container *ngIf="addOrEdit.cumulPlannet.errors && submitted">
                            <small *ngIf="addOrEdit.cumulPlannet.errors?.required">
                              Cumul Plan Net  is required.
                            </small>
                          </ng-container>      
            </div>
  </div>
    
        <!-- Sentence for Cumul Plan -->
        <div class="row">
            <div class="col-md-12">
                <p class="sentence-text">Cumul Plan: +ve value to show ahead condition and -ve to show behind condition.</p>
            </div>
        </div>
    

    </form>
    

    <div class="text-right" style="margin-top: 20px">
        <button class="review_btn" mat-button (click)="Recalculate()">
            Reset
        </button>

        <button class="cancel_btn" mat-button mat-dialog-close="cancel">
            Cancel
        </button>
    </div>
</div>