import { Component, Inject, OnInit } from '@angular/core'
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
// import { ExportStateService } from 'src/app/services/export-state.service'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-delete-breaks',
  templateUrl: './delete-breaks.component.html',
  styleUrls: ['./delete-breaks.component.scss'],
})
export class DeleteBreaksComponent implements OnInit {
  param: any
  fileName: string;
  mesaage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<DeleteBreaksComponent>,
  ) {}

  ngOnInit(): void {
    switch (this.dialogRef.componentInstance.data.mode) {
      case 'Shifts':
        this.fileName = 'Shifts';
        break;
      
      case 'Breaks':
        this.fileName = 'Breaks';
        break;
      
      default:
        // Handle the default case (if necessary)
        break;
    }
  
  }

  closeDialog() {
    this.dialogRef.close()
  }

  dialogData(pNam: string) {
    this.dialogRef.close({ data: pNam })
  }
}