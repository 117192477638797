import { Component, OnInit, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
// import { MasterEditDialogComponent } from '../master-edit-dialog/master-edit-dialog.component';
import { GridApi } from 'ag-grid-community';
// import { MasterDeleteComponent } from '../master-delete/master-delete.component';


@Component({
  selector: 'app-parts-actions',
  templateUrl: './parts-actions.component.html',
  styleUrls: ['./parts-actions.component.scss']
})
export class PartsActionsComponent implements ICellRendererAngularComp {


  editAccess: boolean = false;
  download: boolean = false;
  deleteAccess: boolean = false;
  params: any;
  master_id: any;
  master_name: any;
  /*editmode*/
  isEditMode: boolean = false;

  constructor(public dialog: MatDialog) { }
  access;
  refresh(): boolean {
    return false;
  }
  agInit(params): void {
    this.params = params;
    this.access = JSON.parse(localStorage.getItem('master'));
  }

  ngOnInit(): void { }

  deleteChanged() {
    this.params.context.componentParent.userDeleteChanged(
      this.params.node.rowIndex
    );
  }
  editData(params: any): void {
    this.params.context.componentParent.userEdit(
      this.params.node.rowIndex
    );
  }

}
